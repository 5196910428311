import { LoadingOutlined } from '@ant-design/icons';
import { FC } from 'react';

export const Image: FC<React.ComponentPropsWithoutRef<'img'>> = (props) => {
  if (!props.src) {
    return <LoadingOutlined />;
  }

  // eslint-disable-next-line jsx-a11y/alt-text
  return <img {...props} />;
};
