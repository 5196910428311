import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { APIProject } from '../types';
import { Form, FormRow, Select } from '.';
import { Title } from './typography';

interface Props {
  projects?: APIProject[];
}

const PROJECT_TYPES = [
  {
    title: 'Chemistry',
    value: 'chemistry',
  },
  { title: 'Optical Assay', value: 'optical_assay', disabled: true },
  {
    title: 'High Throughput Fermentation',
    value: 'fermentation',
    disabled: true,
  },
];

type ProjectSelection = {
  project_short_code: string;
  project_type: string;
};

export const SelectProject: FC<Props> = ({ projects = [] }) => {
  const { register, handleSubmit } = useForm<ProjectSelection>();
  const navigate = useNavigate();

  const onSubmit = ({ project_short_code, project_type }: ProjectSelection) => {
    navigate(`/projects/${project_short_code}/${project_type}/submissions`);
  };

  return (
    <Wrapper>
      <Title>Select Project</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <Select
            title="Project Short Code"
            {...register('project_short_code')}
          >
            {projects.map(({ project_name, project_short_code }) => (
              <option key={project_short_code} value={project_short_code}>
                {project_name}
              </option>
            ))}
          </Select>
          <Select title="Project Type" {...register('project_type')}>
            {PROJECT_TYPES.map(({ title, value, disabled }) => (
              <option key={title} value={value} disabled={disabled}>
                {title}
              </option>
            ))}
          </Select>
          <input type="submit" />
        </FormRow>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 240px;
`;
