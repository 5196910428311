import { Breadcrumb } from 'antd';
import { FC } from 'react';
import { Link, useLocation, useMatches } from 'react-router-dom';
import styled from 'styled-components';

import { BiobankType } from '../types';
import { compute, humanize } from '../utils';
import {
  BIOBANK_TYPE_NAME,
  DISALLOW_LIST_VIEW_LIST,
} from './biobank/constants';
import {
  doesTypeAllowBulkUpload,
  doesTypeAllowUpload,
  doesTypeAllowView,
} from './biobank/utils';

export const HeaderBreadcrumb: FC = () => {
  const matches = useMatches();
  const is404 = matches.some(
    (match) => !!(match.data as { is404: boolean } | undefined)?.is404
  );

  const { pathname } = useLocation();
  const pathSegments = pathname.split('/');
  pathSegments.shift();

  if (is404) {
    pathSegments.splice(1);
  }

  if (pathSegments[0] === 'dereplication') {
    // hide project from dereplication breadcrumb
    if (pathSegments.length === 3) {
      pathSegments.splice(1, 1);
    }
  }

  if (pathSegments[0] === 'carts') {
    if (pathSegments.length === 3) {
      pathSegments[1] = `Carts ${pathSegments[1]} & ${pathSegments[2]}`;
      pathSegments.splice(2, 1);
    } else if (pathSegments.length === 2) {
      pathSegments[1] = `Cart ${pathSegments[1]}`;
    }
  }

  if (pathSegments[2] === 'upload') {
    pathSegments[2] = 'add many';
  }

  if (pathSegments[2] === 'new') {
    pathSegments[2] = 'add';
  }

  const items = pathSegments.map((seg, idx) => ({
    title: is404 ? '404' : humanize(seg),
    path: `${seg}`,
    // top-level dropdown
    ...(idx === 0 ? { menu: { items: FIRST_LEVEL_MENU_ITEMS } } : {}),
    // biobank main view dropdown
    ...(idx === 1 && pathSegments[0] === 'biobank'
      ? { menu: { items: BIOBANK_MENU_ITEMS } }
      : {}),
  }));

  return (
    <Breadcrumb
      items={items}
      itemRender={(route, params, routes, paths) => {
        const routeIndex = routes.indexOf(route);
        const isFinalSecondLevelRoute = routeIndex === 1 && routes.length === 2;

        const routeTitle = compute(() => {
          if (routes[0]?.path === 'jobs') {
            if (routeIndex === 1) {
              return `Series ${route.title}`;
            }

            if (routeIndex === 2) {
              return `Job ${route.title}`;
            }

            if (routeIndex === 3) {
              return `Refine ${route.title}`;
            }
          }

          return route.title;
        });

        if (
          is404 ||
          (routes[0]?.path === 'jobs' &&
            (routeIndex > 1 || isFinalSecondLevelRoute)) ||
          (routes[0]?.path === 'carts' && routeIndex > 0)
        ) {
          return <RegularTitle>{routeTitle}</RegularTitle>;
        }

        // TODO: this line is giving <a> child of <a> problems
        return <Link to={paths.join('/')}>{routeTitle}</Link>;
      }}
    />
  );
};

const RegularTitle = styled.span`
  cursor: default;
`;

const FIRST_LEVEL_MENU_ITEMS = [
  'biobank',
  'carts',
  'dereplication',
  'jobs',
  'projects',
  'tools',
].map((path, idx) => ({
  key: `${idx}`,
  label: <Link to={`/${path}`}>{humanize(path)}</Link>,
}));

const BIOBANK_MENU_ITEMS = (Object.keys(BIOBANK_TYPE_NAME) as BiobankType[])
  .sort((a, b) => BIOBANK_TYPE_NAME[a].localeCompare(BIOBANK_TYPE_NAME[b]))
  .map((path) => ({
    key: `${path}-`,
    label: DISALLOW_LIST_VIEW_LIST.includes(path) ? (
      <div
        style={{ cursor: 'default', display: 'inline-block', width: '100%' }}
      >
        {BIOBANK_TYPE_NAME[path]}s
      </div>
    ) : (
      <Link to={`/biobank/${path}`}>{BIOBANK_TYPE_NAME[path]}s</Link>
    ),
    children: [
      ...(BIOBANK_TYPE_NAME[path] && doesTypeAllowView(path as BiobankType)
        ? [
            {
              key: `${path}-view`,
              label: <Link to={`/biobank/${path}`}>View</Link>,
            },
          ]
        : []),
      ...(BIOBANK_TYPE_NAME[path] && doesTypeAllowUpload(path as BiobankType)
        ? [
            {
              key: `${path}-add`,
              label: <Link to={`/biobank/${path}/new`}>Add</Link>,
            },
          ]
        : []),
      ...(doesTypeAllowBulkUpload(path as BiobankType)
        ? [
            {
              key: `${path}-add-many`,
              label: <Link to={`/biobank/${path}/upload`}>Add Many</Link>,
            },
          ]
        : []),
    ],
  }));
