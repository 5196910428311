import { Select } from 'antd';
import { forwardRef, useEffect, useState } from 'react';

import { getBiobankList } from '../../../api';
import { useFetch } from '../../../hooks';
import { nullishPredicate } from '../../../utils';
import { TypeaheadType } from '../types';

type TypeaheadItem = {
  id: string;
  name?: string;
  description?: string;
  condition_code?: string;
};

interface Props {
  itemType: TypeaheadType;
  onChange?: (val: any) => void;
  size?: any;
  placeholder?: string;
}

const NAME_FIELD: Record<TypeaheadType, keyof TypeaheadItem> = {
  high_level_substrates: 'name',
  high_level_products: 'name',
  substrate_descriptors: 'description',
  product_descriptors: 'description',
  outgrowth_conditions: 'condition_code',
  food_species: 'name',
};

export const TypeaheadTag = forwardRef<any, Props>(
  ({ itemType, onChange, size, placeholder, ..._additionalProps }, ref) => {
    const [_selectedItems, setSelectedItems] = useState<any[]>([]);
    const [options, setOptions] = useState<any[]>([]);

    const { data: itemsBundle, loading } = useFetch<{
      items: TypeaheadItem[];
    }>(getBiobankList(itemType).url);
    const { items } = itemsBundle || { items: [] };

    useEffect(() => {
      setOptions(
        items.map((item) => {
          const { id } = item;
          const itemDescription = item[NAME_FIELD[itemType]];
          return {
            value: `${id}-${itemDescription}`,
            label: itemDescription,
          };
        })
      );
    }, [items.length, itemType]);

    const handleChange = (value: string[]) => {
      const foundItems = value
        .map((selectedOption) => {
          const id = selectedOption.match(/^\d+-/)?.[0].slice(0, -1);
          const item = items.find(({ id: itemId }) => `${id}` === `${itemId}`);
          if (item) {
            return item?.[NAME_FIELD[itemType]];
          } else {
            return selectedOption;
          }
        })
        .filter(nullishPredicate);
      setSelectedItems(foundItems);
      onChange?.(foundItems);
    };

    return (
      <Select
        // {...additionalProps}
        size={size}
        ref={ref}
        mode={itemType === 'food_species' ? 'tags' : 'multiple'}
        placeholder={loading ? 'Loading...' : placeholder}
        options={options}
        onChange={handleChange}
      />
    );
  }
);

TypeaheadTag.displayName = 'TypeaheadTag';
