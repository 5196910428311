import { BiobankType } from '../biobank';

export type TaskStartResult = {
  task_id: string;
  validation_issues: ValidationIssue[];
  dispatched: boolean;
  jobs_created: CreatedJob[];
};

export type ValidationIssue = {
  task_idx: number | null;
  field: string;
  message: string;
};

export type CreatedJob = {
  series_id: string;
  job_id: string;
  refine_id: string;
};

export enum TaskStatusStatus {
  Success = 'SUCCESS',
  Pending = 'PENDING',
  Failure = 'FAILURE',
}

export type TaskStatusResponse = {
  task_id: string;
  task_status: TaskStatusStatus;
  task_result: {
    tasks_submitted: boolean;
    validity_results: {
      job_id: string;
      refine_id: string;
      series_id: string;
      validity: 'VALID' | 'INVALID';
    }[];
  };
};

export type Job = {
  series_id: string;
  job_id: string;
  refine_id: string;
  job_status: JobResultStatus;
  task_name: string;
};

export type JobResult = {
  message: string;
  job_status: JobResultStatus;
  files: JobResultFile[];
  final: boolean;
  has_cart?: boolean;
};

export enum JobResultStatus {
  Created = 'CREATED',
  Queued = 'QUEUED',
  Started = 'STARTED',
  Succeeded = 'SUCCEEDED',
  Errored = 'ERRORED',
  Finished = 'FINISHED',
}

export enum JobResultFileType {
  HTML = 'html',
  Text = 'text',
  JSON = 'json',
}

export type JobResultFile = {
  file_path: string;
  file_type: JobResultFileType;
  tab_name: string;
  visible_to_users?: boolean;
};

export type AllJobsItem = {
  series_id: string;
  creation_date: string;
  jobs: Job[];
};

export type AllJobs = {
  count: number;
  items: AllJobsItem[];
};

export type SeriesJobs = {
  count: number;
  items: Job[];
};

export type AvailableTask = {
  display_name: string;
  endpoint_base: string;
};

export type TaskInputField = {
  attribute_name: string;
  default: string | null;
  help_text: string;
  label: string;
  options?: { display: string; key: string }[];
  placeholder: string | null;
  required?: boolean;
  vertical_position: number;
};

export enum TaskInputFieldType {
  Select = 'selects',
  SingleText = 'single_text',
  MultiText = 'multi_text',
}

export type TaskInputs = {
  header?: string;
  [TaskInputFieldType.Select]: TaskInputField[];
  [TaskInputFieldType.SingleText]: TaskInputField[];
  [TaskInputFieldType.MultiText]: TaskInputField[];
};

export type ReshapedTaskInputField = Omit<TaskInputField, 'default'> & {
  inputType: TaskInputFieldType;
  default_value: string | null;
};

export type TaskCartResult = {
  cart_type: BiobankType;
  list_of_ids: string[];
  success?: boolean;
  message?: string;
};
