import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Biobank } from '../components/biobank/Biobank';
import { BIOBANK_TYPE_NAME } from '../components/biobank/constants';
import { useDocumentTitle } from '../hooks';
import { BiobankType } from '../types';

export const BiobankScreen: FC = () => {
  const { type: routeType } = useParams();

  useDocumentTitle(`${BIOBANK_TYPE_NAME[routeType as BiobankType]}s - Biobank`);

  return (
    <div>
      <Biobank type={(routeType as BiobankType) || BiobankType.Foods} />
    </div>
  );
};
