import { Spin } from 'antd';
import { FC, useState } from 'react';
import DataGrid from 'react-data-grid';
import { useNavigate } from 'react-router-dom';
import { getJobs } from 'src/api/cartTasks';
import { JobStatusIcon } from 'src/components/jobs/JobStatusIcon';
import { SeriesListHeader } from 'src/components/jobs/SeriesListHeader';
import { AllJobsItem } from 'src/types/api/tasks';
import { isAtBottom } from 'src/utils';

import { useDocumentTitle, usePaginatedFetch } from '../hooks';

const COLUMNS = [
  {
    key: 'series_id',
    name: 'Series ID',
  },
  {
    key: 'creation_date',
    name: 'Date',
    formatter: (props: any) => (
      <span>{new Date(props.row.creation_date).toLocaleString('en-US')}</span>
    ),
  },
  {
    key: 'jobs_status',
    name: 'Status',
    width: '128px',
    formatter: (props: any) => (
      <span>
        {(props.row.jobs || []).length} jobs&nbsp;
        <JobStatusIcon jobs={props.row.jobs || []} />
      </span>
    ),
  },
];

export const SeriesListTable: FC = () => {
  useDocumentTitle(`Jobs`);

  const [dateStrings, setDateStrings] = useState<any>([null, null]);

  const [after, before] = dateStrings;

  const { loading, data, totalCount, loadMore } =
    usePaginatedFetch<AllJobsItem>(getJobs({ before, after }).url);

  const navigate = useNavigate();

  const onClick = ({ row }, event) => {
    event.preventGridDefault();
    event.preventDefault();

    navigate(`/jobs/${row.series_id}`);
  };

  const handleScroll = async (event: React.UIEvent<HTMLDivElement>) => {
    if (loading || !isAtBottom(event)) {
      return;
    }

    loadMore();
  };

  return (
    <div>
      <SeriesListHeader
        count={data?.length}
        totalCount={totalCount}
        loading={loading}
        onDateRangeChange={setDateStrings}
      />
      <Spin spinning={loading}>
        <DataGrid
          rows={data || []}
          columns={COLUMNS}
          className="rdg-light"
          style={{
            height: '85vh',
          }}
          rowClass={() => 'cursor-pointer'}
          rowKeyGetter={(row) => row.series_id}
          onCellClick={onClick}
          onCellDoubleClick={onClick}
          onScroll={handleScroll}
        />
      </Spin>
    </div>
  );
};
