import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Logo from '../assets/kingdom-logo.png';
import { COLORS } from '../constants';
import { useAuthUser, useUser } from '../hooks';
import { logout } from '../utils';
import { HeaderBreadcrumb } from './HeaderBreadcrumb';

export const Header = () => {
  const user = useUser();
  useAuthUser(user);

  return (
    <HeaderContainer>
      <div style={{ display: 'flex', gap: '24px' }}>
        <HeaderTitle to="/">
          <LogoImage src={Logo} alt="Kingdom Supercultures" />
        </HeaderTitle>
        <HeaderBreadcrumb />
      </div>
      {user ? (
        <UserContainer>
          <UserName>{user.email}</UserName>
          <LogoutButton onClick={() => logout()}>Logout</LogoutButton>
        </UserContainer>
      ) : (
        <AuthLink to="/login">Login</AuthLink>
      )}
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  background-color: ${COLORS.brand.orange};
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  white-space: nowrap;
  position: sticky;
`;

const HeaderTitle = styled(Link)`
  color: black;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;

  img {
    max-height: 24px;
  }
`;

const AuthLink = styled(Link)`
  color: black;
  font-size: 12px;
  text-decoration: none;
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const UserName = styled.div`
  color: black;
  font-size: 12px;
  text-decoration: none;
`;

const LogoutButton = styled.button`
  font-size: 10px;
  color: white;
  border: 1px solid gray;
  background-color: gray;
  cursor: pointer;
`;

const LogoImage = styled.img`
  filter: invert(1);
`;
