import { useEffect, useMemo } from 'react';

export const useDocumentTitle = (title: string) => {
  const oldTitle = useMemo(
    () => `${document?.title} - Kingdom Supercultures`,
    []
  );

  useEffect(() => {
    if (document) {
      document.title = `${title} - Kingdom Supercultures`;
    }

    return () => {
      if (document) {
        document.title = oldTitle;
      }
    };
  }, [title, oldTitle]);
};
