import { Button, Checkbox, Col, Popover, Row } from 'antd';
import { FC, useEffect, useState } from 'react';

import { nullishPredicate } from '../../../utils';
import { TableColumn } from '../types';

interface Props {
  columns?: TableColumn[];
  selectedColumns?: string[];
  setSelectedColumns: (c: string[]) => void;
  reset?: () => void;
}

export const ColumnPicker: FC<Props> = ({
  columns,
  selectedColumns,
  setSelectedColumns,
  reset,
}) => {
  if (!columns?.length) {
    return null;
  }

  return (
    <CheckboxMenu
      options={columns.map(({ schema_name, display_name }) => ({
        value: schema_name,
        label: display_name,
      }))}
      selectedOptions={selectedColumns}
      title="Columns"
      onChange={(vals) => setSelectedColumns(vals)}
      reset={reset}
    ></CheckboxMenu>
  );
};

const CheckboxMenu: FC<{
  options: { value: string; label: string }[];
  selectedOptions?: string[];
  title: string;
  onChange: (vals: any) => void;
  reset?: () => void;
}> = ({
  options,
  title,
  onChange: providedOnChange,
  selectedOptions = [],
  reset,
}) => {
  const [selectedItems, setSelectedItems] = useState<any[]>(selectedOptions);

  const optionsWithIndices = options.map((option, i) => ({
    ...option,
    index: i,
  }));

  useEffect(() => {
    setSelectedItems(selectedOptions);
  }, [selectedOptions]);

  const onChange = (selection: any[]) => {
    const sortedSelection = selection
      .map((field) => optionsWithIndices.find(({ value }) => value === field))
      .sort((a, b) => (a?.index || 0) - (b?.index || 0))
      .map((item) => item?.value);

    setSelectedItems([...sortedSelection]);
    providedOnChange([...sortedSelection]);
  };

  const CheckboxRender = () => {
    const groups = options
      .map((_e: any, i: number) => {
        return i % 10 === 0 ? options.slice(i, i + 10) : null;
      })
      .filter(nullishPredicate);

    return (
      <>
        <Checkbox.Group onChange={onChange} value={selectedItems}>
          <Row>
            {groups.map(
              (group: { value: string; label: string }[], i: number) => {
                return (
                  <Col
                    key={'checkbox-group-' + i}
                    span={Math.floor(24 / groups.length)}
                  >
                    {group.map(
                      ({ value, label }: { value: string; label: string }) => {
                        return (
                          <Checkbox
                            key={value}
                            value={value}
                            style={{ display: 'flex', margin: '0' }}
                          >
                            {label}
                          </Checkbox>
                        );
                      }
                    )}
                  </Col>
                );
              }
            )}
          </Row>
        </Checkbox.Group>
        <Row>
          <Button size="small" onClick={reset}>
            Reset
          </Button>
        </Row>
      </>
    );
  };

  return (
    <Popover
      content={<CheckboxRender />}
      trigger="click"
      placement="bottomLeft"
    >
      <Button size="small" style={buttonStyle}>
        {title}
      </Button>
    </Popover>
  );
};

const buttonStyle = {
  fontSize: '12px',
  alignItems: 'center',
  display: 'inline-flex',
  textDecoration: 'none',
};
