export type InputFields = {
  extra_instructions: string;
  fields: InputField[];
};

export type InputField = {
  display_name: string;
  schema_name: string;
  type: InputFieldType;
  related_type: RelatedType;
  required: boolean;
  explanation: string;
  placeholder: string;
  default: string;
  regex_validation: string | null;
};

export enum InputFieldType {
  Text = 'text',
  Foreign = 'foreign',
  ManyToMany = 'many-to-many',
  Boolean = 'boolean',
  Decimal = 'decimal',
  Date = 'date',
  String = 'string',
}

export enum RelatedType {
  HighLevelSubstrate = 'HighLevelSubstrate',
  HighLevelProduct = 'HighLevelProduct',
  SubstrateDescriptor = 'SubstrateDescriptor',
  ProductDescriptor = 'ProductDescriptor',
  FoodSpecies = 'FoodSpecies',
  CultureSource = 'CultureSource',
  CultureSourceTube = 'CultureSourceTube',
  CultureOutgrowthCondition = 'CultureOutgrowthCondition',
}
