import { forwardRef, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { createExperimentPlate, getAllExperiments } from '../api';
import { useFetch } from '../hooks';
import {
  APIExperimentWithPlates,
  APIPlateCreateBody,
  APIPlateWithAllChildren,
} from '../types';
import { authFetch } from '../utils';
import { Form, FormError, FormRow, Input, Label, Select } from '.';
import { ErrorText, Title } from './typography';

interface Props {
  onCreate?: (data: any) => void;
}

export interface CreatePlateRef {
  refresh: () => void;
}

export const CreatePlate = forwardRef<CreatePlateRef, Props>(
  ({ onCreate }, ref) => {
    const [error, setError] = useState<string | null>(null);
    const {
      register,
      formState: { errors },
      handleSubmit,
    } = useForm<APIPlateCreateBody & { experimentId: string }>();

    const { data: experiments = [], refresh } = useFetch<
      APIExperimentWithPlates[]
    >(getAllExperiments().url);

    useImperativeHandle(ref, () => ({ refresh }));

    const onSubmit = async (
      data: APIPlateCreateBody & { experimentId: string }
    ) => {
      setError(null);
      try {
        const { experimentId, ...newPlate } = data;
        newPlate.plate_type = '2D';
        const response = await authFetch<APIPlateWithAllChildren>(
          createExperimentPlate(experimentId, newPlate)
        );
        onCreate?.(response);
      } catch (e) {
        console.error((e as Error).message);
        setError((e as Error).message);
      }
    };

    return (
      <CreateProjectWrapper>
        <Title>Create Plate</Title>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormRow>
            <Label>
              Experiment:
              <Select {...register('experimentId', { required: true })}>
                {experiments.map(({ id, experiment_name }) => (
                  <option key={id} value={id}>
                    {experiment_name} ({id})
                  </option>
                ))}
              </Select>
            </Label>
          </FormRow>
          <FormRow>
            <Label>
              Plate Name:
              <Input
                {...register('plate_name', { required: true })}
                autoComplete="off"
              />
              {errors?.plate_name && (
                <FormError>Plate Name is required.</FormError>
              )}
            </Label>
          </FormRow>
          <FormRow>
            <Label>
              Size:
              <Select {...register('size', { required: true })}>
                {['24', '96', '384', '1536'].map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </Select>
              {errors?.plate_name && (
                <FormError>Plate Size is required.</FormError>
              )}
            </Label>
          </FormRow>
          <FormRow>
            <input type="submit" value="Create Plate" />
          </FormRow>
          {error && error.length ? (
            <FormRow>
              <ErrorText>Error: {error}</ErrorText>
            </FormRow>
          ) : null}
        </Form>
      </CreateProjectWrapper>
    );
  }
);

const CreateProjectWrapper = styled.div`
  max-width: 240px;
`;
