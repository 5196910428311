import { Button } from 'antd';
import { FC, useState } from 'react';
import { combineCarts } from 'src/api/cart';
import { BiobankType } from 'src/types';

import { Modal } from '../Modal';
import { CartView } from './CartView';

enum CombinationMode {
  Union = 'UNION',
  Intersection = 'INTERSECTION',
  Difference = 'DIFFERENCE',
}

interface Props {
  type: BiobankType;
  cartOne: number;
  cartTwo: number;
}

export const CartTools: FC<Props> = ({ type, cartOne, cartTwo }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [combinedUrl, setCombinedUrl] = useState<string | undefined>();

  const handleCombine = (mode: CombinationMode) => {
    setCombinedUrl(combineCarts(type, cartOne, cartTwo, mode).url);
    setIsModalOpen(true);
  };

  const combineFactory = (mode: CombinationMode) => () => handleCombine(mode);

  return (
    <div style={{ display: 'flex', gap: '8px' }}>
      <Button onClick={combineFactory(CombinationMode.Union)}>Union</Button>
      <Button onClick={combineFactory(CombinationMode.Intersection)}>
        Intersection
      </Button>
      <Button onClick={combineFactory(CombinationMode.Difference)}>
        Difference
      </Button>
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        width="100vw"
        noMaxWidth
      >
        <CartView type={type} baseUrl={combinedUrl} isInModal />
      </Modal>
    </div>
  );
};
