import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { Header } from './components';
import { useUser } from './hooks';
import { Login } from './screens';

export const Layout = () => {
  const user = useUser();

  return (
    <>
      <Header />
      <Wrapper data-name="app-wrapper">{user ? <Outlet /> : <Login />}</Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  padding: 18px;
  overflow: auto;
  max-height: 100vh;
`;
