import { Spin } from 'antd';
import { FC } from 'react';
import DataGrid from 'react-data-grid';
import { useNavigate } from 'react-router-dom';
import { useUserCarts } from 'src/hooks/useUserCarts';

import { BIOBANK_TYPE_NAME } from '../biobank/constants';

const COLUMNS = [
  {
    key: 'name',
    name: 'Name',
    formatter: (props: any) => <span>{props.row.name}</span>,
  },
  {
    key: 'type',
    name: 'Type',
    formatter: (props: any) => (
      <span>{BIOBANK_TYPE_NAME[props.row.type] || props.row.type}</span>
    ),
  },
  {
    key: 'id',
    name: 'ID',
  },
];

export const CartsTable: FC = () => {
  const { data, loading } = useUserCarts();

  const navigate = useNavigate();

  const onClick = ({ row }, event) => {
    event.preventGridDefault();
    event.preventDefault();

    navigate(`/carts/${row.id}`);
  };

  return (
    <Spin spinning={loading}>
      <DataGrid
        rows={data || []}
        columns={COLUMNS}
        className="rdg-light"
        style={{
          height: '85vh',
        }}
        rowClass={() => 'cursor-pointer'}
        onCellClick={onClick}
        onCellDoubleClick={onClick}
        rowKeyGetter={(row) => row.id}
      />
    </Spin>
  );
};
