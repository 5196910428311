import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { COLORS } from 'src/constants';
import styled from 'styled-components';

import Logo from '../assets/logo_orange.png';

export const Home = () => {
  return (
    <Wrapper>
      <Row gutter={16}>
        <Col span={8} />
        <Col span={8}>
          <LogoImage src={Logo} />
        </Col>
        <Col span={8} />
      </Row>
      <InnerWrapper>
        <GapRow>
          <Col xs={4} />
          <Col xs={6}>
            <LinkButton to="/biobank/strains">Strains</LinkButton>
          </Col>
          <Col xs={4} />
          <Col xs={6}>
            <LinkButton to="/biobank/foods">Foods</LinkButton>
          </Col>
          <Col xs={4} />
        </GapRow>
        <GapRow>
          <Col xs={2} />
          <Col xs={6}>
            <LinkButton to="/dereplication">Dereplication</LinkButton>
          </Col>
          <Col xs={1} />
          <Col xs={6}>
            <LinkButton to="/carts">Carts</LinkButton>
          </Col>
          <Col xs={1} />
          <Col xs={6}>
            <LinkButton to="/jobs">Results</LinkButton>
          </Col>
          <Col xs={2} />
        </GapRow>
        <Row>
          <Col xs={4} />
          <Col xs={6}>
            <LinkButton to="/tools">Tools</LinkButton>
          </Col>
          <Col xs={4} />
          <Col xs={6}>
            <LinkButton to="/biobank/strains">Biobank</LinkButton>
          </Col>
          <Col xs={4} />
        </Row>
      </InnerWrapper>
    </Wrapper>
  );
};

const LinkButton = styled(Link)`
  display: flex;
  border: 2px solid white;
  border-radius: 20px;
  padding: 16px 0px;
  font-size: 18px;
  justify-content: center;
  font-weight: bold;
  color: black;

  &:hover {
    color: white;
    background-color: ${COLORS.brand.orange};
  }
`;

const Wrapper = styled.div`
  margin: -18px;
  padding: 18px;
  max-height: 100vh;
  height: 100vh;
  max-width: 100vw;
  background-color: ${COLORS.brand.orangeAlpha(0.5)};
`;

const InnerWrapper = styled.div`
  max-width: 700px;
  margin: 0 auto;
`;

const LogoImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const GapRow = styled(Row)`
  margin-bottom: 16px;
`;
