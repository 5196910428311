import styled from 'styled-components';

export const HugeTitle = styled.h1``;
export const BigTitle = styled.h2``;
export const Title = styled.h3``;
export const SubTitle = styled.h4``;
export const FieldTitle = styled.span`
  color: grey;
  font-size: small;
  cursor: default;
`;

export const ErrorText = styled.span`
  color: red;
  font-size: 12px;
`;
