import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import { deleteBiobankEntity } from 'src/api';
import { API_ROUTES } from 'src/constants';
import { BiobankType, FetchOptions } from 'src/types';
import { authFetch } from 'src/utils';
import styled from 'styled-components';

import { ID_FIELD_BY_TYPE, NAME_FIELD_BY_TYPE } from '../constants';

export const ViewCardHeader = ({
  item,
  itemType,
  isAdding,
  hasUnsavedChanges,
  refreshData,
  onClose,
  toggleIsEditing,
}) => {
  const deepLinkId = item?.[ID_FIELD_BY_TYPE[itemType]];
  const itemNameField = NAME_FIELD_BY_TYPE[itemType as BiobankType];
  const itemDisplayName = !isAdding ? (item as any)?.[itemNameField] : '';
  const isDeletable = !!API_ROUTES.biobank.deleteEntity(itemType, item || {});

  return (
    <>
      <Link to={`/biobank/${itemType}/${deepLinkId}`}>{itemDisplayName}</Link>
      <ControlsWrapper>
        {isDeletable &&
          (!hasUnsavedChanges ? (
            <EditOutlined
              style={{ fontSize: 18 }}
              onClick={toggleIsEditing}
              title="Edit"
            />
          ) : (
            <SaveOutlined
              style={{ fontSize: 18 }}
              onClick={toggleIsEditing}
              title="Save"
            />
          ))}

        {!isAdding && isDeletable && (
          <DeleteOutlined
            style={{ fontSize: 18 }}
            title="Delete"
            onClick={() => {
              Modal.confirm({
                title: `Are you sure you want to delete ${itemDisplayName}?`,
                onOk: async () => {
                  await authFetch(
                    deleteBiobankEntity(itemType, item) as FetchOptions
                  );
                  refreshData();
                  onClose();
                },
              });
            }}
          />
        )}
        <CloseOutlined onClick={onClose} />
      </ControlsWrapper>
    </>
  );
};

const ControlsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;
