import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { FC } from 'react';

import { Title } from '../../typography';

interface Props {
  loading?: boolean;
  error?: any;
  loadingText?: string;
  errorText?: string;
}

export const LoadingErrorDisplay: FC<Props> = ({
  loading,
  error,
  loadingText = 'Loading',
  errorText = 'Item not found',
}) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        {loading ? (
          <>
            <LoadingOutlined style={{ fontSize: 18 }} />
            <Title>{loadingText}</Title>
          </>
        ) : null}
        {error ? (
          <>
            <ExclamationCircleOutlined style={{ fontSize: 18 }} />
            <Title>{errorText}</Title>
          </>
        ) : null}
      </div>
    </div>
  );
};
