import { Tooltip } from 'antd';
import {
  Column,
  headerRenderer,
  HeaderRendererProps,
  SelectColumn,
  SortColumn,
} from 'react-data-grid';
import styled from 'styled-components';

import { getBiobankList } from '../../api';
import { BiobankType } from '../../types';
import { nullishPredicate } from '../../utils';
import {
  ADDABLE_TYPES,
  BULK_UPLOAD_TYPES,
  DISALLOW_LIST_VIEW_LIST,
  SEARCHABLE_TYPES,
  SHOPPABLE_TYPES,
  TABLE_COLUMN_DISPLAY_FORMATTERS_BY_TYPE,
  TABLE_COLUMN_DOWNLOAD_FORMATTERS_BY_TYPE,
} from './constants';
import { TableColumn, TableColumnType } from './types';

export const getItemFieldValue = (item: any, fieldName: string) => {
  const fields = fieldName.split('.');
  let value: any = item;
  for (const field of fields) {
    value = value?.[field];
  }
  return value;
};

export const doesTypeAllowSearching = (type: BiobankType) => {
  return SEARCHABLE_TYPES.includes(type);
};

export const doesTypeAllowBulkUpload = (type: BiobankType) => {
  return BULK_UPLOAD_TYPES.includes(type);
};

export const doesTypeAllowUpload = (type: BiobankType) => {
  return ADDABLE_TYPES.includes(type);
};

export const doesTypeAllowView = (type: BiobankType) => {
  return !DISALLOW_LIST_VIEW_LIST.includes(type);
};

export const doesTypeAllowShopping = (type: BiobankType) =>
  SHOPPABLE_TYPES.includes(type);

export const getBiobankAPIQueryString = ({
  sortColumns,
  searchField,
  searchValue,
  hasQ,
}: {
  sortColumns: readonly SortColumn[];
  searchField?: string;
  searchValue?: string;
  hasQ?: boolean;
}) => {
  const startingChar = hasQ ? '&' : '?';

  const hasSort = !!sortColumns.length;
  const hasSearch = !!searchField && !!searchValue?.length;
  const searchString = hasSearch ? `q=${searchValue}&q_col=${searchField}` : '';
  const sortString = hasSort
    ? `order_by=${sortColumns
        .map((col) => `${col.direction === 'ASC' ? '' : '-'}${col.columnKey}`)
        .join(',')}`
    : '';

  if (!searchString && !sortString) {
    return '';
  }

  return `${startingChar}${searchString}${
    hasSort && hasSearch ? '&' : ''
  }${sortString}`;
};

export const getDataSourceUrl = ({
  type,
  sortColumns,
  searchField,
  searchValue,
  baseUrl,
}: {
  type: BiobankType;
  sortColumns: readonly SortColumn[];
  searchField?: string;
  searchValue?: string;
  baseUrl?: string;
}) => {
  const fetchUrl = baseUrl || getBiobankList(type).url;
  const hasQ = /\?/.test(fetchUrl);

  const queryString = doesTypeAllowSearching(type)
    ? getBiobankAPIQueryString({
        sortColumns,
        searchField,
        searchValue,
        hasQ,
      })
    : '';

  const dataSourceUrl = `${fetchUrl}${queryString}`;

  return dataSourceUrl;
};

const TooltipContents = styled.div`
  white-space: pre-wrap;
  font-size: 10px;
`;

const getDownloadTransformFromTableColumnType = (type: TableColumnType) => {
  if (type.startsWith('array')) {
    const mainType = type.match(/^array\[([^\]]*)/)?.[1];
    const mainFormatter = getDownloadTransformFromTableColumnType(
      mainType as TableColumnType
    );
    return (item: any) =>
      (item || []).map((subItem) => mainFormatter(subItem)).join(', ');
  } else if (TABLE_COLUMN_DOWNLOAD_FORMATTERS_BY_TYPE[type]) {
    return TABLE_COLUMN_DOWNLOAD_FORMATTERS_BY_TYPE[type];
  }

  return (item: any) => item;
};

const getDisplayFormatterFromTableColumn = (column: TableColumn) => {
  const { default_display, schema_name, format_info, type } = column;

  if (type.startsWith('array')) {
    const mainType = type.match(/^array\[([^\]]*)/)?.[1];

    const MainFormatter = getDisplayFormatterFromTableColumn({
      type: mainType as TableColumnType,
      schema_name,
      display_name: '',
      default_display,
      format_info,
    });

    return (props: any) => (
      <span>
        {props.row[schema_name].map((item: any, index) => {
          return <MainFormatter key={index} row={{ [schema_name]: item }} />;
        })}
      </span>
    );
  } else if (format_info === 'italics') {
    return (props: any) => (
      <span style={{ fontStyle: 'italic' }}>{props.row[schema_name]}</span>
    );
  } else if (TABLE_COLUMN_DISPLAY_FORMATTERS_BY_TYPE[type]) {
    return TABLE_COLUMN_DISPLAY_FORMATTERS_BY_TYPE[type](schema_name);
  }

  return (props: any) => <span>{props.row[schema_name]}</span>;
};

export const getGridColumnsFromTableColumns = (
  selectedTableColumns?: TableColumn[]
): Column<any, any>[] => {
  return [
    SelectColumn,
    ...(selectedTableColumns || [])
      .map((column) => {
        const { display_name, schema_name } = column;

        return {
          key: schema_name,
          name: display_name,
          formatter: getDisplayFormatterFromTableColumn(column),
          width: schema_name === 'id' ? '16px' : undefined,
          headerRenderer: (props: HeaderRendererProps<any, any>) => {
            return (
              <Tooltip
                mouseEnterDelay={0.5}
                title={
                  <TooltipContents>
                    Click a column once to sort, again to change sort direction.
                    <br />
                    Sort multiple columns using ⌘-click.
                  </TooltipContents>
                }
                trigger={'hover'}
              >
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    pointerEvents: 'none',
                  }}
                />
                {headerRenderer(props)}
              </Tooltip>
            );
          },
        };
      })
      .filter(nullishPredicate),
  ];
};

export const generateRowsTSV = (
  rowData: Record<string, any>[],
  selectedTableColumns: TableColumn[]
) => {
  const downloadRows = [
    `"${selectedTableColumns
      .map(({ display_name }) => display_name)
      .join('"\t"')}"`,
  ];
  const columnsMap = selectedTableColumns.reduce((acc, curr) => {
    acc[curr.schema_name] = curr;
    return acc;
  }, {});

  for (const row of rowData) {
    const finalData: Record<string, any> = {};

    for (const key of Object.keys(row)) {
      const column = columnsMap[key];
      if (!column) {
        continue;
      }
      finalData[key] = getDownloadTransformFromTableColumnType(column.type)(
        row[key]
      );
    }

    const finalRowOutput = selectedTableColumns.map(({ schema_name }) => {
      return finalData[schema_name];
    });
    downloadRows.push(`"${finalRowOutput.join('"\t"')}"`);
  }

  return downloadRows.join('\n');
};

export const getNestedItemDisplayName = ({
  item,
  itemType,
  isNameOnly,
}: {
  item?: any;
  itemType: BiobankType;
  isNameOnly?: boolean;
}) => {
  if (isNameOnly) {
    return item;
  }

  if (itemType === BiobankType.Genomes) {
    return item?.genome_assembly_id || '(none)';
  }

  if (itemType === BiobankType.Foods) {
    return item?.descriptive_name || item?.short_name
      ? `${item?.descriptive_name || ''} (${item?.short_name || ''})`
      : '(none)';
  }

  if (itemType === BiobankType.FoodTubes) {
    return item?.barcode || item?.location || item?.well
      ? `${item?.barcode || ''} ${item?.location || ''} ${item?.well || ''} ${
          item?.collection_date || ''
        }`
      : '(none)';
  }

  if (itemType === BiobankType.PickPlates) {
    return item?.name || item?.id
      ? `${item?.name || ''} (${item?.id || ''})`
      : '(none)';
  }

  if (itemType === BiobankType.IsolationPlates) {
    return item?.name || '(none)';
  }

  if (itemType === BiobankType.Strains) {
    return item?.name || item?.id ? `${item?.name} (${item?.id})` : '(none)';
  }
};
