import { API_ROUTES } from '../constants';
import { APIProjectCreateBody, HTTPMethod } from '../types';

export const createProject = (newProject: APIProjectCreateBody) => ({
  url: API_ROUTES.projects.create(),
  options: {
    method: HTTPMethod.POST,
    data: newProject,
  },
});

export const getProjects = () => ({ url: API_ROUTES.projects.getAll() });

export const getProject = (projectCode: string) => ({
  url: API_ROUTES.projects.getOne(projectCode),
});
