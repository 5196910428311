import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { AddCard } from '../components/biobank/add/AddCard';
import { useDocumentTitle } from '../hooks';
import { BiobankType } from '../types';
import { humanize } from '../utils';

export const BiobankAddItem: FC = () => {
  const { type } = useParams();

  useDocumentTitle(`Add  ${humanize(type)}`);

  return (
    <div>
      <AddCard itemType={type as BiobankType} isSingleRoute />
    </div>
  );
};
