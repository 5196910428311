import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Title } from 'src/components/typography';

import { getChemistrySampleSubmissions } from '../api/chemistry';
import { useFetch } from '../hooks';

export const ChemistrySampleSubmissions: FC = () => {
  const { projectId } = useParams();
  const { data: sampleSubmissions } = useFetch<any[]>(
    getChemistrySampleSubmissions().url
  );

  console.log(sampleSubmissions);

  return (
    <div>
      <Title>{projectId} Chemistry Sample Submissions</Title>
    </div>
  );
};
