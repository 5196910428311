import { Button, Result, Spin } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useHydratedJobResults } from 'src/hooks/useHydratedJobResults';
import { useJobSeries } from 'src/hooks/useJobSeries';
import { CreatedJob } from 'src/types/api/tasks';
import { compute, scrollToWindowBottom } from 'src/utils';
import styled from 'styled-components';

import { JobResultsModal } from '../jobs/JobResultsModal';

interface Props {
  jobsCreated: CreatedJob[];
  isFullPage?: boolean;
  fullPageLoading?: boolean;
}

export const RunTaskModalPostSubmission: FC<Props> = ({
  jobsCreated,
  isFullPage,
}) => {
  const [isPolling, setIsPolling] = useState(false);
  const { series_id } = jobsCreated[0] || {};

  const { /* loading, */ data } = useJobSeries(series_id);

  const loadedJob = data?.items[0];

  useEffect(() => {
    setTimeout(() => setIsPolling(true), 2500);
    scrollToWindowBottom();
  }, []);

  const { isHydrationStarted, isFinal, isOneFileLoaded, hasCart, files } =
    useHydratedJobResults(loadedJob);

  const { subtitle, extra } = compute(() => {
    if (isHydrationStarted && isFinal) {
      return { subtitle: 'Task complete.', extra: 'Loading outputs...' };
    }

    if (isHydrationStarted && !isFinal) {
      return { subtitle: 'Task processing...', extra: <Spin /> };
    }

    if (loadedJob && !isFinal) {
      return { subtitle: 'Task processing...' };
    }

    if (loadedJob && isFinal) {
      return {
        subtitle: 'Task complete.',
        extra: (
          <Button href={`/jobs/${series_id}`} target="_blank">
            View Job
          </Button>
        ),
      };
    }

    if (isPolling) {
      return { subtitle: 'Task processing...', extra: <Spin /> };
    }

    return { subtitle: 'Task dispatch successful.' };
  });

  useEffect(() => {
    if (isHydrationStarted) {
      scrollToWindowBottom();
    }
  }, [isHydrationStarted]);

  useEffect(() => {
    if (isOneFileLoaded) {
      scrollToWindowBottom();
    }
  }, [isOneFileLoaded]);

  if (files.length && loadedJob) {
    return (
      <Wrapper>
        <JobResultsModal
          isEmbed
          hideOpenJob={isFullPage}
          job={loadedJob}
          files={files}
          loading={isHydrationStarted && !isFinal}
          complete={isFinal}
          hasCart={hasCart}
        />
      </Wrapper>
    );
  }

  if (isFullPage) {
    return (
      <Wrapper>
        <Spin size="large" />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Result subTitle={subtitle} extra={extra}></Result>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
