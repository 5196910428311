import { LoggedInUser } from '../types';
import { useFetch } from './useFetch';

export const useUser = (): LoggedInUser | undefined => {
  const theUser = localStorage.getItem('user');

  if (theUser && !theUser.includes('undefined')) {
    return JSON.parse(theUser);
  }
};

export const useAuthUser = (user?: LoggedInUser) => {
  useFetch(
    user
      ? `${process.env.REACT_APP_API_HOST}/api/authentication/user/${user?.id}`
      : null
  );
};
