import { FC } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { createProject } from '../api';
import { APIProjectCreateBody } from '../types';
import { authFetch } from '../utils';
import { Form, FormError, FormRow, Input, Label } from '.';
import { Title } from './typography';

interface Props {
  onCreate?: () => void;
}

export const CreateProject: FC<Props> = ({ onCreate }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<APIProjectCreateBody>();

  const onSubmit = async (data: APIProjectCreateBody) => {
    try {
      data.project_started = new Date(data.project_started).toISOString();
      await authFetch(createProject(data));
      onCreate?.();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <CreateProjectWrapper>
      <Title>Create Project</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <Label>
            Project Name:
            <Input
              {...register('project_name', { required: true })}
              autoComplete="off"
            />
            {errors?.project_name && (
              <FormError>Project Name is required.</FormError>
            )}
          </Label>
        </FormRow>
        <FormRow>
          <Label>
            Project Short Code:
            <Input
              {...register('project_short_code', { required: true })}
              autoComplete="off"
            />
            {errors?.project_short_code && (
              <FormError>Project Short Code is required.</FormError>
            )}
          </Label>
        </FormRow>
        <FormRow>
          <Label>
            Status:
            <Input
              {...register('status', { required: true })}
              autoComplete="off"
            />
            {errors?.status && <FormError>Status is required.</FormError>}
          </Label>
        </FormRow>
        <FormRow>
          <Label>
            Start Date:
            <Input
              type="date"
              {...register('project_started', { required: true })}
            />
            {errors?.project_started && (
              <FormError>Start Date is required.</FormError>
            )}
          </Label>
        </FormRow>
        <FormRow>
          <input type="submit" value="Create Project" />
        </FormRow>
      </Form>
    </CreateProjectWrapper>
  );
};

const CreateProjectWrapper = styled.div`
  max-width: 240px;
`;
