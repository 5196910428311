import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CartPicker } from './CartPicker';
import { CartTools } from './CartTools';
import { CartView } from './CartView';

export const Carts = () => {
  const navigate = useNavigate();

  const { cartOneId, cartTwoId } =
    useParams<{ cartOneId?: string; cartTwoId?: string }>();

  const [cartOne, setCartOne] = useState<any | undefined>();
  const [cartTwo, setCartTwo] = useState<any | undefined>();

  const hasCartOne = !!cartOne;
  const hasCartTwo = !!cartTwo;
  const hasBothCarts = hasCartOne && hasCartTwo;

  useEffect(() => {
    if (cartOne && !cartOneId) {
      setCartOne(undefined);
    }
    if (cartTwo && !cartTwoId) {
      setCartTwo(undefined);
    }
  }, [cartOne, cartTwo, cartOneId, cartTwoId]);

  const onSelectCartOneId = (id: number) => {
    if (id) {
      navigate(`/carts/${id}${cartTwoId ? `/${cartTwoId}` : ''}`);
    } else if (cartTwoId) {
      navigate(`/carts/${cartTwoId}`);
    } else {
      navigate(`/carts`);
    }
  };

  const onSelectCartTwoId = (id: number) => {
    if (id && cartOneId) {
      navigate(`/carts/${cartOneId}/${id}`);
    } else if (cartOneId) {
      navigate(`/carts/${cartOneId}`);
    } else {
      navigate(`/carts`);
    }
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '8px',
          gap: '32px',
        }}
      >
        <CartPicker
          onHydrateCart={setCartOne}
          onSelectCartId={onSelectCartOneId}
          type={cartTwo?.type}
          cartId={cartOneId ? +cartOneId : undefined}
        />
        {hasBothCarts ? (
          <CartTools
            cartOne={cartOne.id}
            cartTwo={cartTwo.id}
            type={cartOne.type}
          />
        ) : null}
        {hasCartOne ? (
          <CartPicker
            onHydrateCart={setCartTwo}
            onSelectCartId={onSelectCartTwoId}
            type={cartOne.type}
            placeholder="Select a second cart"
            cartId={cartTwoId ? +cartTwoId : undefined}
          />
        ) : null}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '8px',
          gap: '16px',
        }}
      >
        {hasCartOne ? (
          <CartView {...cartOne} numActiveViews={hasBothCarts ? 2 : 1} />
        ) : null}
        {hasCartTwo ? <CartView {...cartTwo} numActiveViews={2} /> : null}
      </div>
    </div>
  );
};
