import { FC } from 'react';

import { StyledSelect } from '../../forms';
import { TableColumn } from '../types';

export const SearchFieldsSelect: FC<{
  value: any;
  onSelect: (val: any) => void;
  tableColumns?: TableColumn[];
}> = ({ onSelect, value, tableColumns }) => {
  if (!tableColumns) {
    return null;
  }

  return (
    <StyledSelect
      placeholder="Search field"
      popupMatchSelectWidth={false}
      options={tableColumns.map(({ schema_name, display_name }) => ({
        value: schema_name,
        label: display_name,
      }))}
      onChange={onSelect}
      value={value}
      size="small"
    />
  );
};
