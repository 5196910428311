import { FC } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { createProjectExperiment } from '../api';
import { APIExperimentCreateBody, APIProject } from '../types';
import { authFetch } from '../utils';
import { Form, FormError, FormRow, Input, Label, Select } from '.';
import { Title } from './typography';

interface Props {
  onCreate?: () => void;
  projects: APIProject[];
}

export const CreateExperiment: FC<Props> = ({ onCreate, projects }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<APIExperimentCreateBody & { projectId: string }>();

  // const { data: projects = [] } = useFetch<APIProject[]>(getProjects().url);

  const onSubmit = async (
    data: APIExperimentCreateBody & { projectId: string }
  ) => {
    try {
      await authFetch(
        createProjectExperiment(data.projectId, {
          experiment_name: data.experiment_name,
        })
      );
      onCreate?.();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <CreateProjectWrapper>
      <Title>Create Experiment</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <Label>
            Project:
            <Select {...register('projectId', { required: true })}>
              {projects.map(({ id, project_name, project_short_code }) => (
                <option key={id} value={id}>
                  {project_short_code} - {project_name}
                </option>
              ))}
            </Select>
          </Label>
        </FormRow>
        <FormRow>
          <Label>
            Experiment Name:
            <Input
              {...register('experiment_name', { required: true })}
              autoComplete="off"
            />
            {errors?.experiment_name && (
              <FormError>Experiment Name is required.</FormError>
            )}
          </Label>
        </FormRow>
        <FormRow>
          <input type="submit" value="Create Experiment" />
        </FormRow>
      </Form>
    </CreateProjectWrapper>
  );
};

const CreateProjectWrapper = styled.div`
  max-width: 240px;
`;
