import { Collapse, Divider } from 'antd';
import { FC, Fragment } from 'react';
import styled from 'styled-components';

import { useDocumentTitle } from '../../../hooks';
import { BiobankType } from '../../../types';
import { APIFood, APIFoodTube } from '../../../types/api/biobank';
import { Checkbox, Textarea } from '../../forms';
import { FieldTitle as GenericFieldTitle, Title } from '../../typography';
import {
  BIOBANK_TYPE_NAME,
  NAME_FIELD_BY_TYPE,
  VIEW_ITEM_FIELD_VALUES_BY_TYPE,
} from '../constants';
import { RunTaskModal } from '../RunTaskModal';
import { FIELD_TYPES } from '../types';
import { getItemFieldValue } from '../utils';
import {
  DownloadDisplayer,
  FoodKeyData,
  SafetyDisplayer,
  SpanDisplayer,
  SpeciesDisplayer,
  TagDisplayer,
} from './displayers';
import { KronaEmbed } from './Krona';
import { NestedDisplayer } from './ViewCard';

interface Props {
  item: APIFood | APIFoodTube | any;
  itemId?: string;
  itemType: BiobankType;
}

export const ViewItem: FC<Props> = ({ item, itemId, itemType }) => {
  const itemNameField = NAME_FIELD_BY_TYPE[itemType as BiobankType];
  const itemDisplayName = (item as any)?.[itemNameField] || itemId;
  const isStrain = itemType === BiobankType.Strains;
  const isGenome = itemType === BiobankType.Genomes;
  const hasPageTools = isGenome || isStrain;

  useDocumentTitle(`${itemDisplayName}`);

  if (!item) {
    return null;
  }

  return (
    <BodyWrapper>
      <Body>
        {![
          BiobankType.Strains,
          BiobankType.PickPlates,
          BiobankType.Foods,
        ].includes(itemType) ? (
          <>
            <FieldTitle>{BIOBANK_TYPE_NAME[itemType]} ID</FieldTitle>
          </>
        ) : null}
        <CardTitle>{itemDisplayName}</CardTitle>
        {itemType === BiobankType.Strains ? (
          <SpeciesDisplayer phylogeny={item?.strain_phylogeny} />
        ) : null}
        {itemType === BiobankType.Strains ? (
          <SafetyDisplayer item={item} />
        ) : null}
        {itemType === BiobankType.Foods ? <FoodKeyData item={item} /> : null}

        <Group>
          {VIEW_ITEM_FIELD_VALUES_BY_TYPE[
            itemType as keyof typeof VIEW_ITEM_FIELD_VALUES_BY_TYPE
          ]?.map(
            ({
              title,
              field,
              type,
              tagFieldNames,
              tagOptions,
              nestedFieldName,
              nestedFieldType,
              derive,
            }) => {
              const itemValue = getItemFieldValue(item as any, field);

              if (type === FIELD_TYPES.Derived) {
                return (
                  <Fragment key={field}>
                    <FieldTitle>{title}</FieldTitle>
                    <span>{derive?.(item)}</span>
                  </Fragment>
                );
              }

              if (type === FIELD_TYPES.DownloadURL) {
                return (
                  <Fragment key={field}>
                    <FieldTitle>{title}</FieldTitle>
                    <DownloadDisplayer item={item} field={field} />
                  </Fragment>
                );
              }

              if (type === FIELD_TYPES.Species) {
                return (
                  <Fragment key={field}>
                    <SpeciesDisplayer phylogeny={itemValue} />
                    <FieldTitle />
                  </Fragment>
                );
              }

              if (type === FIELD_TYPES.Nested) {
                return (
                  <Fragment key={field}>
                    <FieldTitle>{title}</FieldTitle>
                    <NestedDisplayer
                      item={
                        nestedFieldName
                          ? itemValue?.[nestedFieldName]
                          : itemValue
                      }
                      itemType={nestedFieldType}
                    />
                  </Fragment>
                );
              }

              if (type === FIELD_TYPES.NestedByName) {
                return (
                  <Fragment key={field}>
                    <FieldTitle>{title}</FieldTitle>
                    <NestedDisplayer
                      item={
                        nestedFieldName
                          ? itemValue?.[nestedFieldName]
                          : itemValue
                      }
                      itemType={nestedFieldType}
                      isNameOnly
                    />
                  </Fragment>
                );
              }

              if (
                (type === FIELD_TYPES.Tags ||
                  type === FIELD_TYPES.TypeaheadTag) &&
                itemValue
              ) {
                return (
                  <Fragment key={field}>
                    <FieldTitle>{title}</FieldTitle>
                    <TagDisplayer
                      values={itemValue as any[]}
                      fieldNames={tagFieldNames!}
                      tagOptions={tagOptions}
                    />
                  </Fragment>
                );
              }

              let InputComponent: any = SpanDisplayer;
              let inputType: any;

              switch (type) {
                case FIELD_TYPES.Textarea:
                  InputComponent = Textarea;
                  break;
                case FIELD_TYPES.Boolean:
                  InputComponent = Checkbox;
                  inputType = 'checkbox';
                  break;
              }
              return (
                <Fragment key={field}>
                  <FieldTitle>{title}</FieldTitle>
                  <InputWrapper>
                    <InputComponent
                      disabled
                      value={
                        type === FIELD_TYPES.Boolean
                          ? undefined
                          : itemValue || undefined
                      }
                      checked={
                        type === FIELD_TYPES.Boolean ? itemValue : undefined
                      }
                      type={inputType}
                    />
                  </InputWrapper>
                </Fragment>
              );
            }
          )}
          {isStrain ? (
            <>
              <FieldTitle>Krona</FieldTitle>
              <InputWrapper>
                <KronaEmbed name={itemDisplayName} />
              </InputWrapper>
            </>
          ) : null}
        </Group>
        {hasPageTools ? (
          <>
            <Divider />
            <Collapse
              items={[
                {
                  key: 'tools',
                  label: 'Tools',
                  children: (
                    <RunTaskModal
                      cartType={itemType}
                      strainName={isStrain ? itemDisplayName : undefined}
                      genomeUuid={isGenome ? item?.uuid : undefined}
                      isPage
                    />
                  ),
                },
              ]}
            />
          </>
        ) : null}
      </Body>
    </BodyWrapper>
  );
};

const BodyWrapper = styled.div`
  max-width: 80vw;
  margin: 0 auto;
  overflow: auto;
  height: 100%;
`;

const Body = styled.div`
  padding: 16px 64px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 64px;
`;

const Group = styled.div`
  padding: 16px 0px;
  border-top: 1px solid lightgrey;
  display: grid;
  grid-template-columns: 120px auto;
  row-gap: 16px;
  column-gap: 8px;
  align-items: flex-start;
`;

const InputWrapper = styled.div`
  > * {
    width: 100%;
  }
  > input[type='checkbox'] {
    width: auto;
  }
`;

const CardTitle = styled(Title)`
  margin: 0;
`;

const FieldTitle = styled(GenericFieldTitle)`
  padding-top: 4px;
`;
