import { BACKEND_TYPE_BY_TYPE } from '../components/biobank/constants';
import { BiobankType } from '../types';

const { REACT_APP_API_HOST } = process.env;
const API_BASE_ROUTE = `${REACT_APP_API_HOST}/api/kingdom`;
const DEREPLICATION_BASE_ROUTE = `${REACT_APP_API_HOST}/api/dereplication`;
const GENOME_BASE_ROUTE = `${REACT_APP_API_HOST}/api/genomes`;
const COMPUTATIONAL_BASE_ROUTE = `${REACT_APP_API_HOST}/api/computational`;

export const API_ROUTES = {
  tools: {
    getTask: (id: string) => `${COMPUTATIONAL_BASE_ROUTE}/tasks/${id}`,
    blast: () => `${COMPUTATIONAL_BASE_ROUTE}/genome_blastp`,
    ani: () => `${COMPUTATIONAL_BASE_ROUTE}/run/strain_page_ani`,
  },
  treeView: () => `${DEREPLICATION_BASE_ROUTE}/tree_view`,
  projects: {
    // accepts APIProjectCreateBody
    // responds with APIProject
    create: () => `${API_BASE_ROUTE}/projects`,
    // responds with APIProject[]
    getAll: () => `${API_BASE_ROUTE}/projects`,
    // responds with APIProject
    getOne: (project_id: string) => `${API_BASE_ROUTE}/projects/${project_id}`,
  },
  experiments: {
    // accepts APIExperimentCreateBody
    // responds with APIExperiment
    createProjectExperiment: (project_id: string) =>
      `${API_BASE_ROUTE}/projects/${project_id}/experiments`,
    // responds with APIExperimentWithPlates[]
    getAll: () => `${API_BASE_ROUTE}/experiments`,
    // responds with APIExperimentWithPlates
    getOne: (experiment_id: string) =>
      `${API_BASE_ROUTE}/experiments/${experiment_id}`,
    // responds with APIExperimentWithPlates[]
    getProjectExperiments: (project_id: string) =>
      `${API_BASE_ROUTE}/projects/${project_id}/experiments`,
  },
  plates: {
    // accepts APIPlateCreateBody
    // responds with APIPlateWithAllChildren
    createExperimentPlate: (experiment_id: string) =>
      `${API_BASE_ROUTE}/experiments/${experiment_id}/plates`,

    // responds with APIProjectWithPlates
    getExperimentPlates: (experiment_id: string) =>
      `${API_BASE_ROUTE}/experiments/${experiment_id}/plates`,

    // responds with APIPlateWithAllChildren
    getPlate: (plate_id: string) => `${API_BASE_ROUTE}/plates/${plate_id}`,

    // accepts array of APIWellContentsCreateBody
    // responds with APIPlateWithAllChildren
    createWellContents: (plate_id: string) =>
      `${API_BASE_ROUTE}/plates/${plate_id}/contents`,

    // accepts APIConditionsCreateBody
    // responds with APICondition
    createWellConditions: (plate_id: string) =>
      `${API_BASE_ROUTE}/plates/${plate_id}/conditions`,
  },
  chemistrySampleSubmissions: {
    getAll: () => `${REACT_APP_API_HOST}/api/chemistry/sample/submissions`,
  },
  biobank: {
    getAll: (type: string) => {
      if (type === BiobankType.Genomes) {
        return `${GENOME_BASE_ROUTE}/genomes`;
      }

      if (type === BiobankType.Strains) {
        return `${DEREPLICATION_BASE_ROUTE}/wide_strains`;
      }

      return `${DEREPLICATION_BASE_ROUTE}/${type}`;
    },
    getTableColumns: (type: string) => {
      return `${API_ROUTES.biobank.getAll(type)}/table/columns`;
    },
    strains: {
      krona: (name: string) =>
        `${DEREPLICATION_BASE_ROUTE}/strains/${name}/krona`,
    },
    addItem: (type: string) => {
      return `${DEREPLICATION_BASE_ROUTE}/${type}`;
    },
    getStrainByName: (name: string) =>
      `${DEREPLICATION_BASE_ROUTE}/strains/name=${name}`,
    getItem: (type: string, id: string) => {
      const backendType =
        BACKEND_TYPE_BY_TYPE[type as keyof typeof BACKEND_TYPE_BY_TYPE];
      if (backendType === 'id') {
        return `${DEREPLICATION_BASE_ROUTE}/${type}/id=${id}`;
      }
      if (type === 'strains') {
        return `${DEREPLICATION_BASE_ROUTE}/strains/${id}/page`;
      }
      if (type === 'genomes') {
        return `${GENOME_BASE_ROUTE}/genomes/${id}/page`;
      }
      return `${DEREPLICATION_BASE_ROUTE}/${type}/${id}`;
    },
    deleteEntity: (type: BiobankType, entity: any) => {
      switch (type) {
        case BiobankType.Foods:
          return `${DEREPLICATION_BASE_ROUTE}/${type}/id=${entity.id}`;
        case BiobankType.FoodTubes:
          return `${DEREPLICATION_BASE_ROUTE}/${type}/${entity.barcode}`;
        default:
          return;
      }
    },
    // returns InputFields
    inputFields: (type: BiobankType) =>
      `${DEREPLICATION_BASE_ROUTE}/${type}/input_fields`,
    validateSingle: (type: BiobankType) =>
      `${DEREPLICATION_BASE_ROUTE}/${type}/validate_single`,
    card: (type: BiobankType, entityId?: string) => {
      switch (type) {
        case BiobankType.Foods:
        case BiobankType.FoodTubes:
        case BiobankType.PickPlates:
        case BiobankType.IsolationPlates:
        case BiobankType.Strains:
        case BiobankType.Lyo:
        case BiobankType.LyoTubes:
          return `${DEREPLICATION_BASE_ROUTE}/${type}/${entityId}/card`;
        case BiobankType.Genomes:
          return `${GENOME_BASE_ROUTE}/genomes/${entityId}/card`;
      }
    },
    validate: (type: BiobankType | string) => {
      return `${DEREPLICATION_BASE_ROUTE}/validate_${type}`;
    },
    carts: {
      createTypeCart: (type: BiobankType) =>
        `${
          type === BiobankType.Genomes
            ? GENOME_BASE_ROUTE
            : DEREPLICATION_BASE_ROUTE
        }/cart/${type}`,
      getTypeCart: (type: BiobankType, cartId: string | number) =>
        // supports pagination
        `${
          type === BiobankType.Genomes
            ? GENOME_BASE_ROUTE
            : DEREPLICATION_BASE_ROUTE
        }/cart/${type}?cart=${cartId}`,
      getTypeCartCombinations: (
        type: BiobankType,
        cartIdOne: string | number,
        cartIdTwo: string | number,
        combinationMode: string
      ) =>
        // supports pagination
        `${
          type === BiobankType.Genomes
            ? GENOME_BASE_ROUTE
            : DEREPLICATION_BASE_ROUTE
        }/cart/${type}/combinations?cart_one=${cartIdOne}&cart_two=${cartIdTwo}&mode=${combinationMode}`,
      getUserCarts: (
        userId: string | number,
        cartType?: BiobankType | string
      ) =>
        `${DEREPLICATION_BASE_ROUTE}/carts?user_id=${userId}${
          !!cartType ? `&cart_type=${cartType}` : ''
        }`,
      getSingleUserCart: (cartId: string | number) =>
        `${DEREPLICATION_BASE_ROUTE}/carts/${cartId}`,
    },
  },
  tasks: {
    startTask: (taskName: string) =>
      `${COMPUTATIONAL_BASE_ROUTE}/run/${taskName}`,
    getTaskStatus: (taskId: string) =>
      `${COMPUTATIONAL_BASE_ROUTE}/tasks/dispatch/${taskId}`,
    getResults: (seriesId: string, jobId: string, refineId: string) =>
      `${COMPUTATIONAL_BASE_ROUTE}/results/${seriesId}/${jobId}/${refineId}`,
    troubleshootJob: (seriesId: string, jobId: string, refineId: string) =>
      `${COMPUTATIONAL_BASE_ROUTE}/troubleshoot/${seriesId}/${jobId}/${refineId}`,
    getCart: (seriesId: string, jobId: string, refineId: string) =>
      `${COMPUTATIONAL_BASE_ROUTE}/results/${seriesId}/${jobId}/${refineId}/cart`,
    startRefine: (refineOperationName: string) =>
      `${COMPUTATIONAL_BASE_ROUTE}/refine/${refineOperationName}`,
    getJobs: ({ before, after }: { before?: string; after?: string }) => {
      const params = new URLSearchParams();
      if (before) {
        params.set('before', before);
      }
      if (after) {
        params.set('after', after);
      }
      return `${COMPUTATIONAL_BASE_ROUTE}/jobs?${params.toString()}`;
    },
    getJob: (jobId: string) => `${COMPUTATIONAL_BASE_ROUTE}/jobs/${jobId}`,
    getAvailableTasks: (type: BiobankType | string) =>
      `${COMPUTATIONAL_BASE_ROUTE}/tools/cart/${type}`,
    getAvailablePageTasks: (type: BiobankType | string) =>
      `${COMPUTATIONAL_BASE_ROUTE}/tools/page/${type}`,
    getAvailableRefinementTasks: (originalTask: string) =>
      `${COMPUTATIONAL_BASE_ROUTE}/tools/refine/${originalTask}`,
    getAvailableStandaloneTasks: () =>
      `${COMPUTATIONAL_BASE_ROUTE}/tools/standalone`,
    getTaskInputs: (taskName: string) =>
      `${COMPUTATIONAL_BASE_ROUTE}/tools/${taskName}/inputs`,
    getTaskTextView: (taskName: string) =>
      `${COMPUTATIONAL_BASE_ROUTE}/tools/${taskName}/text_view`,
    populateTaskTextView: (taskName: string) =>
      `${COMPUTATIONAL_BASE_ROUTE}/populate_text_view/${taskName}`,
  },
  dereplication: {
    getRuns: () => {
      return `${COMPUTATIONAL_BASE_ROUTE}/latest_dereplication_runs`;
    },
    getImages: (project_name: string, uuid: string) => {
      return `${COMPUTATIONAL_BASE_ROUTE}/strain_dereplication/${project_name}/${uuid}/overview`;
    },
    getQuality: (project_name: string, uuid: string) => {
      return `${COMPUTATIONAL_BASE_ROUTE}/strain_dereplication/${project_name}/${uuid}/quality`;
    },
    getRawReads: (project_name: string, uuid: string) => {
      return `${COMPUTATIONAL_BASE_ROUTE}/strain_dereplication/${project_name}/${uuid}/raw_reads`;
    },
    getFilteredReads: (project_name: string, uuid: string) => {
      return `${COMPUTATIONAL_BASE_ROUTE}/strain_dereplication/${project_name}/${uuid}/filtered_reads`;
    },
    getWellPurity: (project_name: string, uuid: string) => {
      return `${COMPUTATIONAL_BASE_ROUTE}/strain_dereplication/${project_name}/${uuid}/well_purity`;
    },
    getFoods: (project_name: string, uuid: string) => {
      return `${COMPUTATIONAL_BASE_ROUTE}/strain_dereplication/${project_name}/${uuid}/foods`;
    },
    getConditions: (project_name: string, uuid: string) => {
      return `${COMPUTATIONAL_BASE_ROUTE}/strain_dereplication/${project_name}/${uuid}/conditions`;
    },
    getSelections: (project_name: string, uuid: string) => {
      return `${COMPUTATIONAL_BASE_ROUTE}/strain_dereplication/${project_name}/${uuid}/selections`;
    },
    getFasta: (project_name: string, uuid: string) => {
      return `${COMPUTATIONAL_BASE_ROUTE}/strain_dereplication/${project_name}/${uuid}/fasta`;
    },
    getTaxonomy: (project_name: string, uuid: string) => {
      return `${COMPUTATIONAL_BASE_ROUTE}/strain_dereplication/${project_name}/${uuid}/taxonomy_rdp`;
    },
    getOverview: (project_name: string, uuid: string) => {
      return `${COMPUTATIONAL_BASE_ROUTE}/strain_dereplication/${project_name}/${uuid}/overview`;
    },
    getAllPredictedStrains: (project_name: string, uuid: string) => {
      return `${COMPUTATIONAL_BASE_ROUTE}/strain_dereplication/${project_name}/${uuid}/predicted_strains`;
    },
    getWells: (project_name: string, uuid: string) => {
      return `${COMPUTATIONAL_BASE_ROUTE}/strain_dereplication/${project_name}/${uuid}/wells`;
    },
    getLogs: (project_name: string, uuid: string) => {
      return `${COMPUTATIONAL_BASE_ROUTE}/strain_dereplication/${project_name}/${uuid}/output_logs`;
    },
    getCCIds: (project_name: string) => {
      return `${COMPUTATIONAL_BASE_ROUTE}/cc_ids_from_dereplication_project/${project_name}`;
    },
    getRunInDb: (project_name: string, uuid: string) => {
      return `${DEREPLICATION_BASE_ROUTE}/dereplication_log/check_run_in_db?project_id=${project_name}&run_uuid=${uuid}`;
    },
    getPredictedStrains: (project_name: string, uuid: string) => {
      return `${DEREPLICATION_BASE_ROUTE}/dereplication_log/predicted_strains?project_id=${project_name}&run_uuid=${uuid}`;
    },
    getAlreadyAdded: (project_name: string, uuid: string) => {
      return `${DEREPLICATION_BASE_ROUTE}/dereplication_log/check_strains_in_db?project_id=${project_name}&run_uuid=${uuid}`;
    },
    getPrevDerep: (project_name: string, uuid: string) => {
      return `${DEREPLICATION_BASE_ROUTE}/dereplication_log/check_if_project_dereplicated?project_id=${project_name}&run_uuid=${uuid}`;
    },
  },
};
