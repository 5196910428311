import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { RunTaskModalPostSubmission } from 'src/components/biobank/RunTaskModalPostSubmission';
import { Title } from 'src/components/typographyAnt';
import styled from 'styled-components';

import { useDocumentTitle } from '../hooks';

export const JobResultsScreen: FC = () => {
  const { seriesId, jobId, refineId } = useParams();
  useDocumentTitle(`Job ${jobId} Series ${seriesId}`);

  return (
    <div>
      <Header>
        <Title level={4}>
          Series {seriesId}
          <br />
          Job {jobId}
        </Title>
      </Header>
      <RunTaskModalPostSubmission
        jobsCreated={[
          { series_id: seriesId!, job_id: jobId!, refine_id: refineId! },
        ]}
        isFullPage
      />

      {/* <JobResultsModal
          isEmbed
          job={loadedJob}
          files={files}
          loading={isHydrationStarted && !isFinal}
          complete={isFinal}
        /> */}
    </div>
  );
};

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
