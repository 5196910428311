import 'react-data-grid/lib/styles.css';

import { Collapse, message, Spin } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import DataGrid, { SortColumn } from 'react-data-grid';
import { GlobalHotKeys } from 'react-hotkeys';
import { useSearchParams } from 'react-router-dom';
import { getTypeCart } from 'src/api/cart';
import styled from 'styled-components';

import { getBiobankColumns } from '../../api';
import { useFetch } from '../../hooks';
import { useDebounce } from '../../hooks/useDebounce';
import { usePaginatedFetch } from '../../hooks/usePaginatedFetch';
import { BiobankType } from '../../types';
import { isAtBottom, nullishPredicate } from '../../utils';
import { ID_FIELD_BY_TYPE } from '../biobank/constants';
import { BiobankHeader } from '../biobank/header/BiobankHeader';
import { RunTaskModal } from '../biobank/RunTaskModal';
import { TableColumn } from '../biobank/types';
import {
  doesTypeAllowSearching,
  generateRowsTSV,
  getDataSourceUrl,
  getGridColumnsFromTableColumns,
} from '../biobank/utils';
import { ViewCard } from '../biobank/view/ViewCard';

interface Props {
  type: BiobankType;
  id?: number;
  numActiveViews?: number;
  baseUrl?: string;
  isInModal?: boolean;
}

type Comparator = (a: any, b: any) => number;

//TODO: genericize this (only used for frontend sort)
const getComparator = (sortColumn: string): Comparator => {
  switch (sortColumn) {
    case 'culture_source':
      return (a, b) => {
        return (
          a[sortColumn]?.food?.descriptive_name.localeCompare(
            b[sortColumn]?.food?.descriptive_name
          ) || 0
        );
      };
    case 'high_level_substrate':
    case 'high_level_product':
    case 'substrate_descriptors':
    case 'product_descriptors':
    case 'defined_strains':
    case 'culture_conditions':
    case 'culture_source_tube':
      return (_a, _b) => {
        return 0;
      };
    case 'is_vegan':
    case 'is_alcoholic':
    case 'is_starter':
    case 'collection_date_is_guessed':
      return (a, b) => {
        return a[sortColumn] - b[sortColumn];
      };
    default:
      return (a, b) => {
        return `${a[sortColumn]}`.localeCompare(`${b[sortColumn]}`);
      };
  }
};

export const CartView: FC<Props> = ({
  type,
  id,
  baseUrl,
  numActiveViews = 1,
  isInModal = false,
}) => {
  const [queryParams, setQueryParams] = useSearchParams();
  const columnQueryParams = queryParams.get('columns')?.split(',') || [];
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isToolsVisible, setIsToolsVisible] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [searchField, setSearchField] = useState<string | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<ReadonlySet<number>>(
    () => new Set()
  );
  const [selectedColumns, setSelectedColumns] =
    useState<string[]>(columnQueryParams);
  const debouncedSearchValue = useDebounce(searchValue, 250);

  const allowSearching = doesTypeAllowSearching(type);

  useEffect(() => {
    setSearchValue(undefined);
    setSearchField(undefined);
    setSortColumns([]);
    setSelectedColumns([]);
    setSelectedRows(() => new Set());
  }, [type]);

  useEffect(() => {
    const columnParam = queryParams.get('columns');
    if (columnParam) {
      setSelectedColumns(columnParam.split(','));
    }
  }, [queryParams]);

  const { data: tableColumns } = useFetch<TableColumn[]>(
    getBiobankColumns(type).url
  );

  const dataSourceUrl = getDataSourceUrl({
    type,
    sortColumns,
    searchField,
    searchValue: debouncedSearchValue,
    baseUrl: baseUrl || getTypeCart(id!, type).url,
  });

  const {
    loading,
    error: _error,
    data,
    totalCount,
    loadMore,
    initialPageLoaded,
  } = usePaginatedFetch<any>(dataSourceUrl);

  const closeModal = () => setIsModalOpen(false);

  const handleScroll = async (event: React.UIEvent<HTMLDivElement>) => {
    if (loading || !isAtBottom(event)) {
      return;
    }

    loadMore();
  };

  const rawData = data || [];

  const filteredData = allowSearching
    ? rawData
    : rawData.filter((item: any) =>
        JSON.stringify(item)
          .toLowerCase()
          .includes(searchValue?.toLowerCase() || '')
      );

  const sortedRows = useMemo(() => {
    if (sortColumns.length === 0 || allowSearching) return filteredData;

    return [...filteredData].sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [filteredData, sortColumns, allowSearching]);

  const selectedTableColumns: TableColumn[] = selectedColumns
    .map((colName) => {
      return (tableColumns || []).find(
        ({ schema_name }) => schema_name === colName
      );
    })
    .filter(nullishPredicate);

  const getSelectedRows = () => {
    const rowData: Record<string, any>[] = [];
    // TODO: backend should tell us what the indexKey is
    const indexKey = ID_FIELD_BY_TYPE[type];
    for (const index of selectedRows) {
      rowData.push(sortedRows.find((row) => row[indexKey] === index));
    }
    return rowData;
  };

  useEffect(() => {
    if (!tableColumns) {
      return;
    }

    if (!columnQueryParams.length) {
      setSelectedColumns(
        tableColumns
          .filter(({ default_display }) => !!default_display)
          .map(({ schema_name }) => schema_name)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableColumns]);

  const resetSelectedColumns = () => {
    setQueryParams({});
    setSelectedColumns(
      (tableColumns || [])
        .filter(({ default_display }) => !!default_display)
        .map(({ schema_name }) => schema_name)
    );
  };

  const doCopy = () => {
    if (!selectedRows.size) {
      return;
    }

    const copyText = generateRowsTSV(getSelectedRows(), selectedTableColumns);
    navigator.clipboard.writeText(copyText);
    message.success(`Copied ${selectedRows.size} rows`);
  };

  const maxWidth = (() => {
    if (!numActiveViews) {
      return undefined;
    } else if (numActiveViews === 1) {
      return 'calc(100vw - 36px)';
    } else if (numActiveViews === 2) {
      return 'calc(50vw - 26px)';
    }
  })();

  return (
    <GlobalHotKeys keyMap={{ COPY: 'command+c' }} handlers={{ COPY: doCopy }}>
      <OuterBodyWrapper
        style={{
          width: maxWidth,
        }}
        data-name="outerbodywrapper"
      >
        <BiobankHeader
          type={type}
          selectedRowsSize={selectedRows.size}
          sortedRowsLength={sortedRows.length}
          doCopy={doCopy}
          tableColumns={tableColumns}
          selectedColumns={selectedColumns}
          resetSelectedColumns={resetSelectedColumns}
          searchField={searchField}
          setSearchField={setSearchField}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setQueryParams={setQueryParams}
          dataCount={data?.length}
          totalCount={totalCount}
          selectedTableColumns={selectedTableColumns}
          getSelectedRows={getSelectedRows}
          dataSourceUrl={dataSourceUrl}
          isMultiCartView={numActiveViews > 1}
          cartId={id}
        />
        <InnerBodyWrapper
          data-name="innerbodywrapper"
          isToolsVisible={isToolsVisible}
        >
          <Spin spinning={loading}>
            {initialPageLoaded ? (
              <DataGrid
                rows={sortedRows}
                columns={getGridColumnsFromTableColumns(selectedTableColumns)}
                defaultColumnOptions={{
                  sortable: true,
                  resizable: true,
                }}
                className="rdg-light"
                onCellDoubleClick={({ row }, event) => {
                  event.preventGridDefault();
                  event.preventDefault();
                  setActiveItem(row);
                  setIsModalOpen(true);
                  window.getSelection()?.empty();
                }}
                sortColumns={sortColumns}
                onSortColumnsChange={setSortColumns}
                style={{
                  height: isInModal ? '75vh' : '85vh',
                }}
                onScroll={handleScroll}
                selectedRows={selectedRows}
                onSelectedRowsChange={setSelectedRows}
                rowKeyGetter={(row) => row[ID_FIELD_BY_TYPE[type]]}
                onCellClick={(args, event) => {
                  event.preventGridDefault();
                }}
                onCellKeyDown={(args, event) => {
                  event.preventGridDefault();
                  if (event.key === 'c' && event.metaKey) {
                    doCopy();
                  }
                }}
              />
            ) : null}
          </Spin>
        </InnerBodyWrapper>
        {isInModal ? null : (
          <Collapse
            items={[
              {
                key: 'tools',
                label: 'Tools',
                children: <RunTaskModal cartId={id} cartType={type} />,
              },
            ]}
            onChange={(openItems) => {
              if (openItems.length) {
                setIsToolsVisible(true);
              } else {
                setIsToolsVisible(false);
              }
            }}
          />
        )}
        {isModalOpen ? (
          <ViewCard
            item={activeItem}
            itemType={type}
            onClose={closeModal}
            refreshData={() => null}
          />
        ) : null}
      </OuterBodyWrapper>
    </GlobalHotKeys>
  );
};

const InnerBodyWrapper = styled.div<{ isToolsVisible?: boolean }>`
  height: 100%;
  position: relative;
  max-height: calc(
    ${({ isToolsVisible }) =>
      isToolsVisible ? '50vh - 200px' : '85vh - 100px'}
  );

  & > div {
    max-height: inherit;
    & > div {
      max-height: inherit;
      & > div {
        max-height: inherit;
      }
    }
  }
  margin-bottom: 4px;
`;

const OuterBodyWrapper = styled.div`
  height: 100%;
  position: relative;
  max-height: 85vh;
`;
