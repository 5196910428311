import { Input, List } from 'antd';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { SubTitle } from 'src/components/typography';
import { Title } from 'src/components/typographyAnt';
import styled from 'styled-components';

import { API_ROUTES } from '../constants';
import { useDocumentTitle, useFetch } from '../hooks';

type DereplicationRun = {
  name: string;
  uuid: string;
  pipeline_version: string;
  date: string;
};

const getDateFromDerepName = (name: string) => {
  const isoDate = name.slice(0, 8);
  const year = parseInt(isoDate.slice(0, 4));
  const month = parseInt(isoDate.slice(4, 6));
  const day = parseInt(isoDate.slice(6, 8));

  return new Date(year, month - 1, day);
};

const groupAndSortRuns = (
  runs: DereplicationRun[] = [],
  searchValue: string
) => {
  if (searchValue.length) {
  }

  const groupedRuns = (
    searchValue.length
      ? runs.filter((run) => new RegExp(searchValue, 'ig').test(run.name))
      : runs
  ).reduce((acc, run) => {
    const { name } = run;
    acc[name] = [...(acc[name] || []), run];
    return acc;
  }, {} as Record<string, any>);

  const sortedRuns: any[] = [];

  const sortedRunKeys = Object.keys(groupedRuns).sort((a, b) => {
    const aDate = getDateFromDerepName(a);
    const bDate = getDateFromDerepName(b);
    return aDate.valueOf() - bDate.valueOf();
  });

  for (const name of sortedRunKeys) {
    sortedRuns.push(groupedRuns[name]);
  }

  return sortedRuns;
};

export const Dereplication: FC = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const { data: derepRuns, loading } = useFetch<DereplicationRun[]>(
    API_ROUTES.dereplication.getRuns()
  );

  const sortedRuns = groupAndSortRuns(derepRuns, searchValue);

  useDocumentTitle(`Dereplication Runs`);

  return (
    <div>
      <Header>
        <Title level={4}>Dereplication Runs</Title>
        <div style={{ maxWidth: '300px' }}>
          <Input.Search
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            allowClear
            placeholder="Project name"
          />
        </div>
      </Header>
      <Wrapper>
        <List
          itemLayout="vertical"
          size="small"
          dataSource={sortedRuns}
          loading={loading}
          bordered={false}
          renderItem={(item) => {
            const { name } = item[0];
            return (
              <List.Item style={{ borderBottom: 'none' }}>
                <StyledSubtitle>Project {name}</StyledSubtitle>
                <List
                  size="small"
                  itemLayout="horizontal"
                  dataSource={item}
                  renderItem={(item: DereplicationRun) => {
                    const { uuid, date } = item;

                    const hasDate = !!date.length;

                    return (
                      <List.Item
                        actions={[
                          <Link to={`/dereplication/${name}/${uuid}`}>
                            view run
                          </Link>,
                        ]}
                      >
                        <StyledLink to={`/dereplication/${name}/${uuid}`}>
                          {uuid}
                          <br />
                          <small>
                            {hasDate
                              ? `(${new Date(
                                  date
                                ).toLocaleTimeString()} ${new Date(
                                  date
                                ).toDateString()})`
                              : ''}
                          </small>
                        </StyledLink>
                      </List.Item>
                    );
                  }}
                />
              </List.Item>
            );
          }}
        />
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledLink = styled(Link)`
  color: black;
`;

const StyledSubtitle = styled(SubTitle)`
  border-bottom: 1px solid black;
  margin-bottom: auto;
`;
