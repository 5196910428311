import { Button, Input, message } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { addItemsToCart } from 'src/api/cart';
import { useUser } from 'src/hooks';
import { BiobankType } from 'src/types';
import { authFetch } from 'src/utils';
import styled from 'styled-components';

import { Modal } from '../Modal';
import { FieldTitle } from '../typography';
import { CART_ID_FIELD_BY_TYPE } from './constants';

type Props =
  | {
      type: BiobankType;
      open: boolean;
      getSelectedRows: () => Record<string, any>[];
      idOnlyMode?: false | undefined;
      onCancel: () => void;
    }
  | {
      type: BiobankType;
      open: boolean;
      getSelectedRows: () => string[];
      idOnlyMode: true;
      onCancel: () => void;
    };

export const AddToCartModal: FC<Props> = ({
  getSelectedRows,
  open,
  onCancel,
  idOnlyMode,
  type,
}) => {
  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { isValid },
    setValue,
  } = useForm();

  const [itemKeys, setItemKeys] = useState<string[]>([]);

  const currentUser = useUser();

  useEffect(() => {
    const rows = getSelectedRows();
    const keys = idOnlyMode
      ? rows
      : rows.map((item) => item[CART_ID_FIELD_BY_TYPE[type]]);
    setItemKeys(keys);
    setValue('list_of_ids', keys);
    setValue('user_email', currentUser?.email);
  }, [open, currentUser?.email]);

  const onClose = () => {
    reset();
    onCancel();
  };

  const onSubmit = async (data: any) => {
    try {
      const cartResult = await authFetch(addItemsToCart(type, data));

      message.success('Created new cart');

      if (cartResult?.id) {
        window.open(`/carts/${cartResult.id}`);
      }
      onClose();
    } catch (e) {
      message.error(<span style={{ whiteSpace: 'pre-wrap' }}>{`${e}`}</span>);
    }
  };

  return (
    <Modal open={open} onCancel={onClose} header="Add Selections to New Cart">
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="hidden"
          {...register('list_of_ids', { value: itemKeys })}
        />
        <Wrapper>
          <Group>
            <FieldTitle style={{ display: 'flex', alignItems: 'center' }}>
              Cart name
            </FieldTitle>
            <InputWrapper>
              <Controller
                control={control}
                name={'cart_name'}
                render={({ field }) => (
                  <Input {...field} placeholder="Cart name" />
                )}
                rules={{ required: true }}
              />
            </InputWrapper>
            <FieldTitle style={{ display: 'flex', alignItems: 'center' }}>
              Extra notes
            </FieldTitle>
            <InputWrapper>
              <Controller
                control={control}
                name={'notes'}
                render={({ field }) => (
                  <Input {...field} placeholder="Extra notes" />
                )}
                rules={{ required: true }}
              />
            </InputWrapper>
          </Group>
          <div style={{ textAlign: 'right' }}>
            <Button type="primary" disabled={!isValid} htmlType="submit">
              Submit
            </Button>
          </div>
        </Wrapper>
      </form>
    </Modal>
  );
};

const InputWrapper = styled.div`
  > * {
    width: 100%;
  }
  > input[type='checkbox'] {
    width: auto;
  }

  &&&& .ant-select-selection-placeholder,
  &&&& input::placeholder {
    font-style: italic !important;
    color: rgba(1, 1, 1, 0.4);
  }
`;

const Group = styled.div`
  padding: 16px 0px;
  display: grid;
  grid-template-columns: fit-content(200px) auto;
  row-gap: 16px;
  column-gap: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
