import { useEffect } from 'react';

import { useLogin } from '../hooks';

// https://developers.google.com/identity/gsi/web/reference/js-reference

export const Login = () => {
  const loginUrl = `${process.env.REACT_APP_API_HOST}/api/authentication/login`;
  const { handleGoogle, loading, error } = useLogin(loginUrl);

  useEffect(() => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogle,
      });

      window.google.accounts.id.renderButton(
        document.getElementById('loginDiv'),
        {
          theme: 'filled_black',
          text: 'signin_with',
          shape: 'pill',
        }
      );
    }
  }, [handleGoogle]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {loading ? <div>Loading....</div> : <div id="loginDiv"></div>}
    </div>
  );
};
