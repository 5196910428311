import { CopyOutlined } from '@ant-design/icons';
import { Button, message, Tooltip } from 'antd';
import { CSSProperties, FC } from 'react';
import styled from 'styled-components';

interface Props {
  content?: string;
  style?: CSSProperties;
  customButton?: {
    icon: any;
    onClick: () => void;
    title: string;
  };
}

export const LogView: FC<Props> = ({ content, style, customButton }) => {
  if (!content) {
    return null;
  }

  const doCopy = () => {
    navigator.clipboard.writeText(content);
    message.success(`Copied`);
  };

  const button = customButton ? (
    <Button
      size="small"
      type="default"
      ghost
      style={{
        display: 'flex',
        position: 'sticky',
        top: '0px',
        left: 'calc(100% - 24px)',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={customButton.onClick}
      icon={customButton.icon}
    ></Button>
  ) : (
    <Button
      size="small"
      type="default"
      ghost
      style={{
        display: 'flex',
        position: 'sticky',
        top: '0px',
        left: 'calc(100% - 24px)',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={doCopy}
      icon={<CopyOutlined />}
    ></Button>
  );

  return (
    <Wrapper style={style}>
      <Tooltip title={customButton ? customButton.title : 'Copy'}>
        {button}
      </Tooltip>
      {content}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  font-family: Monaco, ui-monospace, monospace;
  color: white;
  white-space: pre;
  overflow: scroll;
  background: rgb(26, 28, 36);
  padding: 12px;
`;
