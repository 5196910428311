import { FC } from 'react';

import { Well } from './types';
import { rowLetterToNumber } from './utils';

interface Props {
  selectedWells: Well[];
}

export const SelectedWells: FC<Props> = ({ selectedWells }) => {
  console.table(selectedWells);
  const tableData = selectedWells
    .sort((a, b) => {
      if (!a.row || !b.row || !a.column || !b.column) {
        return 0;
      }
      const rowSort = rowLetterToNumber(a.row) - rowLetterToNumber(b.row);
      if (rowSort === 0) {
        const colSort = Number(a.column) - Number(b.column);
        return colSort;
      }
      return rowSort;
    })
    .flatMap(
      ({ row, column, strains, barcodes: _barcodes, species: _species }) =>
        (strains || []).map((strain) => ({
          strain,
          row,
          column,
        }))
    );
  console.table(tableData);
  return <div />;
};
