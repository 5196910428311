import { Select, Spin, Typography } from 'antd';
import { forwardRef, useEffect, useState } from 'react';
import { useDebounce } from 'src/hooks/useDebounce';

import { getBiobankList } from '../../../api';
import { usePaginatedFetch } from '../../../hooks';

type TypeaheadItem = {
  short_name?: string;
  name?: string;
  description?: string;
  condition_code?: string;
  barcode?: string;
};

interface Props {
  itemType: string;
  onChange?: (val: any) => void;
  size?: any;
  placeholder?: string;
}

const NAME_FIELD: Record<string, keyof TypeaheadItem> = {
  food_tubes: 'barcode',
  culture_sources: 'name',
};

const LABEL_FIELD: Record<string, keyof TypeaheadItem> = {
  food_tubes: 'description',
  culture_sources: 'description',
};

export const ForeignSelect = forwardRef<any, Props>(
  ({ itemType, onChange, size, placeholder, ..._additionalProps }, ref) => {
    const [options, setOptions] = useState<any[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const debouncedSearchValue = useDebounce(searchValue, 500);

    const dataSourceUrl = debouncedSearchValue
      ? `${
          getBiobankList(itemType).url
        }?q=${debouncedSearchValue}&q_col=description`
      : getBiobankList(itemType).url;

    const { data: items = [], loading } = usePaginatedFetch<TypeaheadItem>(
      dataSourceUrl,
      { disable: !debouncedSearchValue }
    );

    useEffect(() => {
      setOptions(
        items.map((item) => {
          const itemName = item[NAME_FIELD[itemType]];
          const itemDescription = item[LABEL_FIELD[itemType]];
          return {
            value: `${itemName}`,
            label: `${itemName} - ${itemDescription}`,
          };
        })
      );
    }, [items.length, itemType]);

    return (
      <Select
        // {...additionalProps}
        size={size}
        ref={ref}
        placeholder={loading ? 'Loading...' : placeholder}
        options={options}
        filterOption={false}
        // labelInValue
        notFoundContent={getNotFoundContent(loading, debouncedSearchValue)}
        value={searchValue || undefined}
        showSearch
        showArrow={false}
        onSearch={(newValue) => setSearchValue(newValue)}
        onChange={(newValue) => {
          setSearchValue(newValue);
          onChange?.(newValue);
        }}
      />
    );
  }
);

const getNotFoundContent = (loading: boolean, searchValue?: string) => {
  if (loading) {
    return <Spin />;
  }

  if (!!searchValue) {
    return (
      <span style={{ padding: '4px' }}>
        <Typography.Text type="secondary">No results found</Typography.Text>
      </span>
    );
  }

  return null;
};

ForeignSelect.displayName = 'ForeignSelect';
