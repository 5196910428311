export enum HTTPMethod {
  POST = 'POST',
  GET = 'GET',
  DELETE = 'DELETE',
  PUT = 'PUT',
}

export type FetchOptions = {
  url: string;
  options?: {
    method?: HTTPMethod;
    data?: any;
    contentType?: string;
    omitContentType?: boolean;
    signal?: AbortSignal;
  };
};
