import { Select as AntSelect } from 'antd';
import { forwardRef } from 'react';
import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const FormRow = styled.div`
  display: flex;
  gap: 8px;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

export const Input = styled.input`
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  &:invalid {
    border: 1px solid red;
  }
`;

export const Textarea = styled.textarea`
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
`;

export const RawCheckbox = styled.input``;
export const Checkbox = forwardRef<any, any>(({ children, ...props }, ref) => (
  <RawCheckbox type="checkbox" {...props} ref={ref}>
    {children}
  </RawCheckbox>
));

export const FormError = styled.div`
  color: red;
`;

export const Select = styled.select``;

export const PlainButton = styled.button`
  cursor: pointer;
  background: white;
  border: 1px solid black;
  border-radius: 4px;
  padding: 4px 4px;
`;

export const PlainLabelButton = styled.label`
  cursor: pointer;
  background: white;
  border: 1px solid black;
  border-radius: 4px;
  padding: 4px 4px;
  font-size: 0.8rem;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const StyledSelect = styled(AntSelect)`
  &&&&&.ant-select-focused .ant-select-selector {
    color: unset !important;
  }
`;
