import { API_ROUTES } from '../constants';
import {
  APIConditionsCreateBody,
  APIPlateCreateBody,
  APIWellContentsCreateBody,
  HTTPMethod,
} from '../types';

// responds with APIPlateWithAllChildren
export const createExperimentPlate = (
  experimentId: string,
  newPlate: APIPlateCreateBody
) => ({
  url: API_ROUTES.plates.createExperimentPlate(experimentId),
  options: {
    method: HTTPMethod.POST,
    data: newPlate,
  },
});

// responds with APIProjectWithPlates
export const getExperimentPlates = (experimentId: string) => ({
  url: API_ROUTES.plates.getExperimentPlates(experimentId),
});

// responds with APIPlateWithAllChildren
export const getPlate = (plateId: string) => ({
  url: API_ROUTES.plates.getPlate(plateId),
});

// responds with APIPlateWithAllChildren
export const createWellContents = (
  plateId: string,
  contents: APIWellContentsCreateBody[]
) => ({
  url: API_ROUTES.plates.createWellContents(plateId),
  options: {
    method: HTTPMethod.POST,
    data: contents,
  },
});

// responds with APICondition
export const createWellConditions = (
  plateId: string,
  conditions: APIConditionsCreateBody
) => ({
  url: API_ROUTES.plates.createWellConditions(plateId),
  options: {
    method: HTTPMethod.POST,
    data: conditions,
  },
});
