import { Button, Spin } from 'antd';
import { FC } from 'react';
import { useHydratedJobResults } from 'src/hooks/useHydratedJobResults';

export const JobResultsButton: FC<{
  series_id: string;
  job_id: string;
  refine_id: string;
  task_name: string;
}> = ({ series_id, job_id, refine_id }) => {
  const { isHydrating, isLoadingResults, isFinal } = useHydratedJobResults({
    series_id,
    job_id,
    refine_id,
  });

  const buttonText = (() => {
    if (isLoadingResults || isHydrating) {
      return 'Loading...';
    }

    if (isFinal) {
      return 'View Results';
    }

    if (!isFinal) {
      return 'Processing';
    }
  })();

  return (
    <Spin spinning={isHydrating || isLoadingResults || !isFinal}>
      <div style={{ display: 'flex', gap: '8px' }}>
        <Button href={`/jobs/${series_id}/${job_id}/${refine_id}`}>
          {buttonText}
        </Button>
      </div>
    </Spin>
  );
};
