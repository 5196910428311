import {
  ID_FIELD_BY_TYPE,
  ITEM_CARD_ID_FIELD_BY_TYPE,
} from '../components/biobank/constants';
import { API_ROUTES } from '../constants';
import { BiobankType } from '../types';
import { useFetch } from './useFetch';

export const useItemCardData = (
  item: Record<string, any>,
  itemType: BiobankType
) => {
  const cardUrl = API_ROUTES.biobank.card(
    itemType,
    item[ITEM_CARD_ID_FIELD_BY_TYPE[itemType]]
  );

  return {
    ...useFetch<typeof item>(cardUrl),
    deepLinkId: item[ID_FIELD_BY_TYPE[itemType]],
  };
};
