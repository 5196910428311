import { API_ROUTES as APIRoutes } from './api';

export const COLORS = {
  brand: {
    orange: '#DA6335',
    orangeAlpha: (alpha: number) => `rgba(218, 99, 53, ${alpha})`,
    blue: '#86B7F0',
    yellow: '#F5C351',
    green: '#69B54B',
  },
};

export const IS_DEV = (process.env.REACT_APP_API_HOST || '').includes(
  'localhost'
);

export const API_ROUTES = APIRoutes;

export const TAG_COLORS = [
  'magenta',
  'red',
  'orange',
  'green',
  'cyan',
  'blue',
  'purple',
];
