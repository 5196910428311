import { API_ROUTES } from '../constants';
import { BiobankType, HTTPMethod } from '../types';

export const addItemsToCart = (type: BiobankType, data: any) => ({
  url: API_ROUTES.biobank.carts.createTypeCart(type),
  options: {
    method: HTTPMethod.POST,
    data,
  },
});

export const getUserCarts = (userId: number) => ({
  url: API_ROUTES.biobank.carts.getUserCarts(userId),
});

export const getUserTypeCarts = (userId: number, type?: BiobankType) => ({
  url: API_ROUTES.biobank.carts.getUserCarts(userId, type),
});

export const getTypeCart = (cartId: string | number, type: BiobankType) => ({
  url: API_ROUTES.biobank.carts.getTypeCart(type, cartId),
});

export const combineCarts = (
  type: BiobankType,
  cartIdOne: string | number,
  cartIdTwo: string | number,
  combinationMode: string
) => ({
  url: API_ROUTES.biobank.carts.getTypeCartCombinations(
    type,
    cartIdOne,
    cartIdTwo,
    combinationMode
  ),
});
