import { TAG_COLORS } from '../constants';
import { FetchOptions, HTTPMethod } from '../types';

export const logout = () => {
  localStorage.removeItem('user');
  window.location.reload();
};

export const getUserToken = () => {
  const theUser = localStorage.getItem('user');

  if (theUser && !theUser.includes('undefined')) {
    return JSON.parse(theUser).access_token;
  }
};

export const authFetch = async <T = any>({
  url,
  options,
}: FetchOptions): Promise<T | false> => {
  const token = getUserToken();

  if (!token) {
    throw new Error('User not authenticated');
  }

  const body = options?.data
    ? !!options?.contentType || !!options?.omitContentType
      ? options.data
      : JSON.stringify(options.data)
    : undefined;

  const res = await fetch(url, {
    method: options?.method || HTTPMethod.GET,
    headers: {
      ...(options?.omitContentType
        ? {}
        : { 'Content-Type': options?.contentType || 'application/json' }),
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'X-Requested-With',
      Authorization: `Bearer ${token}`,
    },
    body,
    signal: options?.signal,
  });

  if (!res.ok) {
    if (res.status === 401) {
      logout();

      return false;
    } else {
      if (res.headers && res.headers.get('Content-Type')?.includes('json')) {
        const errorJson = await res.json();

        if (errorJson.detail) {
          // eslint-disable-next-line no-throw-literal
          throw { message: 'Error', detail: errorJson.detail };
        }
        throw new Error(res.statusText);
      }

      throw new Error(res.statusText);
    }
  }

  const data = await res.json();

  return data;
};

export const textFetch = async (url: string) => {
  const res = await fetch(url);

  if (res.ok) {
    const body = await res.text();

    return body;
  }
};

export const getDataUri = (text: string, contentType = 'text/plain') => {
  return `data:${contentType};charset=UTF-8,${encodeURIComponent(text)}`;
};

export const fetchAllPages = async (baseUrl: string, pageSize = 100) => {
  let currentUrl = `${baseUrl}`;
  let offset = 0;
  let totalCount = 100;
  let data: any[] = [];

  while (data.length < totalCount) {
    const res = await authFetch({ url: currentUrl });
    const { items, count } = res;
    data = [...data, ...items];
    totalCount = count;
    offset += pageSize;
    const hasQ = /\?/.test(baseUrl);
    currentUrl = `${baseUrl}${
      hasQ ? '&' : '?'
    }limit=${pageSize}&offset=${offset}`;
  }

  return data;
};

export const downloadFromString = (file: string, filename: string) => {
  const link = document.createElement('a');
  link.setAttribute('style', 'display: none;');
  link.href = getDataUri(file);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
};

export const properCase = (text: string) => {
  return `${text.charAt(0).toLocaleUpperCase()}${text.slice(1)}`;
};

export const humanize = (text?: string) => {
  if (!text) {
    return '';
  }

  const cleanText = text.replace(/[_]/gi, ' ');
  const words = cleanText.split(' ').map(properCase);

  return words.join(' ');
};

export const nullishPredicate = <T>(
  item: T | void | undefined | null
): item is T => {
  if (typeof item === 'number') {
    return true;
  }

  return !!item;
};

export const isAtBottom = ({
  currentTarget,
}: React.UIEvent<HTMLDivElement>): boolean => {
  return (
    currentTarget.scrollTop + 10 >=
    currentTarget.scrollHeight - currentTarget.clientHeight
  );
};

export const getRandomTagColor = () => {
  return TAG_COLORS[Math.floor(Math.random() * TAG_COLORS.length)];
};

export const compute = <T>(fn: () => T) => {
  return fn();
};

export const getJSONtoJSON = (input: string) => {
  try {
    return JSON.stringify(JSON.parse(input), null, 2);
  } catch {
    return 'Invalid JSON';
  }
};

export const scrollToWindowBottom = () => {
  document.body.scrollTo(0, document.body.scrollHeight);

  const appWrapper = document.querySelector('div[data-name=app-wrapper]');
  if (appWrapper) {
    appWrapper.scrollTo(0, appWrapper.scrollHeight);
  }
};
