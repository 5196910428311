import { List, Spin } from 'antd';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { JobResultsButton } from 'src/components/jobs/JobResultsButton';
import { JobStatusIcon } from 'src/components/jobs/JobStatusIcon';
import { TroubleshootButton } from 'src/components/jobs/TroubleshootButton';
import { Title } from 'src/components/typographyAnt';
import { useJobSeries } from 'src/hooks/useJobSeries';
import styled from 'styled-components';

import { useDocumentTitle } from '../hooks';

export const SeriesSingleScreen: FC = () => {
  const { seriesId } = useParams();
  useDocumentTitle(`Job Series ${seriesId}`);

  const { loading, data } = useJobSeries(seriesId);

  return (
    <div>
      <Header>
        <Title level={4}>Job Series {seriesId}</Title>
      </Header>
      <Spin spinning={loading}>
        <List
          bordered={false}
          dataSource={data?.items}
          renderItem={(item) => {
            if (!item) {
              return;
            }

            const { job_id, refine_id, job_status, task_name } = item;

            const hasTaskName = !!task_name;

            return (
              <List.Item
                actions={[
                  <JobStatusIcon jobs={[{ job_status }]} isSingle />,
                  <JobResultsButton {...item} />,
                  <TroubleshootButton {...item} />,
                ]}
                style={{ borderBottom: 'none' }}
              >
                Job {job_id} ({refine_id})
                {hasTaskName ? (
                  <>
                    &nbsp;(<code>{task_name}</code>)
                  </>
                ) : null}
              </List.Item>
            );
          }}
        />
      </Spin>
    </div>
  );
};

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
