import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from 'react-router-dom';

import { CartsTable } from './components/cart/CartsTable';
import { Layout } from './Layout';
import {
  ChemistrySampleSubmissions,
  Home,
  Login,
  PlateViewer,
  ProjectsView,
} from './screens';
import { FourOhFour } from './screens/404';
import { BiobankScreen } from './screens/Biobank';
import { BiobankAddItem } from './screens/BiobankAddItem';
import { BiobankBulkUpload } from './screens/BiobankBulkUpload';
import { BiobankSingleItem } from './screens/BiobankSingleItem';
import { CartsScreen } from './screens/Carts';
import { Dereplication } from './screens/Dereplication';
import { DereplicationRun } from './screens/DereplicationRun';
import { JobResultsScreen } from './screens/JobResults';
import { SeriesListTable } from './screens/SeriesListTable';
import { SeriesSingleScreen } from './screens/SeriesSingle';
import { ToolsScreen } from './screens/Tools';
import { TreeView } from './screens/TreeView';

export const browserRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />}>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/home" element={<Home />} />
      <Route path="/projects" element={<ProjectsView />} />
      <Route
        path="/projects/:projectId/chemistry/submissions"
        element={<ChemistrySampleSubmissions />}
      />
      <Route path="/plates/:plateId" element={<PlateViewer />} />
      <Route path="/plates/:plateId/edit" element={<PlateViewer editMode />} />

      {/* Carts */}
      <Route path="/carts" element={<CartsTable />} />
      <Route path="/carts/:cartOneId" element={<CartsScreen />}>
        <Route path=":cartTwoId" element={<CartsScreen />} />
      </Route>

      {/* Cart Tasks */}
      <Route path="/jobs" element={<SeriesListTable />} />

      <Route path="/jobs/:seriesId" element={<SeriesSingleScreen />} />

      <Route
        path="/jobs/:seriesId/:jobId/:refineId"
        element={<JobResultsScreen />}
      />

      {/* Standalone Tools */}
      <Route path="/tools" element={<ToolsScreen />} />

      {/* Biobank */}
      <Route path="/biobank" element={<Navigate to="/biobank/foods" />} />
      <Route path="/biobank/:type" element={<BiobankScreen />} />
      <Route path="/biobank/:type/new" element={<BiobankAddItem />} />
      <Route path="/biobank/:type/upload" element={<BiobankBulkUpload />} />
      <Route path="/biobank/:type/:itemId" element={<BiobankSingleItem />} />

      {/* Tree view */}
      <Route path="/tree_view" element={<TreeView />} />

      {/* Dereplication */}
      <Route path="/dereplication" element={<Dereplication />} />
      <Route
        path="/dereplication/:project/:uuid"
        element={<DereplicationRun />}
      />

      {/* 404 */}
      <Route
        path="*"
        element={<FourOhFour />}
        loader={async () => ({ is404: true })}
      />
    </Route>
  )
);
