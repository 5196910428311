import { API_ROUTES } from '../constants';
import { BiobankType, HTTPMethod } from '../types';

export const startCartTask = ({
  task_name,
  cartId,
  strainId,
  uuid,
  // userId,
  data,
}: {
  task_name: string;
  cartId?: number;
  strainId?: number;
  uuid?: string;
  userId: string | number;
  data?: Record<string, any>;
}) => ({
  url: API_ROUTES.tasks.startTask(task_name),
  options: {
    method: HTTPMethod.POST,
    data: {
      // user_id: userId,
      cart_id: cartId,
      strain_id: strainId,
      uuid,
      ...data,
    },
  },
});

export const getTaskStatus = (taskId: string) => ({
  url: API_ROUTES.tasks.getTaskStatus(taskId),
});

export const getJob = (jobId: string) => ({
  url: API_ROUTES.tasks.getJob(jobId),
});

export const getJobs = ({
  before,
  after,
}: {
  before?: string;
  after?: string;
}) => ({
  url: API_ROUTES.tasks.getJobs({
    before,
    after,
  }),
});

export const getStrainByName = (name: string) => ({
  url: API_ROUTES.biobank.getStrainByName(name),
});

export const getResults = ({
  seriesId,
  jobId,
  refineId,
}: {
  seriesId: string;
  jobId: string;
  refineId: string;
}) => ({
  url: API_ROUTES.tasks.getResults(seriesId, jobId, refineId),
});

export const getCart = ({
  seriesId,
  jobId,
  refineId,
}: {
  seriesId: string;
  jobId: string;
  refineId: string;
}) => ({
  url: API_ROUTES.tasks.getCart(seriesId, jobId, refineId),
});

export const getAvailableTasks = (type: BiobankType | string) => ({
  url: API_ROUTES.tasks.getAvailableTasks(type),
});

export const getAvailablePageTasks = (type: BiobankType | string) => ({
  url: API_ROUTES.tasks.getAvailablePageTasks(type),
});

export const getAvailableRefinementTasks = (type: string) => ({
  url: API_ROUTES.tasks.getAvailableRefinementTasks(type),
});

export const getAvailableStandaloneTasks = () => ({
  url: API_ROUTES.tasks.getAvailableStandaloneTasks(),
});

export const getTaskInputs = (taskName: string) => ({
  url: API_ROUTES.tasks.getTaskInputs(taskName),
});

export const getTaskTextView = (taskName: string) => ({
  url: API_ROUTES.tasks.getTaskTextView(taskName),
});

export const populateTaskTextView = (
  taskName: string,
  data: Record<string, any>
) => ({
  url: API_ROUTES.tasks.populateTaskTextView(taskName),
  options: {
    method: HTTPMethod.POST,
    data,
  },
});
