import { JobResultFile, JobResultFileType } from 'src/types/api/tasks';
import { getDataUri, getJSONtoJSON, nullishPredicate } from 'src/utils';

import { LogView } from '../LogView';

export const getResultsFilesToComponentsList = (
  files: (JobResultFile & { fileBody?: string })[]
) => {
  return files
    .map((file) => {
      const isVisible = !!file.visible_to_users;

      if (!isVisible) {
        return undefined;
      }

      const isLogView = [
        JobResultFileType.Text,
        JobResultFileType.JSON,
      ].includes(file.file_type);
      const isEmbed = !isLogView;
      const isJSON = JobResultFileType.JSON === file.file_type;

      const contents = (() => {
        const { fileBody } = file;

        if (!fileBody) {
          return getDataUri('<i>No file</i>', 'text/html');
        }

        if (isJSON && fileBody) {
          return getJSONtoJSON(fileBody);
        }
        if (isEmbed && fileBody) {
          return getDataUri(fileBody, 'text/html');
        }
        if (isLogView) {
          return fileBody;
        }
      })();

      const tabName = file.tab_name;

      return {
        key: tabName,
        label: tabName,
        children: isLogView ? (
          <LogView content={contents} />
        ) : (
          <iframe
            style={{
              aspectRatio: 1,
              maxWidth: '50vw',
              width: '100%',
              alignSelf: 'center',
            }}
            src={contents}
            title={tabName}
          />
        ),
      };
    })
    .filter(nullishPredicate);
};
