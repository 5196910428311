import { FC } from 'react';
import styled from 'styled-components';

export const FourOhFour: FC = () => {
  return <Wrapper>404: Not found</Wrapper>;
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  max-height: 100vh;
  height: 100vh;
`;
