import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Papa from 'papaparse';
import { FC, useEffect, useState } from 'react';
import DataGrid from 'react-data-grid';
import { Link, useParams } from 'react-router-dom';
import { BigTitle, SubTitle, Title } from 'src/components/typography';
import styled from 'styled-components';

import { Image } from '../components/Image';
import { API_ROUTES } from '../constants';
import { useDocumentTitle, useFetch } from '../hooks';
import { authFetch, getDataUri, textFetch } from '../utils';

type RunImage = {
  name: string;
  type: string;
  url: string;
};

type APIDereplicationRun = {
  name: string;
  uuid: string;
  pipeline_version: string;
  date: string;
};

const fetchFasta = async (project: string, uuid: string) => {
  const fasta = await authFetch<RunImage[]>({
    url: API_ROUTES.dereplication.getFasta(project, uuid),
  });

  const result: Record<string, any> = {};

  for (const f of fasta || []) {
    if (!f) {
      continue;
    }

    const { url, name } = f;
    const res = await textFetch(url);
    result[name] = res || '';
  }

  return result;
};

const fetchAndParseTSVs = async (project: string, uuid: string) => {
  const rdp = await authFetch<RunImage[]>({
    url: API_ROUTES.dereplication.getTaxonomy(project, uuid),
  });
  const overview = await authFetch<RunImage[]>({
    url: API_ROUTES.dereplication.getOverview(project, uuid),
  });
  const predicted = await authFetch<RunImage>({
    url: API_ROUTES.dereplication.getAllPredictedStrains(project, uuid),
  });
  const tsvs = [...(rdp || []), ...(overview || []), ...[predicted]];
  const result: Record<string, any> = {};
  for (const tsv of tsvs) {
    if (!tsv) {
      continue;
    }
    const { url, name } = tsv;
    const res = await fetch(url);
    if (res.ok) {
      const body = await res.text();
      const parsed = Papa.parse(body, { delimiter: '\t', header: true });
      result[name] = { ...parsed, file: body };
    }
  }
  return result;
};

const fetchImages = async (project: string, uuid: string) => {
  const quality = await authFetch<RunImage[]>({
    url: API_ROUTES.dereplication.getQuality(project, uuid),
  });
  const rawReads = await authFetch<RunImage[]>({
    url: API_ROUTES.dereplication.getRawReads(project, uuid),
  });
  const filteredReads = await authFetch<RunImage[]>({
    url: API_ROUTES.dereplication.getFilteredReads(project, uuid),
  });
  const wellPurity = await authFetch<RunImage[]>({
    url: API_ROUTES.dereplication.getWellPurity(project, uuid),
  });
  const foods = await authFetch<RunImage[]>({
    url: API_ROUTES.dereplication.getFoods(project, uuid),
  });
  const conditions = await authFetch<RunImage[]>({
    url: API_ROUTES.dereplication.getConditions(project, uuid),
  });
  const selections = await authFetch<RunImage[]>({
    url: API_ROUTES.dereplication.getSelections(project, uuid),
  });

  return [
    ...(quality || []),
    ...(rawReads || []),
    ...(filteredReads || []),
    ...(wellPurity || []),
    ...(foods || []),
    ...(conditions || []),
    ...(selections || []),
  ].reduce((acc, img) => {
    acc[img.name] = img.url;
    return acc;
  }, {} as Record<string, string>);
};

export const DereplicationRun: FC = () => {
  const { project, uuid } = useParams();

  useDocumentTitle(`Dereplication ${uuid} - ${project}`);

  const { data: derepRuns = [] } = useFetch<APIDereplicationRun[]>(
    API_ROUTES.dereplication.getRuns()
  );

  const thisRun = derepRuns.find((run) => run.uuid === uuid);

  const [logs, setLogs] = useState<string | undefined>(undefined);
  const [images, setImages] =
    useState<Record<string, string> | undefined>(undefined);
  const [tsvs, setTsvs] = useState<Record<string, any> | undefined>(undefined);
  const [fasta, setFasta] =
    useState<Record<string, string> | undefined>(undefined);

  const { data: isRunInDb } = useFetch<boolean>(
    API_ROUTES.dereplication.getRunInDb(project!, uuid!)
  );
  const { data: rawPredictedStrains } = useFetch<any>(
    API_ROUTES.dereplication.getPredictedStrains(project!, uuid!)
  );
  const { data: alreadyAdded } = useFetch<any>(
    API_ROUTES.dereplication.getAlreadyAdded(project!, uuid!)
  );
  const { data: prevDerep } = useFetch<any>(
    API_ROUTES.dereplication.getPrevDerep(project!, uuid!)
  );
  const { data: outputLogs } = useFetch<any>(
    API_ROUTES.dereplication.getLogs(project!, uuid!)
  );
  const { data: ccIds } = useFetch<any>(
    API_ROUTES.dereplication.getCCIds(project!)
  );
  const { cc_one, cc_two } = ccIds || {};

  const rdp_16SGridInfo = getHeaderlessTsvData(tsvs?.['rdp_16S']?.file);
  const rdp_ITSGridInfo = getHeaderlessTsvData(tsvs?.['rdp_ITS']?.file);

  const predictedStrains = (rawPredictedStrains || []).reduce(
    (acc: Record<string, any>, ps: any) => {
      if (ps.major_seq_type) {
        acc[ps.major_seq_type] = (acc[ps.major_seq_type] || 0) + 1;
      }

      return acc;
    },
    {}
  );

  useEffect(() => {
    // window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const doFetch = async () => {
      if (project && uuid) {
        try {
          const result = await fetchImages(project, uuid);
          setImages(result);
          const tsvdata = await fetchAndParseTSVs(project, uuid);
          setTsvs(tsvdata);
          const fastaRes = await fetchFasta(project, uuid);
          setFasta(fastaRes);
        } catch (e) {
          setImages(undefined);
        }
      }
    };

    doFetch();
  }, [project, uuid]);

  useEffect(() => {
    const doFetch = async () => {
      try {
        const body = await textFetch(outputLogs.url);
        if (body) {
          setLogs(body);
        }
      } catch (e) {
        console.error(e);
      }
    };

    if (!outputLogs?.url) {
      return;
    }

    doFetch();
  }, [outputLogs]);

  return (
    <div>
      <Wrapper>
        <Link to="/dereplication">← Projects</Link>
        <BigTitle>Project {project}</BigTitle>
        <Field>Run UUID: {uuid}</Field>
        {thisRun?.date ? (
          <Field>
            Run Date:{' '}
            {`${new Date(thisRun.date).toLocaleTimeString()} ${new Date(
              thisRun.date
            ).toDateString()}`}
          </Field>
        ) : null}
        <Field>
          Run in db: {`${typeof isRunInDb === 'undefined' ? '...' : isRunInDb}`}
        </Field>
        <Field>
          Predicted strains:{' '}
          {typeof rawPredictedStrains === 'undefined'
            ? '...'
            : Object.keys(predictedStrains)
                .map(
                  (strainType: string) =>
                    `${predictedStrains[strainType]} ${strainType} strains`
                )
                .join(', ')}
        </Field>
        <Field>
          Already added:{' '}
          {`${typeof alreadyAdded === 'undefined' ? '...' : alreadyAdded}`}
        </Field>
        <Field>
          Strains previously dereplicated by this project:{' '}
          {typeof prevDerep === 'undefined'
            ? '...'
            : !!prevDerep?.length
            ? prevDerep.length
            : 'None'}
        </Field>
        <BigTitle>Logs for the run</BigTitle>
        <LogView>{logs}</LogView>

        <BigTitle>Sequence Quality</BigTitle>
        <FourGrid>
          <div>
            <Title>16S</Title>
            <SubTitle>{cc_one}</SubTitle>
            <Image src={images?.['16S_R1_quality']} alt="16S_R1_quality" />
          </div>
          <div>
            <Title>ITS</Title>
            <SubTitle>{cc_one}</SubTitle>
            <Image src={images?.['ITS_R1_quality']} alt="ITS_R1_quality" />
          </div>
          <div>
            <SubTitle>{cc_two}</SubTitle>
            <Image src={images?.['16S_R2_quality']} alt="16S_R2_quality" />
          </div>
          <div>
            <SubTitle>{cc_two}</SubTitle>
            <Image src={images?.['ITS_R2_quality']} alt="ITS_R2_quality" />
          </div>
        </FourGrid>

        <BigTitle>Raw Read Counts</BigTitle>
        <FourGrid>
          <div>
            <Title>16S</Title>
            <SubTitle>{cc_one}</SubTitle>
            <Image src={images?.['16S_one_raw_read']} alt="16S_one_raw_read" />
          </div>
          <div>
            <Title>ITS</Title>
            <SubTitle>{cc_one}</SubTitle>
            <Image src={images?.['ITS_one_raw_read']} alt="ITS_one_raw_read" />
          </div>
          <div>
            <SubTitle>{cc_two}</SubTitle>
            <Image src={images?.['16S_two_raw_read']} alt="16S_two_raw_read" />
          </div>
          <div>
            <SubTitle>{cc_two}</SubTitle>
            <Image src={images?.['ITS_two_raw_read']} alt="ITS_two_raw_read" />
          </div>
        </FourGrid>

        <BigTitle>Filtered Read Counts</BigTitle>
        <FourGrid>
          <div>
            <Title>16S</Title>
            <SubTitle>{cc_one}</SubTitle>
            <Image
              src={images?.['16S_one_filtered_read']}
              alt="16S_one_filtered_read"
            />
          </div>
          <div>
            <Title>ITS</Title>
            <SubTitle>{cc_one}</SubTitle>
            <Image
              src={images?.['ITS_one_filtered_read']}
              alt="ITS_one_filtered_read"
            />
          </div>
          <div>
            <SubTitle>{cc_two}</SubTitle>
            <Image
              src={images?.['16S_two_filtered_read']}
              alt="16S_two_filtered_read"
            />
          </div>
          <div>
            <SubTitle>{cc_two}</SubTitle>
            <Image
              src={images?.['ITS_two_filtered_read']}
              alt="ITS_two_filtered_read"
            />
          </div>
        </FourGrid>

        <BigTitle>Purity Per Well</BigTitle>
        <FourGrid>
          <div>
            <Title>16S</Title>
            <SubTitle>{cc_one}</SubTitle>
            <Image src={images?.['16S_one_purity']} alt="16S_one_purity" />
          </div>
          <div>
            <Title>ITS</Title>
            <SubTitle>{cc_one}</SubTitle>
            <Image src={images?.['ITS_one_purity']} alt="ITS_one_purity" />
          </div>
          <div>
            <SubTitle>{cc_two}</SubTitle>
            <Image src={images?.['16S_two_purity']} alt="16S_two_purity" />
          </div>
          <div>
            <SubTitle>{cc_two}</SubTitle>
            <Image src={images?.['ITS_two_purity']} alt="ITS_two_purity" />
          </div>
        </FourGrid>

        <FourGrid>
          <div>
            <BigTitle>Foods</BigTitle>
            <SubTitle>{cc_one}</SubTitle>
            <Image src={images?.['foods_one']} alt="foods_one" />
          </div>
          <div>
            <BigTitle>Conditions</BigTitle>
            <SubTitle>{cc_one}</SubTitle>
            <Image src={images?.['conditions_one']} alt="conditions_one" />
          </div>
          <div>
            <SubTitle>{cc_two}</SubTitle>
            <Image src={images?.['foods_two']} alt="foods_two" />
          </div>
          <div>
            <SubTitle>{cc_two}</SubTitle>
            <Image src={images?.['conditions_two']} alt="conditions_two" />
          </div>
        </FourGrid>

        <hr />

        <BigTitle>Selections Per Well</BigTitle>
        <FourGrid>
          <div>
            <Title>16S (select gold / silver)</Title>
            <SubTitle>{cc_one}</SubTitle>
            <Image
              src={images?.['selections_16S_one']}
              alt="selections_16S_one"
            />
          </div>
          <div>
            <Title>ITS (select gold / silver)</Title>
            <SubTitle>{cc_one}</SubTitle>
            <Image
              src={images?.['selections_ITS_one']}
              alt="selections_ITS_one"
            />
          </div>
          <div>
            <SubTitle>{cc_two}</SubTitle>
            <Image
              src={images?.['selections_16S_two']}
              alt="selections_16S_two"
            />
          </div>
          <div>
            <SubTitle>{cc_two}</SubTitle>
            <Image
              src={images?.['selections_ITS_two']}
              alt="selections_ITS_two"
            />
          </div>
        </FourGrid>

        <TitleAndDownload>
          <BigTitle>Predicted 16S OTUs</BigTitle>
          <DownloadButton txts={fasta} name="16S_fasta" />
        </TitleAndDownload>
        <LogView>{fasta?.['16S_fasta']}</LogView>

        <TitleAndDownload>
          <BigTitle>Predicted ITS OTUs</BigTitle>
          <DownloadButton txts={fasta} name="ITS_fasta" />
        </TitleAndDownload>
        <LogView>{fasta?.['ITS_fasta']}</LogView>

        <TitleAndDownload>
          <BigTitle>Predicted 16S Taxonomy (RDP)</BigTitle>
          <DownloadButton tsvs={tsvs} name="rdp_16S" />
        </TitleAndDownload>
        {!!tsvs?.['rdp_16S'].data ? (
          <DataGrid
            rows={rdp_16SGridInfo?.data || []}
            columns={rdp_16SGridInfo?.columns || []}
            headerRowHeight={0}
          />
        ) : null}

        <TitleAndDownload>
          <BigTitle>Predicted ITS Taxonomy (RDP)</BigTitle>
          <DownloadButton tsvs={tsvs} name="rdp_ITS" />
        </TitleAndDownload>
        {!!tsvs?.['rdp_ITS'].data ? (
          <DataGrid
            rows={rdp_ITSGridInfo?.data || []}
            columns={rdp_ITSGridInfo?.columns || []}
            headerRowHeight={0}
          />
        ) : null}

        <TitleAndDownload>
          <BigTitle>Per Well Info, Plate 1</BigTitle>
          <DownloadButton tsvs={tsvs} name="overview_one" />
        </TitleAndDownload>
        {!!tsvs?.['overview_one'].data ? (
          <DataGrid
            rows={tsvs['overview_one'].data}
            columns={(tsvs['overview_one'].meta.fields || []).map(
              (field: string) => ({
                name: field,
                key: field,
              })
            )}
          />
        ) : null}

        <TitleAndDownload>
          <BigTitle>Per Well Info, Plate 2</BigTitle>
          <DownloadButton tsvs={tsvs} name="overview_two" />
        </TitleAndDownload>
        {!!tsvs?.['overview_two'].data ? (
          <DataGrid
            rows={tsvs['overview_two'].data}
            columns={(tsvs['overview_two'].meta.fields || []).map(
              (field: string) => ({
                name: field,
                key: field,
              })
            )}
          />
        ) : null}

        <TitleAndDownload>
          <BigTitle>Predicted Strains</BigTitle>
          <DownloadButton tsvs={tsvs} name="predicted_strains" />
        </TitleAndDownload>
        {!!tsvs?.['predicted_strains'].data ? (
          <DataGrid
            rows={tsvs['predicted_strains'].data}
            columns={(tsvs['predicted_strains'].meta.fields || []).map(
              (field: string) => ({
                name: field,
                key: field,
              })
            )}
          />
        ) : null}
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 16px;
`;

const LogView = styled.div`
  font-family: Monaco, ui-monospace, monospace;
  color: white;
  white-space: pre;
  overflow: scroll;
  background: rgb(26, 28, 36);
  padding: 12px;
`;

const Field = styled.div`
  margin: 8px 0;
`;

const FourGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row: auto auto;
  gap: 12px;

  img {
    max-height: 100%;
    max-width: 100%;
    display: block;
    position: relative;

    &:before {
      content: ' ';
      display: block;

      position: absolute;
      top: 0px;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgb(230, 230, 230);
    }
    &:after {
      content: 'Missing image';
      display: block;
      font-size: 16px;
      font-style: normal;
      font-family: FontAwesome;
      color: rgb(100, 100, 100);

      position: absolute;
      top: 0px;
      left: 0;
      width: 100%;
      height: 50px;
      text-align: center;
    }
  }
`;

const TitleAndDownload = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const DownloadButton: FC<{ tsvs?: any; txts?: any; name: string }> = ({
  tsvs,
  txts,
  name,
}) => {
  const isTsv = !!tsvs?.[name]?.data;
  const isTxt = !!txts?.[name];

  if (!isTsv && !isTxt) {
    return null;
  }

  return (
    <Button
      href={getDataUri(isTsv ? tsvs[name].file : txts[name])}
      download={`${name}.${isTsv ? 'tsv' : 'txt'}`}
    >
      <DownloadOutlined /> Download
    </Button>
  );
};

const getHeaderlessTsvData = (tsv?: string) => {
  if (!tsv) {
    return {};
  }
  const matrix = tsv.split('\n').map((row) => row.split('\t'));

  const columns = matrix[0].map((_c, index) => ({
    name: '',
    key: `${index}`,
  }));

  const data = matrix.map((row) => {
    return row.reduce((acc, item, index) => {
      acc[`${index}`] = item;

      return acc;
    }, {} as Record<string, any>);
  });

  return { columns, data };
};
