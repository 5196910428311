import { FC, useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { APIPlateWithAllChildren, PlateSize } from '../../types';
import { Title } from '../typography';
import { PlateControls } from './PlateControls';
import { PlateDataControls } from './PlateDataControls';
import { SelectedWells } from './SelectedWells';
import {
  Coordinate,
  DisplaySettings,
  EMPTY_WELL,
  Plate,
  PLATE_COLUMNS,
  PLATE_ROWS,
  Well,
} from './types';
import {
  addRowsAndColumnsToGrid,
  countSelectedWells,
  getSelectedWells,
  getSizeOfPlate,
  isColumnSelected,
  isGridSelected,
  isRowSelected,
  isWellSelected,
  rowLetterToNumber,
  rowNumberToLetter,
  saveGridContentsToPlate,
  selectAllWellsBetweenCoordinates,
  setSingleWell,
  toggleSingleWell,
} from './utils';

interface Props {
  size?: PlateSize;
  plate?: APIPlateWithAllChildren;
  editMode?: boolean;
}

export const PlateGrid: FC<Props> = ({ size = 384, plate, editMode }) => {
  const hasPlate = !!plate;
  const plateSize = hasPlate ? getSizeOfPlate(plate) : size;
  const [grid, setGrid] = useState<Plate>([[{ ...EMPTY_WELL }]]);
  const numCols = PLATE_COLUMNS[plateSize];
  const numRows = PLATE_ROWS[plateSize];
  const [isReady, setIsReady] = useState(false);
  const [displaySettings, setDisplaySettings] = useState<DisplaySettings>(
    DisplaySettings.Strains
  );
  const [lastSelectedRow, setLastSelectedRow] = useState<number | null>(null);
  const [lastSelectedCol, setLastSelectedCol] = useState<number | null>(null);
  const [lastSelectedWell, setLastSelectedWell] =
    useState<Coordinate | null>(null);

  // initialize matrix
  useEffect(() => {
    const matrix = Array.from({ length: numRows }, () =>
      Array.from({ length: numCols }, () => ({
        ...EMPTY_WELL,
      }))
    );

    if (hasPlate) {
      const { wells } = plate;
      for (let well of wells) {
        const { id, row, column, well_contents = [] } = well;
        const realRow = rowLetterToNumber(row);
        const realColumn = Number(column) - 1;
        const newWell: Well = {
          ...EMPTY_WELL,
          id,
          row,
          column,
          strains: [],
          barcodes: [],
          species: [],
        };

        for (let wellContent of well_contents) {
          const {
            strain_tube: { barcode, name, strain_id },
          } = wellContent;
          newWell.barcodes.push(barcode);
          newWell.strains.push(strain_id);
          newWell.species.push(name);
        }

        matrix[realRow][realColumn] = { ...newWell };
      }
    } else {
      addRowsAndColumnsToGrid(matrix);
    }
    setGrid(matrix);
    setIsReady(true);
  }, [hasPlate, numCols, numRows, plate, plateSize]);

  const updateGridWithImport = (importedWells: any[][], key: string) => {
    setGrid((oldGrid: Plate) => {
      const newGrid = [
        ...oldGrid.map((col, r) => [
          ...col.map((well, c) => ({
            ...well,
            [key]: [importedWells[r][c]],
          })),
        ]),
      ];
      return newGrid;
    });
  };

  const selectColumnFactory = useCallback(
    (
        column: number,
        {
          preserveSelectionState,
          setSelectionValue,
        }: {
          preserveSelectionState?: boolean;
          setSelectionValue?: boolean;
        } = {}
      ) =>
      (e?: React.MouseEvent) => {
        e?.preventDefault();
        if (e) {
          setLastSelectedCol(column);
        }
        const { shiftKey, metaKey } = e || { shiftKey: false, metaKey: false };

        if (shiftKey && lastSelectedCol) {
          if (lastSelectedCol < column) {
            for (let i = lastSelectedCol; i <= column; i++) {
              selectColumnFactory(i, { preserveSelectionState: true })();
            }
            return;
          } else {
            for (let i = column; i <= lastSelectedCol; i++) {
              selectColumnFactory(i, { preserveSelectionState: true })();
            }
          }
        } else if (metaKey) {
          if (isColumnSelected(grid, column)) {
            selectColumnFactory(column, {
              preserveSelectionState: true,
              setSelectionValue: false,
            })();
          } else {
            selectColumnFactory(column, { preserveSelectionState: true })();
          }
        } else {
          setGrid((oldGrid: Plate) => {
            const defaultValue =
              typeof setSelectionValue === 'undefined'
                ? true
                : setSelectionValue;
            const newGrid = [
              ...oldGrid.map((col) => [
                ...col.map((well, c) =>
                  c === column
                    ? { ...well, isSelected: defaultValue }
                    : {
                        ...well,
                        isSelected: preserveSelectionState
                          ? well.isSelected
                          : false,
                      }
                ),
              ]),
            ];
            return newGrid;
          });
        }
      },
    [lastSelectedCol, grid]
  );

  const selectRowFactory = useCallback(
    (
        row: number,
        {
          preserveSelectionState,
          setSelectionValue,
        }: {
          preserveSelectionState?: boolean;
          setSelectionValue?: boolean;
        } = {}
      ) =>
      (e?: React.MouseEvent) => {
        e?.preventDefault();
        if (e) {
          setLastSelectedRow(row);
        }
        const { shiftKey, metaKey } = e || { shiftKey: false, metaKey: false };

        if (lastSelectedRow && shiftKey) {
          if (lastSelectedRow < row) {
            for (let i = lastSelectedRow; i <= row; i++) {
              selectRowFactory(i, { preserveSelectionState: true })();
            }
            return;
          } else {
            for (let i = row; i <= lastSelectedRow; i++) {
              selectRowFactory(i, { preserveSelectionState: true })();
            }
          }
        } else if (metaKey) {
          if (isRowSelected(grid, row)) {
            selectRowFactory(row, {
              preserveSelectionState: true,
              setSelectionValue: false,
            })();
          } else {
            selectRowFactory(row, { preserveSelectionState: true })();
          }
        } else {
          const defaultValue =
            typeof setSelectionValue === 'undefined' ? true : setSelectionValue;

          setGrid((oldGrid: Plate) => {
            const newGrid = [
              ...oldGrid.map((col, r) => [
                ...col.map((well) =>
                  r === row
                    ? { ...well, isSelected: defaultValue }
                    : {
                        ...well,
                        isSelected: preserveSelectionState
                          ? well.isSelected
                          : false,
                      }
                ),
              ]),
            ];
            return newGrid;
          });
        }
      },
    [lastSelectedRow, grid]
  );

  const selectAllWells = () => {
    for (let a = 0; a < (numRows || 0); a++) {
      selectRowFactory(a, { preserveSelectionState: true })();
    }
  };

  const selectNoWells = () => {
    for (let a = 0; a < (numRows || 0); a++) {
      selectRowFactory(a, { setSelectionValue: false })();
    }
  };

  const onWellClickFactory =
    (row: number, column: number) => (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const { shiftKey, metaKey } = e;
      const isMultiSelect = shiftKey || metaKey;
      if (!isMultiSelect || (shiftKey && !lastSelectedWell)) {
        const isWellAlreadySelected = isWellSelected(grid, row, column);
        const areOtherWellsSelected = countSelectedWells(grid) > 1;
        setLastSelectedWell(isWellAlreadySelected ? null : [row, column]);
        selectNoWells();
        if (areOtherWellsSelected || !isWellAlreadySelected) {
          setSingleWell({
            row,
            column,
            setGrid,
            isSelected: true,
          });
        }
      } else if (metaKey && !shiftKey) {
        setLastSelectedWell([row, column]);
        toggleSingleWell({ row, column, setGrid });
      } else if (shiftKey) {
        selectAllWellsBetweenCoordinates({
          pointA: lastSelectedWell || [0, 0],
          pointB: [row, column],
          setGrid,
        });
      }
    };

  if (!isReady) {
    return null;
  }

  const selectedWells = getSelectedWells(grid);

  return (
    <PageWrapper>
      <Title>
        {plate?.plate_name} (Plate {plate?.id})
      </Title>
      {editMode ? (
        <PlateDataControls
          onLoadCSV={updateGridWithImport}
          plateSize={plateSize}
          doSave={async () => {
            if (plate?.id) {
              await saveGridContentsToPlate({ grid, plateId: plate?.id });
            } else {
              throw new Error('No server plate');
            }
          }}
        />
      ) : null}
      <ComponentWrapper>
        <PlateContainer>
          <PlateWrapper>
            {/* header row */}
            <Row numCols={(numCols || 0) + 1} sticky>
              <RCButton
                sticky
                onClick={(e) => {
                  e.preventDefault();
                  isGridSelected(grid) ? selectNoWells() : selectAllWells();
                }}
              >
                ...
              </RCButton>
              {grid[0].map((v, c) => (
                <RCButton key={`colhead_${c}`} onClick={selectColumnFactory(c)}>
                  {c + 1}
                </RCButton>
              ))}
            </Row>
            {grid.map((v, r) => (
              <Row numCols={(numCols || 0) + 1} key={`row_${r}`}>
                <RCButton sticky onClick={selectRowFactory(r)}>
                  {rowNumberToLetter(r)}
                </RCButton>
                {grid[r].map((well, c) => {
                  return (
                    <WellContainer
                      key={`well_${r}${c}`}
                      {...well}
                      onClick={onWellClickFactory(r, c)}
                    >
                      <WellText>
                        {displaySettings === DisplaySettings.R1C1 ? (
                          `${rowNumberToLetter(r)}${c + 1}`
                        ) : (
                          <>
                            {well[displaySettings].map((txt) => (
                              <div key={`${r}${c}${txt}`}>{txt}</div>
                            ))}
                          </>
                        )}
                      </WellText>
                    </WellContainer>
                  );
                })}
              </Row>
            ))}
          </PlateWrapper>
        </PlateContainer>
        <PlateControls {...{ displaySettings, setDisplaySettings }} />
        {selectedWells.length ? (
          <SelectedWells selectedWells={selectedWells} />
        ) : null}
      </ComponentWrapper>
    </PageWrapper>
  );
};

const stickyMixin = css`
  position: sticky;
  left: 0;
  top: 0;
  z-index: 999;
`;

const stickyTopMixin = css`
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const PlateContainer = styled.div`
  width: 572px;
  height: 398px;
  border: 2px solid black;
  overflow: hidden;
  padding: 2px;
  user-select: none;
`;

const PlateWrapper = styled.div<{ numRows?: number }>`
  display: grid;
  grid-template-rows: 36px auto;
  gap: 8px;
  overflow: scroll;
  max-height: 400px;
  background-color: white;
  ::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
  }
`;
const Row = styled.div<{ numCols?: number; sticky?: boolean }>`
  display: grid;
  grid-template-columns: repeat(${({ numCols }) => numCols || 0}, 36px);
  gap: 8px;
  min-width: 0;
  min-height: 0;
  height: 36px;
  ${({ sticky }) => (sticky ? stickyTopMixin : '')}
`;

const RCButton = styled.div<{ sticky?: boolean }>`
  cursor: pointer;
  aspect-ratio: 1;
  border-radius: 4px;
  border: 1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-size: 1.25rem;
  background-color: rgb(247, 176, 170);
  ${({ sticky }) => (sticky ? stickyMixin : '')}
`;
const WellContainer = styled.div<{ isSelected?: boolean }>`
  cursor: pointer;
  border: 2px solid black;
  border-radius: 50%;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 0.75rem;
  overflow: hidden;

  ${({ isSelected }) =>
    isSelected
      ? css`
          &:after {
            position: absolute;
            border: 6px solid blue;
            border-radius: 50%;
            width: 75%;
            height: 75%;
            opacity: 0.4;
            content: '';
          }
        `
      : ''}
`;

const WellText = styled.div`
  position: absolute;
  font-size: 0.5rem;
  text-align: center;
`;

const ComponentWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 200px;
  & > div {
    flex: 1;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
