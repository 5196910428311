import { Modal as AntdModal } from 'antd';
import { CSSProperties, FC, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  style?: CSSProperties;
  mask?: boolean;
  children?: ReactNode;
  onCancel?: () => void;
  header?: ReactNode;
  footer?: string | ReactNode;
  open?: boolean;
  width?: string | number;
  noMaxWidth?: boolean;
}

export const Modal: FC<Props> = ({
  children,
  header,
  footer,
  style,
  mask,
  onCancel,
  open = true,
  width = 600,
  noMaxWidth,
}) => {
  return (
    <StyledModal
      open={open}
      closable={false}
      width={width}
      footer={null}
      bodyStyle={{
        padding: '0px',
        borderRadius: '0px',
      }}
      style={style}
      onCancel={onCancel}
      centered
      mask={mask}
    >
      <BodyWrapper noMaxWidth={noMaxWidth}>
        {!!header ? <Header>{header}</Header> : null}
        <Body noMaxWidth={noMaxWidth}>{children}</Body>
        {!!footer ? <Header borderless>{footer}</Header> : null}
      </BodyWrapper>
    </StyledModal>
  );
};

const StyledModal = styled(AntdModal)`
  .ant-modal-content {
    border-radius: 0px;
    box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.27);
    overflow: auto;
    max-height: 85vh;
    padding: 0px;
  }
`;

const Header = styled.div<{ borderless?: boolean }>`
  ${({ borderless }) =>
    borderless ? '' : 'border-bottom: 1px solid lightgrey;'}
  font-size: 14px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  ${({ borderless }) => (borderless ? 'bottom: 0' : 'top: 0')};
  background: white;
  z-index: 99;
`;

const BodyWrapper = styled.div<{ noMaxWidth?: boolean }>`
  ${({ noMaxWidth }) => (noMaxWidth ? '' : 'max-width: 80vw;')}
  margin: 0 auto;
`;

const Body = styled.div<{ noMaxWidth?: boolean }>`
  ${({ noMaxWidth }) =>
    noMaxWidth ? 'padding: 16px 0px;' : 'padding: 16px 64px;'}
  display: flex;
  flex-direction: column;
  position: relative;
`;
