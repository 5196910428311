import { DatePicker } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';

interface Props {
  loading?: boolean;
  count?: number;
  totalCount?: number;
  onDateRangeChange: (dates: any) => void;
}

export const SeriesListHeader: FC<Props> = ({
  loading,
  count,
  totalCount,
  onDateRangeChange,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '8px',
        backgroundColor: 'white',
      }}
    >
      <InfoWrapper>
        <DatePicker.RangePicker
          size="small"
          placeholder={['From all time', 'To now']}
          allowEmpty={[true, true]}
          onChange={(values) => {
            const startMomentString = values?.[0]
              ?.startOf('day')
              .format('YYYY-MM-DD');
            const endMomentString = values?.[1]
              ?.endOf('day')
              .format('YYYY-MM-DD');
            onDateRangeChange([startMomentString, endMomentString]);
          }}
        />
      </InfoWrapper>
      <PaginationInfoWrapper>
        {loading ? 'Loading... ' : ''}
        {count} / {totalCount}
      </PaginationInfoWrapper>
    </div>
  );
};

const InfoWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  color: grey;
`;
const PaginationInfoWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  font-size: 12px;
  color: grey;
`;
