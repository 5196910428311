import { FC } from 'react';
import { Carts } from 'src/components/cart';

import { useDocumentTitle } from '../hooks';

export const CartsScreen: FC = () => {
  useDocumentTitle(`Carts`);

  return (
    <div>
      <Carts />
    </div>
  );
};
