import {
  ArrowsAltOutlined,
  CopyOutlined,
  DiffOutlined,
  DownloadOutlined,
  LoadingOutlined,
  PlusOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { Button, Input, Tooltip } from 'antd';
import { FC, useState } from 'react';
import styled from 'styled-components';

import { BiobankType } from '../../../types';
import { downloadFromString, fetchAllPages } from '../../../utils';
import { ControlsRow } from '../../layout';
import { Modal } from '../../Modal';
import { AddToCartModal } from '../AddToCartModal';
import { BIOBANK_TYPE_NAME } from '../constants';
import { RunTaskModal } from '../RunTaskModal';
import { TableColumn } from '../types';
import {
  doesTypeAllowBulkUpload,
  doesTypeAllowSearching,
  doesTypeAllowShopping,
  doesTypeAllowUpload,
  generateRowsTSV,
} from '../utils';
import { ColumnPicker } from './ColumnPicker';
import { SearchFieldsSelect } from './SearchFieldsSelect';

interface Props {
  type: BiobankType;
  selectedRowsSize?: number;
  sortedRowsLength?: number;
  doOpenAddModal?: () => void;
  doCopy: () => void;
  loading?: boolean;
  tableColumns?: TableColumn[];
  selectedColumns?: string[];
  resetSelectedColumns: () => void;
  searchValue?: string;
  setSearchValue: (a: string) => void;
  searchField?: string;
  setSearchField: (a: string) => void;
  setQueryParams: (a: any) => void;
  dataCount: number;
  totalCount: number;
  getSelectedRows: () => Record<string, any>[];
  selectedTableColumns: TableColumn[];
  dataSourceUrl: string;
  isMultiCartView?: boolean;
  cartId?: number;
  loadAll?: () => void;
  forceFetch?: () => void;
}

export const BiobankHeader: FC<Props> = ({
  type,
  selectedRowsSize,
  sortedRowsLength,
  doOpenAddModal,
  loading,
  tableColumns,
  selectedColumns,
  resetSelectedColumns,
  searchValue,
  setSearchValue,
  searchField,
  setSearchField,
  setQueryParams,
  dataCount,
  totalCount,
  getSelectedRows,
  selectedTableColumns,
  dataSourceUrl,
  doCopy,
  isMultiCartView,
  cartId,
  loadAll,
  forceFetch,
}) => {
  const [isProcessingDownload, setIsProcessingDownload] = useState(false);
  const [isAddToCartModalOpen, setIsAddToCartModalOpen] = useState(false);
  const [isRunTaskModalOpen, setIsRunTaskModalOpen] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  const ButtonComponent = isMultiCartView ? HoveredExpandButton : Button;

  const downloadRowCount =
    selectedRowsSize || (!!searchValue && !!searchField ? totalCount : 0);

  const doDownload = async () => {
    setIsProcessingDownload(true);
    if (selectedRowsSize) {
      // download selected rows only
      downloadFromString(
        generateRowsTSV(getSelectedRows(), selectedTableColumns),
        `${type}-selected-${new Date().toISOString()}.tsv`
      );

      setIsProcessingDownload(false);

      return;
    }

    // otherwise download paginated response
    const data = await fetchAllPages(dataSourceUrl);
    downloadFromString(
      generateRowsTSV(data, selectedTableColumns),
      `${type}-${searchField}-${searchValue}-${new Date().toISOString()}.tsv`
    );

    setIsProcessingDownload(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '8px',
        maxWidth: isMultiCartView ? `calc((100vw - 42px) / 2` : undefined,
        backgroundColor: 'white',
      }}
    >
      <AddToCartModal
        open={isAddToCartModalOpen}
        onCancel={() => setIsAddToCartModalOpen(false)}
        getSelectedRows={getSelectedRows}
        type={type}
      />
      {isSearchModalOpen ? (
        <Modal
          header="Search"
          open
          onCancel={() => setIsSearchModalOpen(false)}
        >
          <Input.TextArea
            autoSize
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Modal>
      ) : null}
      {isRunTaskModalOpen ? (
        <Modal
          header="Run Task"
          open
          onCancel={() => setIsRunTaskModalOpen(false)}
        >
          <RunTaskModal cartId={cartId} cartType={type} />
        </Modal>
      ) : null}
      <ControlsRow>
        {/* {!!cartId ? (
          <ButtonComponent
            size="small"
            style={buttonStyle}
            icon={<PlayCircleOutlined />}
            onClick={() => setIsRunTaskModalOpen(true)}
          >
            Run Task
          </ButtonComponent>
        ) : null} */}
        {doesTypeAllowUpload(type) && doOpenAddModal ? (
          <ButtonComponent
            size="small"
            icon={<PlusOutlined />}
            href={`/biobank/${type}/new`}
            onClick={(e) => {
              e.preventDefault();
              doOpenAddModal?.();
            }}
            style={buttonStyle}
          >
            Add {BIOBANK_TYPE_NAME[type]}
          </ButtonComponent>
        ) : null}
        {doesTypeAllowBulkUpload(type) ? (
          <ButtonComponent
            size="small"
            icon={<DiffOutlined />}
            href={`/biobank/${type}/upload`}
            style={buttonStyle}
          >
            Add {BIOBANK_TYPE_NAME[type]}s
          </ButtonComponent>
        ) : null}
        {sortedRowsLength && !!downloadRowCount ? (
          <ButtonComponent
            size="small"
            icon={
              isProcessingDownload ? <LoadingOutlined /> : <DownloadOutlined />
            }
            style={buttonStyle}
            onClick={doDownload}
            disabled={isProcessingDownload}
          >
            Download {downloadRowCount}{' '}
            {!!selectedRowsSize ? 'selected' : 'query'} rows
          </ButtonComponent>
        ) : null}
        {selectedRowsSize ? (
          <>
            <ButtonComponent
              size="small"
              icon={<CopyOutlined />}
              style={buttonStyle}
              onClick={doCopy}
            >
              <Tooltip title={`⌘-C`}>
                Copy {downloadRowCount} rows to clipboard
              </Tooltip>
            </ButtonComponent>
            {doesTypeAllowShopping(type) ? (
              <ButtonComponent
                size="small"
                icon={<ShoppingCartOutlined />}
                style={buttonStyle}
                onClick={() => setIsAddToCartModalOpen(true)}
              >
                Copy {downloadRowCount} rows to new Cart
              </ButtonComponent>
            ) : null}
          </>
        ) : null}
      </ControlsRow>
      <div style={{ display: 'flex', gap: '8px' }}>
        <PaginationInfoWrapper>
          {loading ? 'Loading... ' : ''}
          {dataCount} / {totalCount}
        </PaginationInfoWrapper>
        {!loadAll ? null : (
          <Button
            size="small"
            style={buttonStyle}
            onClick={loadAll}
            disabled={loading}
          >
            Load All
          </Button>
        )}
        <ColumnPicker
          columns={tableColumns}
          selectedColumns={selectedColumns}
          setSelectedColumns={(cols: string[]) => {
            setQueryParams({ columns: cols.join(',') });
          }}
          reset={resetSelectedColumns}
        />
        <SearchInput
          size="small"
          style={buttonStyle}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onSearch={() => forceFetch?.()}
          allowClear
          addonAfter={
            <Tooltip title="Expand search input">
              <Button
                size="small"
                type="text"
                onClick={() => setIsSearchModalOpen(true)}
              >
                <ArrowsAltOutlined />
              </Button>
            </Tooltip>
          }
          addonBefore={
            doesTypeAllowSearching(type) ? (
              <SearchFieldsSelect
                value={searchField}
                onSelect={(val) => setSearchField(val)}
                tableColumns={tableColumns}
              />
            ) : undefined
          }
        />
      </div>
    </div>
  );
};

const PaginationInfoWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  font-size: 12px;
  color: grey;
`;

const SearchInput = styled(Input.Search)`
  &&& .ant-select-selection-item,
  &&& .ant-select-selection-placeholder {
    font-size: 12px;
  }
`;

const buttonStyle = {
  fontSize: '12px',
  alignItems: 'center',
  display: 'inline-flex',
  textDecoration: 'none',
};

const HoveredExpandButton = styled(Button)`
  transition: max-width 0.2s;
  max-width: 28px;
  overflow: hidden;

  &&&:hover {
    max-width: 100%;
  }
`;
