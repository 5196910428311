import { FC, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { getProjectExperiments, getProjects } from '../api';
import { useFetch } from '../hooks';
import { APIExperimentWithPlates, APIProject } from '../types';
import { authFetch } from '../utils';
import { CreateExperiment } from './CreateExperiment';
import { CreatePlate, CreatePlateRef } from './CreatePlate';
import { CreateProject } from './CreateProject';
import { Select } from './forms';
import { Title } from './typography';

export const Manager: FC = () => {
  const [projectId, setProjectId] = useState<string | undefined>();
  const [experimentId, setExperimentId] = useState<string | undefined>();
  const [plateId, setPlateId] = useState<string | undefined>();

  const [experiments, setExperiments] = useState<APIExperimentWithPlates[]>([
    { id: 'NULL', experiment_name: 'Loading...', plates: [] },
  ]);

  const navigate = useNavigate();
  const createPlateRef = useRef<CreatePlateRef>(null);

  const {
    loading,
    refresh: refreshProjects,
    data: projects = [],
  } = useFetch<APIProject[]>(getProjects().url);

  const doRefresh = async () => {
    refreshProjects();
    const fetchExperiments = async () => {
      if (projectId) {
        const apiExperiments = await authFetch(
          getProjectExperiments(projectId)
        );
        setExperiments(apiExperiments);
        setExperimentId(apiExperiments[0]?.id);
      }
    };
    setExperiments([{ id: 'NULL', experiment_name: 'Loading...', plates: [] }]);
    fetchExperiments();
    createPlateRef.current?.refresh();
  };

  useEffect(() => {
    if (projects.length && !projectId) {
      setProjectId(projects[0].id);
    }
  }, [projects, projectId]);

  useEffect(() => {
    const selectedExperiment = experiments.find(
      ({ id }) => id === experimentId
    );
    setPlateId(selectedExperiment?.plates[0]?.id);
  }, [experimentId, experiments]);

  useEffect(() => {
    const fetchExperiments = async () => {
      if (projectId) {
        const apiExperiments = await authFetch(
          getProjectExperiments(projectId)
        );
        setExperiments(apiExperiments);
        setExperimentId(apiExperiments[0]?.id);
      }
    };
    setExperiments([{ id: 'NULL', experiment_name: 'Loading...', plates: [] }]);
    fetchExperiments();
  }, [projectId]);

  const plates =
    experiments.find(({ id }) => id === experimentId)?.plates || [];

  return (
    <div>
      <Title>View Plate</Title>
      <Wrapper>
        <SelectsContainer>
          <SelectContainer>
            Project
            <Select
              value={projectId}
              onChange={(e) => setProjectId(e.target.value)}
            >
              {loading ? (
                <option>Loading</option>
              ) : (
                projects.map(({ id, project_name, project_short_code }) => (
                  <option key={id} value={id}>
                    {project_name} - {project_short_code}
                  </option>
                ))
              )}
            </Select>
            <CreateProject onCreate={doRefresh} />
          </SelectContainer>
          <SelectContainer>
            Experiment
            <Select
              value={experimentId}
              onChange={(e) => setExperimentId(e.target.value)}
            >
              {experiments.map(({ id, experiment_name }) => (
                <option key={id} value={id}>
                  {experiment_name}
                </option>
              ))}
            </Select>
            <CreateExperiment projects={projects} onCreate={doRefresh} />
          </SelectContainer>
          <SelectContainer>
            Plate
            <Select
              value={plateId}
              onChange={(e) => setPlateId(e.target.value)}
            >
              {plates.map(({ id, plate_name }) => (
                <option key={id} value={id}>
                  {plate_name}
                </option>
              ))}
            </Select>
            <CreatePlate
              ref={createPlateRef}
              onCreate={(response) => {
                if (response?.id) {
                  navigate(`/plates/${response.id}/edit`);
                }
              }}
            />
          </SelectContainer>
          <SelectContainer>
            <br />
            {plateId ? <Link to={`/plates/${plateId}`}>View</Link> : null}
          </SelectContainer>
        </SelectsContainer>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25%);
  gap: 8px;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;

  & div:last-child {
    margin-top: 24px;
  }
`;
