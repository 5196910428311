import { useEffect, useState } from 'react';

import { authFetch } from '../utils';

// for GETting data only
export const useFetch = <T>(url?: string | null) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<T | undefined>();
  const [fetchCount, setFetchCount] = useState<number>(1);

  useEffect(() => {
    if (!url) {
      return;
    }
    const doFetch = async () => {
      try {
        setLoading(true);
        const res = await authFetch({ url });
        setData(res);
      } catch (e) {
        setData(undefined);
        setError(e);
      } finally {
        setLoading(false);
      }
    };
    doFetch();
  }, [url, fetchCount]);

  return { loading, error, data, refresh: () => setFetchCount(fetchCount + 1) };
};
