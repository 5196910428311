import { CSSProperties, FC } from 'react';

import { BiobankType } from '../../types';

export enum FIELD_TYPES {
  Hardcoded = 'hardcoded',
  SingleText = 'single_text',
  Number = 'number',
  Textarea = 'textarea',
  Date = 'date',
  Boolean = 'boolean',
  Tags = 'tags',
  Nested = 'nested',
  NestedByName = 'nested_by_name',
  Derived = 'derived',
  Species = 'species',
  TypeaheadTag = 'typeahead_tag',
  DownloadURL = 'download_url',
}

export type FieldValue = {
  title: string;
  field: string;
  type: FIELD_TYPES;
  tagFieldNames?: string[];
  tagOptions?: Record<string, any>;
  nestedFieldType?: BiobankType;
  nestedFieldName?: string;
  derive?: (val: any) => string | JSX.Element;
  typeaheadType?: string;
  required?: boolean;
  defaultValue?: any;
  style?: CSSProperties;
  hideFieldTitle?: boolean;
};

export type TypeaheadType =
  | 'high_level_substrates'
  | 'substrate_descriptors'
  | 'high_level_products'
  | 'product_descriptors'
  | 'outgrowth_conditions'
  | 'food_species';

export enum TableColumnSchemaType {
  Boolean = 'boolean',
  Integer = 'integer',
  Number = 'number',
  String = 'string',
  ColonyIsolationPlateSetSchemaOut = 'Object.ColonyIsolationPlateSetSchemaOut',
  CultureOutgrowthConditionSchemaOut = 'Object.CultureOutgrowthConditionSchemaOut',
  CultureSourceSchemaOut = 'Object.CultureSourceSchemaOut',
  CultureSourceTubeSchemaOut = 'Object.CultureSourceTubeSchemaOut',
  DefinedStrainSchemaOut = 'Object.DefinedStrainSchemaOut',
  HighLevelProductSchemaOut = 'Object.HighLevelProductSchemaOut',
  HighLevelSubstrateSchemaOut = 'Object.HighLevelSubstrateSchemaOut',
  ProductDescriptorSchemaOut = 'Object.ProductDescriptorSchemaOut',
  SubstrateDescriptorSchemaOut = 'Object.SubstrateDescriptorSchemaOut',
  StrainSchemaOut = 'Object.StrainSchemaOut',
  UserSchema = 'Object.UserSchema',
}

export type TableColumnType =
  | `array[${TableColumnSchemaType}]`
  | TableColumnSchemaType;

export type TableColumn = {
  schema_name: string;
  display_name: string;
  default_display: boolean;
  format_info: 'italics' | null;
  type: TableColumnType;
};

export type ItemDisplayer<T = {}> = FC<{ item: any } & T>;
