import { API_ROUTES } from '../constants';
import { BiobankType, HTTPMethod } from '../types';

export const getBiobankList = (type: string) => ({
  url: API_ROUTES.biobank.getAll(type),
});

export const getBiobankColumns = (type: string) => ({
  url: API_ROUTES.biobank.getTableColumns(type),
});

export const getBiobankItem = (type: string, id: string) => ({
  url: API_ROUTES.biobank.getItem(type, id),
});

export const deleteBiobankEntity = (type: BiobankType, entity: any) => ({
  url: API_ROUTES.biobank.deleteEntity(type, entity),
  options: {
    method: HTTPMethod.DELETE,
  },
});

export const addBiobankEntity = (type: BiobankType, entity: any) => ({
  url: API_ROUTES.biobank.addItem(type),
  options: {
    method: HTTPMethod.POST,
    data: entity,
  },
});

export const validateBiobankEntity = (type: BiobankType, entity: any) => ({
  url: API_ROUTES.biobank.validateSingle(type),
  options: {
    method: HTTPMethod.POST,
    data: entity,
  },
});
