import { getJob } from 'src/api/cartTasks';
import { JobResultStatus, SeriesJobs } from 'src/types/api/tasks';

import { useFetch, useInterval } from '.';

export const useJobSeries = (seriesId?: string) => {
  const { loading, data, refresh } = useFetch<SeriesJobs>(
    seriesId ? getJob(seriesId).url : undefined
  );

  useInterval(() => {
    if (!data) {
      return;
    }

    const { items } = data;
    const isDone = items.every(({ job_status }) => {
      return [
        JobResultStatus.Finished,
        JobResultStatus.Succeeded,
        JobResultStatus.Errored,
      ].includes(job_status);
    });

    if (isDone) {
      return;
    }

    refresh();
  }, 5000);

  if (!seriesId) {
    return {};
  }

  return { loading, data };
};
