import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { getPlate } from '../api/plates';
import { PlateGrid } from '../components/plates';
import { useFetch } from '../hooks';
import { APIPlateWithAllChildren } from '../types';

interface Props {
  editMode?: boolean;
}

export const PlateViewer: FC<Props> = ({ editMode }) => {
  const [isEditMode, _setIsEditMode] = useState<boolean>(!!editMode);
  const { plateId } = useParams();
  const {
    loading,
    error,
    data: plate,
  } = useFetch<APIPlateWithAllChildren>(getPlate(plateId!).url);

  return (
    <div>
      <PlateWrapper>
        {loading ? (
          'Loading...'
        ) : error ? (
          'Error loading plate'
        ) : (
          <PlateGrid plate={plate} editMode={isEditMode} />
        )}
      </PlateWrapper>
    </div>
  );
};

const PlateWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 85%;
`;
