import { API_ROUTES } from '../constants';
import { BiobankType, InputFields, InputFieldType } from '../types';
import { useFetch } from './useFetch';

export const useInputFields = (itemType: BiobankType) => {
  const inputFieldsUrl = API_ROUTES.biobank.inputFields(itemType);
  const { data } = useFetch<InputFields>(inputFieldsUrl);

  const defaultValues = (data?.fields || []).reduce((acc, field) => {
    if (field.default) {
      if (field.type === InputFieldType.Date) {
        const formattedDate = field.default.match(/^\d{4}-\d{2}-\d{2}/g);
        if (formattedDate?.[0]) {
          return { ...acc, [field.schema_name]: formattedDate[0] };
        }
      } else if (field.type === InputFieldType.Boolean) {
        return { ...acc, [field.schema_name]: field.default || false };
      }

      return { ...acc, [field.schema_name]: field.default };
    }

    return acc;
  }, {} as Record<string, string | boolean>);

  return { rawInputFields: data, defaultValues };
};
