export enum BiobankType {
  Foods = 'foods',
  FoodTubes = 'food_tubes',
  Strains = 'strains',
  PickPlates = 'pick_plates',
  OutgrowthConditions = 'outgrowth_conditions',
  IsolationPlates = 'isolation_plates',
  Lyo = 'lyo',
  LyoTubes = 'lyo_tubes',
  Picks = 'basic_picks',
  Genomes = 'genomes',
  StrainTubes = 'strain_tubes',
}
