import { useEffect, useRef, useState } from 'react';

import { authFetch } from '../utils';

type Options = {
  pageSize?: number;
  disable?: boolean;
};

// for GETting data only
export const usePaginatedFetch = <T>(
  url: string,
  { pageSize: providedPageSize, disable }: Options = { pageSize: 100 }
) => {
  const pageSize = providedPageSize || 100;
  const [data, setData] = useState<T[]>([]);
  const initialPageLoaded = useRef(false);
  const currentUrl = useRef(url);
  const [totalCount, setTotalCount] = useState(0);
  const offset = useRef(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const abortController = useRef(new AbortController());

  const loadMore = async () => {
    if (data.length < totalCount) {
      offset.current += pageSize;
      const hasQ = /\?/.test(url);
      currentUrl.current = `${url}${hasQ ? '&' : '?'}limit=${pageSize}&offset=${
        offset.current
      }`;
      await loadItems();
    }
  };

  const loadAll = async () => {
    while (offset.current < totalCount) {
      await loadMore();
    }
  };

  const loadItems = async () => {
    if (!!disable) {
      return;
    }

    try {
      abortController.current = new AbortController();
      setIsLoading(true);
      const res = await authFetch({
        url: currentUrl.current,
        options: { signal: abortController.current?.signal },
      });
      const { items, count } = res;
      setData((existingData) => [...existingData, ...items]);
      setTotalCount(count);
    } catch (e) {
      console.error(e);
      setError((e as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  const forceFetch = () => {
    if (isLoading) {
      abortController.current?.abort();
    }
    setData([]);
    initialPageLoaded.current = false;
    currentUrl.current = url;
    setTotalCount(0);
    offset.current = 0;
    loadItems();
    initialPageLoaded.current = true;
  };

  useEffect(() => {
    if (!!disable) {
      return;
    }

    if (
      !currentUrl.current?.includes(url) ||
      (currentUrl.current?.includes(url) &&
        currentUrl.current?.length > url.length)
    ) {
      setData([]);
      initialPageLoaded.current = false;
      currentUrl.current = url;
      setTotalCount(0);
      offset.current = 0;
      if (isLoading) {
        abortController.current?.abort();
      }
      setIsLoading(true);
    }

    if (initialPageLoaded.current) {
      return;
    }

    loadItems();
    initialPageLoaded.current = true;
  }, [url, disable]);

  return {
    data,
    totalCount,
    loading: isLoading,
    error,
    loadMore,
    loadAll,
    forceFetch,
    initialPageLoaded: initialPageLoaded.current,
  };
};
