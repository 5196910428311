import { FC, useEffect, useState } from 'react';
import { getTaskTextView, populateTaskTextView } from 'src/api/cartTasks';
import { useFetch } from 'src/hooks';
import { authFetch } from 'src/utils';

import { LogView } from '../LogView';
import { ReloadOutlined } from '@ant-design/icons';

export const TaskTextView: FC<{
  selectedTask?: string;
  payload?: Record<string, any>;
}> = ({ selectedTask, payload }) => {
  const [results, setResults] = useState('');
  const { data: text_view_name } = useFetch<string>(
    selectedTask ? getTaskTextView(selectedTask).url : undefined
  );

  const doFetch = async () => {
    if (!selectedTask || !text_view_name) {
      return;
    }

    const result = await authFetch<any>(
      populateTaskTextView(selectedTask, { ...(payload || {}), text_view_name })
    );
    setResults(result);
  };

  const hasTextView = !!text_view_name;

  useEffect(() => {
    doFetch();
  }, [text_view_name]);

  if (!selectedTask || !hasTextView || !results) {
    return null;
  }

  return (
    <LogView
      content={results}
      style={{ marginLeft: '8px', paddingTop: '16px', flexBasis: '50%' }}
      customButton={{
        title: 'Re-run',
        icon: <ReloadOutlined />,
        onClick: doFetch,
      }}
    />
  );
};
