import { getUserTypeCarts } from 'src/api/cart';

import { BiobankType } from '../types';
import { useFetch } from './useFetch';
import { useUser } from './useUser';

export const useUserCarts = (type?: BiobankType) => {
  const { id } = useUser() || {};
  const { url } = getUserTypeCarts(id || 0, type);

  return {
    ...useFetch<{ id: number; type: BiobankType; name: string }[]>(url),
  };
};
