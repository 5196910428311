import { Tag, Tooltip } from 'antd';
import { ReactNode } from 'react';

import { BiobankType } from '../../types';
import { FIELD_TYPES, FieldValue, TableColumnSchemaType } from './types';

export const BACKEND_TYPE_BY_TYPE = {
  [BiobankType.Foods]: 'id',
  [BiobankType.FoodTubes]: 'barcode',
  [BiobankType.PickPlates]: 'id',
  [BiobankType.Strains]: 'strain_name',
  [BiobankType.IsolationPlates]: 'id',
  [BiobankType.OutgrowthConditions]: '',
  [BiobankType.Lyo]: 'id',
  [BiobankType.LyoTubes]: 'barcode',
  [BiobankType.Picks]: '',
  [BiobankType.Genomes]: 'page',
  [BiobankType.StrainTubes]: 'id',
};

export const CART_ID_FIELD_BY_TYPE = {
  [BiobankType.Foods]: 'id',
  [BiobankType.Strains]: 'id',
  [BiobankType.StrainTubes]: 'barcode',
  [BiobankType.Genomes]: 'uuid',
};

export const NAME_FIELD_BY_TYPE = {
  [BiobankType.Foods]: 'short_name',
  [BiobankType.FoodTubes]: 'barcode',
  [BiobankType.PickPlates]: 'name',
  [BiobankType.Strains]: 'name',
  [BiobankType.IsolationPlates]: 'name',
  [BiobankType.OutgrowthConditions]: 'condition_code',
  [BiobankType.Lyo]: 'short_name',
  [BiobankType.LyoTubes]: 'barcode',
  [BiobankType.Picks]: '',
  [BiobankType.Genomes]: 'genome_assembly_id',
  [BiobankType.StrainTubes]: 'barcode',
};

export const ID_FIELD_BY_TYPE = {
  [BiobankType.Foods]: 'id',
  [BiobankType.FoodTubes]: 'barcode',
  [BiobankType.PickPlates]: 'id',
  [BiobankType.Strains]: 'name',
  [BiobankType.IsolationPlates]: 'id',
  [BiobankType.OutgrowthConditions]: 'id',
  [BiobankType.Lyo]: 'id',
  [BiobankType.LyoTubes]: 'barcode',
  [BiobankType.Picks]: '',
  [BiobankType.Genomes]: 'uuid',
  [BiobankType.StrainTubes]: 'barcode',
};

export const SEARCHABLE_TYPES = [
  BiobankType.Foods,
  BiobankType.Strains,
  BiobankType.PickPlates,
  BiobankType.IsolationPlates,
  BiobankType.Lyo,
  BiobankType.LyoTubes,
  BiobankType.Genomes,
  BiobankType.StrainTubes,
];

export const BULK_UPLOAD_TYPES = [
  BiobankType.FoodTubes,
  BiobankType.Foods,
  BiobankType.PickPlates,
  BiobankType.Picks,
  BiobankType.Lyo,
  BiobankType.LyoTubes,
];

export const ADDABLE_TYPES = [
  BiobankType.Foods,
  BiobankType.FoodTubes,
  BiobankType.Strains,
  BiobankType.PickPlates,
  BiobankType.IsolationPlates,
  BiobankType.Lyo,
  BiobankType.LyoTubes,
];

export const SHOPPABLE_TYPES = [
  BiobankType.Strains,
  BiobankType.Foods,
  BiobankType.StrainTubes,
  BiobankType.Genomes,
];

export const ADD_CARD_FIELD_VALUES_BY_TYPE: Partial<
  Record<BiobankType, FieldValue[]>
> = {
  [BiobankType.Foods]: [
    {
      title: 'High Level Substrates',
      field: 'high_level_substrate',
      type: FIELD_TYPES.TypeaheadTag,
      typeaheadType: 'high_level_substrates',
      tagFieldNames: ['name'],
    },
    {
      title: 'Substrate Descriptors',
      field: 'substrate_descriptors',
      type: FIELD_TYPES.TypeaheadTag,
      typeaheadType: 'substrate_descriptors',
      tagFieldNames: ['description'],
    },
    {
      title: 'High Level Products',
      field: 'high_level_product',
      type: FIELD_TYPES.TypeaheadTag,
      typeaheadType: 'high_level_products',
      tagFieldNames: ['name'],
    },
    {
      title: 'Product Descriptors',
      field: 'product_descriptors',
      type: FIELD_TYPES.TypeaheadTag,
      typeaheadType: 'product_descriptors',
      tagFieldNames: ['description'],
    },
    {
      title: 'Short Name',
      field: 'short_name',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Descriptive Name',
      field: 'descriptive_name',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Source',
      field: 'production_source',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Retail Source',
      field: 'retail_source',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Notes',
      field: 'notes',
      type: FIELD_TYPES.Textarea,
    },
    {
      title: 'Is Vegan',
      field: 'is_vegan',
      type: FIELD_TYPES.Boolean,
    },
    {
      title: 'Is Alcoholic',
      field: 'is_alcoholic',
      type: FIELD_TYPES.Boolean,
    },
    {
      title: 'Is Starter',
      field: 'is_starter',
      type: FIELD_TYPES.Boolean,
    },
    {
      title: 'CFUs/mL',
      field: 'cfus_per_ml',
      type: FIELD_TYPES.Number,
      required: true,
      defaultValue: 0,
    },
    {
      title: 'Collection Date',
      field: 'collection_date',
      type: FIELD_TYPES.Date,
    },
    {
      title: 'Date Guessed?',
      field: 'collection_date_is_guessed',
      type: FIELD_TYPES.Boolean,
    },
  ],
  [BiobankType.PickPlates]: [
    {
      title: 'Source Tube',
      field: 'culture_source_tube',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.FoodTubes,
    },
    {
      title: 'Source Food',
      field: 'culture_source_tube.culture_source.food',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.Foods,
    },
    {
      title: 'Growth Conditions',
      field: 'culture_conditions',
      type: FIELD_TYPES.Tags,
      tagOptions: { useColors: false },
      tagFieldNames: ['condition'],
    },
    {
      title: 'Isolated Strains',
      field: 'strains',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.Strains,
    },
  ],
  [BiobankType.Strains]: [
    {
      field: 'source_food.food',
      title: 'Source Food',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.Foods,
    },
    {
      field: 'source_plate',
      title: 'Source Plate',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.PickPlates,
    },
    {
      field: 'strain_tube',
      title: 'Main Strain Tube',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.FoodTubes,
    },
    {
      field: 'accessible_strain_tubes',
      title: 'Accessible Tubes',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.FoodTubes,
    },
    {
      field: 'otu_type',
      title: 'OTU Type',
      type: FIELD_TYPES.Hardcoded,
    },
    {
      field: 'otu_sequence',
      title: 'OTU Sequence',
      type: FIELD_TYPES.Hardcoded,
    },
    {
      field: 'otu_classification.genus',
      title: 'OTU Classification',
      type: FIELD_TYPES.Hardcoded,
    },
    {
      field: 'strain_gtdbtk.classification',
      title: 'Gtdbtk Classification',
      type: FIELD_TYPES.Hardcoded,
    },
    // {
    //   field: 'gene_level_concern',
    //   title: 'Gene Level Concern',
    //   type: FIELD_TYPES.Hardcoded,
    // },
    // {
    //   field: 'taxonomic_concern',
    //   title: 'Taxonomic Concern',
    //   type: FIELD_TYPES.Hardcoded,
    // },
  ],
  [BiobankType.FoodTubes]: [
    { title: 'Barcode', field: 'barcode', type: FIELD_TYPES.SingleText },
    { title: 'Location', field: 'location', type: FIELD_TYPES.SingleText },
    { title: 'Well', field: 'well', type: FIELD_TYPES.SingleText },
    { title: 'Volume', field: 'volume', type: FIELD_TYPES.Number },
    {
      title: 'Volume Unit',
      field: 'volume_unit',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Collection Date',
      field: 'collection_date',
      type: FIELD_TYPES.Date,
    },
    {
      title: 'Culture Source',
      field: 'culture_source',
      type: FIELD_TYPES.Nested,
      nestedFieldName: 'food',
      nestedFieldType: BiobankType.Foods,
    },
  ],
  [BiobankType.Lyo]: [
    {
      title: 'Short Name',
      field: 'short_name',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Descriptive Name',
      field: 'descriptive_name',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Source',
      field: 'production_source',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Retail Source',
      field: 'retail_source',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Expected Species',
      field: 'expected_species',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Status',
      field: 'status',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Notes',
      field: 'notes',
      type: FIELD_TYPES.Textarea,
    },
  ],
  [BiobankType.LyoTubes]: [
    {
      title: 'Source Name',
      field: 'source_name',
      type: FIELD_TYPES.SingleText,
      required: true,
    },
    {
      title: 'Barcode',
      field: 'barcode',
      type: FIELD_TYPES.SingleText,
      required: true,
    },
    { title: 'Location', field: 'location', type: FIELD_TYPES.SingleText },
    { title: 'Well', field: 'well', type: FIELD_TYPES.SingleText },
    {
      title: 'Volume',
      field: 'volume',
      type: FIELD_TYPES.Number,
      required: true,
      defaultValue: 0,
    },
    {
      title: 'Volume Unit',
      field: 'volume_unit',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Collection Date',
      field: 'collection_date',
      type: FIELD_TYPES.Date,
    },
  ],
};

export const VIEW_CARD_FIELD_VALUES_BY_TYPE: Record<
  keyof typeof NAME_FIELD_BY_TYPE,
  FieldValue[]
> = {
  [BiobankType.Foods]: [
    {
      title: 'Glycerol Stocks',
      field: 'tubes',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.FoodTubes,
    },
    {
      title: 'Related Plates',
      field: 'pick_plates',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.PickPlates,
    },
    {
      title: 'Isolated Strains',
      field: 'food.defined_strains',
      type: FIELD_TYPES.Tags,
      tagFieldNames: ['id', 'name'],
    },
  ],
  [BiobankType.FoodTubes]: [
    { title: 'Barcode', field: 'barcode', type: FIELD_TYPES.SingleText },
    { title: 'Location', field: 'location', type: FIELD_TYPES.SingleText },
    { title: 'Well', field: 'well', type: FIELD_TYPES.SingleText },
    { title: 'Volume', field: 'volume', type: FIELD_TYPES.Number },
    {
      title: 'Volume Unit',
      field: 'volume_unit',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Collection Date',
      field: 'collection_date',
      type: FIELD_TYPES.Date,
    },
    {
      title: 'Culture Source',
      field: 'culture_source',
      type: FIELD_TYPES.Nested,
      nestedFieldName: 'food',
      nestedFieldType: BiobankType.Foods,
    },
  ],
  [BiobankType.PickPlates]: [
    {
      title: 'Source Tube',
      field: 'pick_plate.culture_source_tube',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.FoodTubes,
    },
    {
      title: 'Source Food',
      field: 'pick_plate.culture_source_tube.culture_source.food',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.Foods,
    },
    {
      title: 'Growth Conditions',
      field: 'pick_plate.culture_conditions',
      type: FIELD_TYPES.Tags,
      tagOptions: { useColors: false },
      tagFieldNames: ['condition'],
    },
    {
      title: 'Isolated Strains',
      field: 'strains',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.Strains,
    },
  ],
  [BiobankType.Strains]: [
    {
      field: 'source_food.food',
      title: 'Source Food',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.Foods,
    },
    {
      field: 'source_plate',
      title: 'Source Plate',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.PickPlates,
    },
    {
      field: 'strain_tube',
      title: 'Main Strain Tube',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.FoodTubes,
    },
    {
      field: 'accessible_strain_tubes',
      title: 'Accessible Tubes',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.FoodTubes,
    },
    {
      field: 'best_assembly',
      title: 'Best Assembly',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.Genomes,
    },
    {
      field: 'external_code',
      title: 'External Code',
      type: FIELD_TYPES.Hardcoded,
    },
    {
      field: 'otu_type',
      title: 'OTU Type',
      type: FIELD_TYPES.Hardcoded,
    },
    {
      field: 'otu_sequence',
      title: 'OTU Sequence',
      type: FIELD_TYPES.Hardcoded,
    },
    {
      field: 'otu_classification.genus',
      title: 'OTU Classification',
      type: FIELD_TYPES.Hardcoded,
      style: {
        fontStyle: 'italic',
      },
    },
    {
      field: 'strain_gtdbtk.classification',
      title: 'Gtdbtk Classification',
      type: FIELD_TYPES.Hardcoded,
    },
    {
      field: 'render_notes',
      title: 'Render Notes',
      type: FIELD_TYPES.Hardcoded,
      hideFieldTitle: true,
      style: {
        fontStyle: 'italic',
      },
    },
  ],
  [BiobankType.IsolationPlates]: [
    {
      field: 'strains',
      title: 'Strains',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.Strains,
    },
    {
      field: 'related_plates',
      title: 'Related Plates',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.IsolationPlates,
    },
    {
      field: 'sources',
      title: 'Sources',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.PickPlates,
    },
  ],
  [BiobankType.OutgrowthConditions]: [
    { field: 'condition', title: 'Condition', type: FIELD_TYPES.SingleText },
    {
      field: 'condition_code',
      title: 'Condition Code',
      type: FIELD_TYPES.SingleText,
    },
  ],
  [BiobankType.Lyo]: [],
  [BiobankType.LyoTubes]: [
    { title: 'Barcode', field: 'barcode', type: FIELD_TYPES.SingleText },
    { title: 'Location', field: 'location', type: FIELD_TYPES.SingleText },
    { title: 'Well', field: 'well', type: FIELD_TYPES.SingleText },
    { title: 'Volume', field: 'volume', type: FIELD_TYPES.Number },
    {
      title: 'Volume Unit',
      field: 'volume_unit',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Collection Date',
      field: 'collection_date',
      type: FIELD_TYPES.Date,
    },
    {
      title: 'Culture Source',
      field: 'culture_source',
      type: FIELD_TYPES.Nested,
      nestedFieldName: 'food',
      nestedFieldType: BiobankType.Foods,
    },
  ],
  [BiobankType.Picks]: [],
  [BiobankType.Genomes]: [
    {
      title: 'Strain Name',
      field: 'strain_name',
      type: FIELD_TYPES.NestedByName,
      nestedFieldType: BiobankType.Strains,
    },
    {
      title: 'N50',
      field: 'n50',
      type: FIELD_TYPES.Hardcoded,
    },
    {
      title: '# Contigs',
      field: 'num_contigs',
      type: FIELD_TYPES.Hardcoded,
    },
    {
      title: '# Proteins',
      field: 'num_proteins',
      type: FIELD_TYPES.Hardcoded,
    },
    {
      title: 'sum_length',
      field: 'sum_length',
      type: FIELD_TYPES.Hardcoded,
    },
    {
      title: 'Render Notes',
      field: 'render_notes',
      type: FIELD_TYPES.Hardcoded,
    },
    {
      title: 'Assembly Run',
      field: 'assembly_run.name',
      type: FIELD_TYPES.Hardcoded,
    },
  ],
  [BiobankType.StrainTubes]: [],
};

export const VIEW_ITEM_FIELD_VALUES_BY_TYPE: Partial<
  Record<BiobankType, FieldValue[]>
> = {
  [BiobankType.Foods]: [
    {
      title: 'High Level Substrates',
      field: 'high_level_substrate',
      type: FIELD_TYPES.TypeaheadTag,
      typeaheadType: 'high_level_substrates',
      tagFieldNames: ['name'],
    },
    {
      title: 'Substrate Descriptors',
      field: 'substrate_descriptors',
      type: FIELD_TYPES.TypeaheadTag,
      typeaheadType: 'substrate_descriptors',
      tagFieldNames: ['description'],
    },
    {
      title: 'High Level Products',
      field: 'high_level_product',
      type: FIELD_TYPES.TypeaheadTag,
      typeaheadType: 'high_level_products',
      tagFieldNames: ['name'],
    },
    {
      title: 'Product Descriptors',
      field: 'product_descriptors',
      type: FIELD_TYPES.TypeaheadTag,
      typeaheadType: 'product_descriptors',
      tagFieldNames: ['description'],
    },
    {
      title: 'Short Name',
      field: 'short_name',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Descriptive Name',
      field: 'descriptive_name',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Source',
      field: 'production_source',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Retail Source',
      field: 'retail_source',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Notes',
      field: 'notes',
      type: FIELD_TYPES.Textarea,
    },
    {
      title: 'Is Vegan',
      field: 'is_vegan',
      type: FIELD_TYPES.Boolean,
    },
    {
      title: 'Is Alcoholic',
      field: 'is_alcoholic',
      type: FIELD_TYPES.Boolean,
    },
    {
      title: 'Is Starter',
      field: 'is_starter',
      type: FIELD_TYPES.Boolean,
    },
    {
      title: 'CFUs/mL',
      field: 'cfus_per_ml',
      type: FIELD_TYPES.Number,
      required: true,
      defaultValue: 0,
    },
    {
      title: 'Collection Date',
      field: 'collection_date',
      type: FIELD_TYPES.Date,
    },
    {
      title: 'Date Guessed?',
      field: 'collection_date_is_guessed',
      type: FIELD_TYPES.Boolean,
    },
  ],
  [BiobankType.PickPlates]: [
    {
      title: 'Source Tube',
      field: 'culture_source_tube',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.FoodTubes,
    },
    {
      title: 'Source Food',
      field: 'culture_source_tube.culture_source.food',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.Foods,
    },
    {
      title: 'Growth Conditions',
      field: 'culture_conditions',
      type: FIELD_TYPES.Tags,
      tagOptions: { useColors: false },
      tagFieldNames: ['condition'],
    },
    {
      title: 'Isolated Strains',
      field: 'strains',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.Strains,
    },
  ],
  [BiobankType.Strains]: [
    {
      field: 'source_food.food',
      title: 'Source Food',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.Foods,
    },
    {
      field: 'source_plate',
      title: 'Source Plate',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.PickPlates,
    },
    {
      field: 'strain_tube',
      title: 'Main Strain Tube',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.FoodTubes,
    },
    {
      field: 'accessible_strain_tubes',
      title: 'Accessible Tubes',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.FoodTubes,
    },
    {
      title: 'Best Assembly',
      field: 'best_assembly',
      type: FIELD_TYPES.Nested,
      nestedFieldType: BiobankType.Genomes,
    },
    {
      field: 'external_code',
      title: 'External Code',
      type: FIELD_TYPES.Hardcoded,
    },
    {
      field: 'otu_type',
      title: 'OTU Type',
      type: FIELD_TYPES.Hardcoded,
    },
    {
      field: 'otu_sequence',
      title: 'OTU Sequence',
      type: FIELD_TYPES.Hardcoded,
    },
    {
      field: 'otu_classification.genus',
      title: 'OTU Classification',
      type: FIELD_TYPES.Hardcoded,
    },
    {
      field: 'strain_gtdbtk.classification',
      title: 'Gtdbtk Classification',
      type: FIELD_TYPES.Hardcoded,
    },
  ],
  [BiobankType.FoodTubes]: [
    { title: 'Barcode', field: 'barcode', type: FIELD_TYPES.SingleText },
    { title: 'Location', field: 'location', type: FIELD_TYPES.SingleText },
    { title: 'Well', field: 'well', type: FIELD_TYPES.SingleText },
    { title: 'Volume', field: 'volume', type: FIELD_TYPES.Number },
    {
      title: 'Volume Unit',
      field: 'volume_unit',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Collection Date',
      field: 'collection_date',
      type: FIELD_TYPES.Date,
    },
    {
      title: 'Culture Source',
      field: 'culture_source',
      type: FIELD_TYPES.Nested,
      nestedFieldName: 'food',
      nestedFieldType: BiobankType.Foods,
    },
  ],
  [BiobankType.Lyo]: [
    {
      title: 'Short Name',
      field: 'short_name',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Descriptive Name',
      field: 'descriptive_name',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Source',
      field: 'production_source',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Retail Source',
      field: 'retail_source',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Expected Species',
      field: 'expected_species',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Status',
      field: 'status',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Notes',
      field: 'notes',
      type: FIELD_TYPES.Textarea,
    },
  ],
  [BiobankType.LyoTubes]: [
    { title: 'Barcode', field: 'barcode', type: FIELD_TYPES.SingleText },
    { title: 'Location', field: 'location', type: FIELD_TYPES.SingleText },
    { title: 'Well', field: 'well', type: FIELD_TYPES.SingleText },
    {
      title: 'Volume',
      field: 'volume',
      type: FIELD_TYPES.Number,
      required: true,
      defaultValue: 0,
    },
    {
      title: 'Volume Unit',
      field: 'volume_unit',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Collection Date',
      field: 'collection_date',
      type: FIELD_TYPES.Date,
    },
  ],
  [BiobankType.Picks]: [],
  [BiobankType.Genomes]: [
    {
      title: 'Assembly Run',
      field: 'assembly_run.name',
      type: FIELD_TYPES.Hardcoded,
    },
    {
      title: 'n50',
      field: 'n50',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'num_contigs',
      field: 'num_contigs',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'num_proteins',
      field: 'num_proteins',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'render_notes',
      field: 'render_notes',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Strain',
      field: 'strain_name',
      type: FIELD_TYPES.NestedByName,
      nestedFieldType: BiobankType.Strains,
    },
    {
      title: 'sum_length',
      field: 'sum_length',
      type: FIELD_TYPES.SingleText,
    },
    {
      title: 'Genome',
      field: 'genome_s3',
      type: FIELD_TYPES.DownloadURL,
    },
    {
      title: 'GFF',
      field: 'gff_s3',
      type: FIELD_TYPES.DownloadURL,
    },
    {
      title: 'Protein',
      field: 'protein_s3',
      type: FIELD_TYPES.DownloadURL,
    },
  ],
};

export const DISALLOW_LIST_VIEW_LIST = [BiobankType.Picks];

export const ITEM_CARD_ID_FIELD_BY_TYPE = {
  [BiobankType.Foods]: 'short_name',
  [BiobankType.FoodTubes]: 'barcode',
  [BiobankType.PickPlates]: 'name',
  [BiobankType.IsolationPlates]: 'name',
  [BiobankType.Strains]: 'name',
  [BiobankType.OutgrowthConditions]: '',
  [BiobankType.Lyo]: 'short_name',
  [BiobankType.LyoTubes]: 'barcode',
  [BiobankType.Picks]: '',
  [BiobankType.Genomes]: 'uuid',
};

export const ALL_BULK_PICKER_OPTIONS = [
  { value: BiobankType.Foods, label: 'Foods' },
  { value: BiobankType.FoodTubes, label: 'Food Tubes' },
  { value: BiobankType.Lyo, label: 'Lyo' },
  { value: BiobankType.LyoTubes, label: 'Lyo Tubes' },
  { value: BiobankType.Picks, label: 'Picks' },
  { value: BiobankType.PickPlates, label: 'Pick Plates' },
  { value: BiobankType.Genomes, label: 'Genomes' },
];

export const BIOBANK_TYPE_NAME = {
  [BiobankType.Foods]: 'Food',
  [BiobankType.FoodTubes]: 'Food Tube',
  [BiobankType.Strains]: 'Strain',
  [BiobankType.PickPlates]: 'Pick Plate',
  [BiobankType.OutgrowthConditions]: 'Outgrowth Condition',
  [BiobankType.IsolationPlates]: 'Isolation Plate',
  [BiobankType.Lyo]: 'Lyo',
  [BiobankType.LyoTubes]: 'Lyo Tube',
  [BiobankType.Picks]: 'Pick',
  [BiobankType.Genomes]: 'Genome',
  [BiobankType.StrainTubes]: 'Strain Tube',
};

export const TABLE_COLUMN_DISPLAY_FORMATTERS_BY_TYPE: Record<
  TableColumnSchemaType,
  (schema_name: string) => (props: any) => ReactNode
> = {
  [TableColumnSchemaType.ColonyIsolationPlateSetSchemaOut]:
    (schema_name: string) => (props: any) =>
      <span>{props.row[schema_name].name}</span>,
  [TableColumnSchemaType.CultureOutgrowthConditionSchemaOut]:
    (schema_name: string) => (props: any) =>
      (
        <Tooltip title={props.row[schema_name].condition}>
          <Tag>{props.row[schema_name].condition_code}</Tag>
        </Tooltip>
      ),
  [TableColumnSchemaType.CultureSourceSchemaOut]:
    (schema_name: string) => (props: any) =>
      (
        <span>
          {props.row[schema_name]?.food?.descriptive_name} (
          {props.row[schema_name]?.food?.short_name})
        </span>
      ),
  [TableColumnSchemaType.CultureSourceTubeSchemaOut]:
    (schema_name: string) => (props: any) => {
      const data = props.row[schema_name];

      if (!data || (!data.barcode && !data.culture_source)) {
        return null;
      }

      return (
        <>
          {data.barcode}{' '}
          {data.culture_source?.food ? (
            <span>
              {data.culture_source?.food?.descriptive_name} (
              {data.culture_source?.food?.short_name})
            </span>
          ) : null}
        </>
      );
    },
  [TableColumnSchemaType.DefinedStrainSchemaOut]:
    (schema_name: string) => (props: any) =>
      <Tag>{props.row[schema_name].name}</Tag>,
  [TableColumnSchemaType.HighLevelProductSchemaOut]:
    (schema_name: string) => (props: any) =>
      <Tag>{props.row[schema_name].name}</Tag>,
  [TableColumnSchemaType.HighLevelSubstrateSchemaOut]:
    (schema_name: string) => (props: any) =>
      <Tag>{props.row[schema_name].name}</Tag>,
  [TableColumnSchemaType.ProductDescriptorSchemaOut]:
    (schema_name: string) => (props: any) =>
      <Tag>{props.row[schema_name].description}</Tag>,
  [TableColumnSchemaType.SubstrateDescriptorSchemaOut]:
    (schema_name: string) => (props: any) =>
      <Tag>{props.row[schema_name].description}</Tag>,
  [TableColumnSchemaType.StrainSchemaOut]:
    (schema_name: string) => (props: any) =>
      <Tag>{props.row[schema_name].name}</Tag>,
  [TableColumnSchemaType.UserSchema]: (schema_name: string) => (props: any) =>
    (
      <span>
        {props.row[schema_name]?.email ||
          props.row[schema_name]?.first_name ||
          ''}
      </span>
    ),
  [TableColumnSchemaType.Boolean]: (schema_name: string) => (props: any) =>
    <span>{!!props.row[schema_name] ? '✓' : ''}</span>,
  [TableColumnSchemaType.Integer]: (schema_name: string) => (props: any) =>
    <span>{props.row[schema_name]}</span>,
  [TableColumnSchemaType.Number]: (schema_name: string) => (props: any) =>
    <span>{props.row[schema_name]}</span>,
  [TableColumnSchemaType.String]: (schema_name: string) => (props: any) =>
    <span>{props.row[schema_name]}</span>,
};

export const TABLE_COLUMN_DOWNLOAD_FORMATTERS_BY_TYPE: Record<
  TableColumnSchemaType,
  (item: any) => string
> = {
  [TableColumnSchemaType.ColonyIsolationPlateSetSchemaOut]: (item: any) =>
    item?.name || '',
  [TableColumnSchemaType.CultureOutgrowthConditionSchemaOut]: (item: any) =>
    item?.condition || '',
  [TableColumnSchemaType.CultureSourceSchemaOut]: (item: any) =>
    JSON.stringify(item),
  [TableColumnSchemaType.CultureSourceTubeSchemaOut]: (item: any) =>
    `${item.barcode} ${item.culture_source?.food?.descriptive_name} (${item.culture_source?.food?.short_name})`,
  [TableColumnSchemaType.DefinedStrainSchemaOut]: (item: any) =>
    item?.name || '',
  [TableColumnSchemaType.StrainSchemaOut]: (item: any) => item?.name || '',
  [TableColumnSchemaType.HighLevelProductSchemaOut]: (item: any) =>
    item?.name || '',
  [TableColumnSchemaType.HighLevelSubstrateSchemaOut]: (item: any) =>
    item?.name || '',
  [TableColumnSchemaType.ProductDescriptorSchemaOut]: (item: any) =>
    item?.description || '',
  [TableColumnSchemaType.SubstrateDescriptorSchemaOut]: (item: any) =>
    item?.description || '',
  [TableColumnSchemaType.UserSchema]: (item: any) =>
    item?.email || item?.first_name || '',
  [TableColumnSchemaType.Boolean]: (item: any) => String(item),
  [TableColumnSchemaType.Integer]: (item: any) => item,
  [TableColumnSchemaType.Number]: (item: any) => item,
  [TableColumnSchemaType.String]: (item: any) => item,
};
