import {
  CheckCircleTwoTone,
  ExperimentOutlined,
  FolderOpenOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { Button, Spin, Tabs, Tooltip } from 'antd';
import { FC, useState } from 'react';
import { getAvailableRefinementTasks, getCart } from 'src/api/cartTasks';
import { COLORS } from 'src/constants';
import { useFetch } from 'src/hooks';
import {
  AvailableTask,
  JobResultFile,
  TaskCartResult,
} from 'src/types/api/tasks';
import { compute, scrollToWindowBottom } from 'src/utils';

import { AddToCartModal } from '../biobank/AddToCartModal';
import { RunTaskModal } from '../biobank/RunTaskModal';
import { Modal } from '../Modal';
import { getResultsFilesToComponentsList } from './utils';

export const JobResultsModal: FC<{
  files: (JobResultFile & { fileBody?: string })[];
  job: {
    series_id: string;
    job_id: string;
    refine_id: string;
    task_name: string;
  };
  loading?: boolean;
  complete?: boolean;
  isEmbed?: boolean;
  hideOpenJob?: boolean;
  hasCart?: boolean;
}> = ({ files, job, loading, complete, isEmbed, hideOpenJob, hasCart }) => {
  const [isAddToCartModalOpen, setIsAddToCartModalOpen] = useState(false);
  const [isRefineModalOpen, setIsRefineModalOpen] = useState(false);

  const { data: availableRefinementTasks } = useFetch<AvailableTask[]>(
    getAvailableRefinementTasks(job.task_name).url
  );

  const { data: availableCart, error } = useFetch<TaskCartResult>(
    complete && hasCart
      ? getCart({
          seriesId: job.series_id,
          jobId: job.job_id,
          refineId: job.refine_id,
        }).url
      : undefined
  );

  const hasAvailableRefinements = !!availableRefinementTasks?.length;

  const hasAvailableCart = !!(
    availableCart &&
    availableCart.success &&
    availableCart.list_of_ids.length
  );

  const unavailableCartMessage = compute(() => {
    if (error) {
      return 'Unknown error';
    }

    if (!availableCart) {
      return 'Loading';
    }

    if (!availableCart.success && availableCart.message) {
      return availableCart.message;
    }

    if (!availableCart.success) {
      return 'Unknown error';
    }

    if (!availableCart.list_of_ids.length) {
      return 'No IDs returned';
    }

    return '';
  });

  const items = [
    ...getResultsFilesToComponentsList(files),
    {
      key: '___indicator',
      disabled: true,
      label: loading ? (
        <Spin size="small" />
      ) : complete ? (
        <CheckCircleTwoTone
          twoToneColor={COLORS.brand.green}
          style={{ marginRight: 0 }}
        />
      ) : null,
    },
  ];

  const tabBarButtons = compute(() => {
    const items = [
      <Button size="small" href={`/jobs/${job.series_id}`} target="_blank">
        Series <FolderOpenOutlined />
      </Button>,
    ];

    if (!hideOpenJob) {
      items.push(
        <Button
          size="small"
          href={`/jobs/${job.series_id}/${job.job_id}/${job.refine_id}`}
          target="_blank"
        >
          Job <FolderOpenOutlined />
        </Button>
      );
    }

    if (hasAvailableRefinements) {
      items.push(
        <Button size="small" onClick={() => setIsRefineModalOpen(true)}>
          Refine Mode <ExperimentOutlined />
        </Button>
      );
    }

    if (hasCart) {
      items.push(
        <Button
          size="small"
          onClick={() => {
            setIsAddToCartModalOpen(true);
          }}
          disabled={!hasAvailableCart}
        >
          <Tooltip title={unavailableCartMessage}>
            Cart <ShoppingCartOutlined />
          </Tooltip>
        </Button>
      );
    }

    return items;
  });

  return (
    <>
      <div style={isEmbed ? undefined : { minHeight: '60vh' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}></div>
        <Tabs
          size="small"
          animated={false}
          items={items}
          onChange={scrollToWindowBottom}
          tabBarExtraContent={
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              {tabBarButtons}
            </div>
          }
        />
      </div>
      {isRefineModalOpen ? (
        <Modal onCancel={() => setIsRefineModalOpen(false)}>
          <RunTaskModal originalJob={job} />
        </Modal>
      ) : null}
      {isAddToCartModalOpen && availableCart ? (
        <AddToCartModal
          open
          idOnlyMode
          onCancel={() => setIsAddToCartModalOpen(false)}
          getSelectedRows={() => availableCart.list_of_ids}
          type={availableCart.cart_type}
        />
      ) : null}
    </>
  );
};
