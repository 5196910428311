import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox as AntCheckbox,
  Input as AntInput,
  message,
  Tooltip,
} from 'antd';
import { FC, Fragment, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useInputFields } from 'src/hooks/useInputFields';
import styled from 'styled-components';

import { addBiobankEntity, validateBiobankEntity } from '../../../api';
import { useUser } from '../../../hooks';
import { BiobankType, InputFieldType, RelatedType } from '../../../types';
import { authFetch } from '../../../utils';
import { Modal } from '../../Modal';
import { FieldTitle } from '../../typography';
import { BIOBANK_TYPE_NAME, ID_FIELD_BY_TYPE } from '../constants';
import { ForeignSelect } from './ForeignSelect';
import { TypeaheadTag } from './TypeaheadTag';

interface Props {
  itemType: BiobankType;
  isSingleRoute?: boolean;
  onClose?: () => void;
}

const MANY_TO_MANY_TYPE_MAPPINGS = {
  [RelatedType.HighLevelProduct]: 'high_level_products',
  [RelatedType.HighLevelSubstrate]: 'high_level_substrates',
  [RelatedType.SubstrateDescriptor]: 'substrate_descriptors',
  [RelatedType.ProductDescriptor]: 'product_descriptors',
  [RelatedType.CultureOutgrowthCondition]: 'outgrowth_conditions',
  [RelatedType.FoodSpecies]: 'food_species',
};

const FOREIGN_TYPE_MAPPINGS = {
  [RelatedType.CultureSourceTube]: 'food_tubes',
  [RelatedType.CultureSource]: 'culture_sources',
};

export const AddCard: FC<Props> = ({
  itemType,
  onClose = () => {},
  isSingleRoute = false,
}) => {
  const { rawInputFields, defaultValues } = useInputFields(itemType);
  const { extra_instructions: extraInstructions, fields: inputFields = [] } =
    rawInputFields || {};

  const user = useUser();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid },
  } = useForm({ mode: 'onBlur' });

  useEffect(() => {
    if (defaultValues) {
      for (const fieldName of Object.keys(defaultValues)) {
        setValue(fieldName, defaultValues[fieldName]);
      }
    }
  }, [defaultValues]);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const createItem = async (data: any) =>
    await authFetch(addBiobankEntity(itemType, [data]));

  const validateItem = async (data: any) =>
    await authFetch(validateBiobankEntity(itemType, [data]));

  const onSubmit = async (newItem) => {
    setIsLoading(true);
    const isCreatingFood = itemType === BiobankType.Foods;

    try {
      const missingBools = inputFields.reduce((acc, cur) => {
        if (cur.type === InputFieldType.Boolean && !newItem[cur.schema_name]) {
          return { ...acc, [cur.schema_name]: false };
        }
        return acc;
      }, {});

      const itemData = {
        ...newItem,
        ...missingBools,
        ...(isCreatingFood ? { submitter_email: user?.email } : {}),
      };

      const validateRes = await validateItem(itemData);

      if (!validateRes) {
        throw new Error('Backend validation failed');
      }

      const createRes = await createItem(itemData);

      if (isSingleRoute) {
        const [item] = createRes;
        const itemId = item[ID_FIELD_BY_TYPE[itemType]];
        message.success(`Created ${itemId}`);
        navigate(`/biobank/${itemType}/${itemId}`);
      } else {
        onClose();
      }
    } catch (e) {
      console.error(e);
      message.error(<span style={{ whiteSpace: 'pre-wrap' }}>{`${e}`}</span>);
    } finally {
      setIsLoading(false);
    }
  };

  const headerContent = (
    <>
      <div>New {BIOBANK_TYPE_NAME[itemType]}</div>
      <ControlsWrapper>
        {isLoading ? <LoadingOutlined style={{ fontSize: 18 }} /> : null}
        {isSingleRoute ? null : <CloseOutlined onClick={onClose} />}
      </ControlsWrapper>
    </>
  );

  const footerContent = (
    <>
      <div />
      <ControlsWrapper>
        <Button type="primary" disabled={!isValid} htmlType="submit">
          Submit
        </Button>
      </ControlsWrapper>
    </>
  );

  const bodyContent = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Body isSingleRoute={isSingleRoute}>
        {extraInstructions ? (
          <FieldTitle style={{ fontWeight: 'bold' }}>
            {extraInstructions}
          </FieldTitle>
        ) : null}
        <Group>
          {inputFields.map((field) => {
            const {
              display_name,
              schema_name,
              related_type,
              explanation,
              placeholder,
              required,
              type,
              default: defaultValue,
            } = field;

            let InputComponent: any = AntInput;
            const inputProps: Record<string, any> = {
              defaultValue,
              placeholder,
              size: 'small',
              required,
            };

            switch (type) {
              case InputFieldType.Boolean:
                InputComponent = AntCheckbox;
                if (!inputProps.defaultValue) {
                  inputProps.defaultValue = false;
                }
                delete inputProps.required;
                break;
              case InputFieldType.Decimal:
                inputProps.type = 'number';
                break;
              case InputFieldType.Date:
                const formattedDate = (defaultValue || '').match(
                  /^\d{4}-\d{2}-\d{2}/g
                );
                if (formattedDate?.[0]) {
                  inputProps.defaultValue = formattedDate[0];
                }
                inputProps.type = 'date';
                break;
              case InputFieldType.ManyToMany:
                InputComponent = TypeaheadTag;
                inputProps.itemType = MANY_TO_MANY_TYPE_MAPPINGS[related_type];
                delete inputProps.defaultValue;
                break;
              case InputFieldType.Foreign:
                InputComponent = ForeignSelect;
                inputProps.itemType = FOREIGN_TYPE_MAPPINGS[related_type];
                delete inputProps.defaultValue;
                break;
            }

            return (
              <Fragment key={display_name}>
                <FieldTitle style={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip
                    title={explanation}
                    placement="right"
                    arrow={{ pointAtCenter: true }}
                  >
                    {`${display_name}${required ? '*' : ''}`}
                  </Tooltip>
                </FieldTitle>
                <InputWrapper>
                  <Controller
                    control={control}
                    name={schema_name}
                    render={({ field }) => (
                      <InputComponent {...inputProps} {...field} />
                    )}
                    rules={{
                      required:
                        type === InputFieldType.Boolean ? undefined : required,
                    }}
                  />
                </InputWrapper>
              </Fragment>
            );
          })}
        </Group>
      </Body>
      <Header borderless>{footerContent}</Header>
    </form>
  );

  if (isSingleRoute) {
    return (
      <BodyWrapper>
        <Header>{headerContent}</Header>
        {bodyContent}
      </BodyWrapper>
    );
  }

  return (
    <Modal onCancel={onClose} header={headerContent}>
      {bodyContent}
    </Modal>
  );
};

const BodyWrapper = styled.div`
  max-width: 80vw;
  margin: 0 auto;
`;

const Header = styled.div<{ borderless?: boolean }>`
  ${({ borderless }) =>
    borderless ? '' : 'border-bottom: 1px solid lightgrey;'}
  font-size: 14px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Body = styled.div<{ isSingleRoute?: boolean }>`
  ${({ isSingleRoute }) => (isSingleRoute ? 'padding: 16px 64px;' : '')}
  display: flex;
  flex-direction: column;
`;

const Group = styled.div`
  padding: 16px 0px;
  display: grid;
  grid-template-columns: fit-content(200px) auto;
  row-gap: 16px;
  column-gap: 16px;
`;

const InputWrapper = styled.div`
  > * {
    width: 100%;
  }
  > input[type='checkbox'] {
    width: auto;
  }

  &&&& .ant-select-selection-placeholder,
  &&&& input::placeholder {
    font-style: italic !important;
    color: rgba(1, 1, 1, 0.4);
  }
`;

const ControlsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;
