import { Button } from 'antd';
import { FC, useState } from 'react';
import { API_ROUTES } from 'src/constants';
import { authFetch } from 'src/utils';

import { LogView } from '../LogView';
import { Modal } from '../Modal';

export const TroubleshootButton: FC<{
  series_id: string;
  job_id: string;
  refine_id: string;
  task_name: string;
}> = ({ series_id, job_id, refine_id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [results, setResults] = useState('');

  const doTroubleshoot = async () => {
    setIsLoadingResults(true);
    try {
      const troubleshootResults = await authFetch({
        url: API_ROUTES.tasks.troubleshootJob(series_id, job_id, refine_id),
      });
      setResults(troubleshootResults);
      setIsModalOpen(true);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingResults(false);
    }
  };

  return (
    <>
      {isModalOpen ? (
        <Modal onCancel={() => setIsModalOpen(false)} width="100vw">
          <LogView content={results} />
        </Modal>
      ) : null}
      <Button onClick={doTroubleshoot} loading={isLoadingResults}>
        Troubleshoot
      </Button>
    </>
  );
};
