import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CopyOutlined,
  ExclamationCircleFilled,
  InsertRowAboveOutlined,
  LoadingOutlined,
  PaperClipOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Alert, Button, Spin, Tag, Tooltip, Upload } from 'antd';
import { FC, RefObject, useEffect, useRef, useState } from 'react';
import DataGrid, { DataGridHandle } from 'react-data-grid';
import { GlobalHotKeys } from 'react-hotkeys';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ControlsRow } from '../components/layout';
import { API_ROUTES } from '../constants';
import { useDocumentTitle } from '../hooks';
import { HTTPMethod } from '../types';
import { authFetch, getDataUri, getUserToken, humanize } from '../utils';

enum FileStatus {
  IsValid = 0,
  IsValidWithIssues = 1,
  IsInvalid = 2,
}

enum UploadStatus {
  Unstarted = 0,
  InProgress = 1,
  Success = 2,
  Failed = 3,
}

export const BiobankBulkUpload: FC = () => {
  const { type } = useParams<{ type: keyof typeof DEFAULT_COLUMNS }>();
  const token = getUserToken();

  const [isLoading, setIsLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(
    UploadStatus.Unstarted
  );
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [fileContents, setFileContents] =
    useState<string | undefined>(undefined);
  const [_fileType, setFileType] = useState<string | undefined>(undefined);
  const [parsedColumns, setParsedColumns] = useState<any[]>([]);
  const [parsedData, setParsedData] = useState<any[]>([]);
  const [parsingResponse, setParsingResponse] =
    useState<ParsingResponse | undefined>(undefined);
  const gridRef = useRef<DataGridHandle>(null);

  const fileIsValid = parsingResponse?.file_is_valid;
  const hasRowLevelIssues = !!parsingResponse?.row_level_issues?.length;

  let fileStatus: FileStatus = FileStatus.IsValid;
  if (fileIsValid && hasRowLevelIssues) {
    fileStatus = FileStatus.IsValidWithIssues;
  } else if (!fileIsValid) {
    fileStatus = FileStatus.IsInvalid;
  }

  const uploadRoute = type ? API_ROUTES.biobank.addItem(type) : undefined;

  const parsedInputFieldName = `${
    type === 'lyo' ? type : type?.slice(0, -1)
  }_input`;
  const parsedInput = parsingResponse?.[
    parsedInputFieldName as keyof ParsingResponse
  ] as undefined | any[];
  const hasParsedInput = !!parsedInput?.length;

  const canUpload =
    fileStatus === FileStatus.IsValid && hasParsedInput && !!uploadRoute;

  useDocumentTitle(`Bulk Upload - ${humanize(type)}`);

  const resetState = () => {
    setIsLoading(false);
    setFileContents(undefined);
    setFileType(undefined);
    setParsedColumns([]);
    setParsedData([]);
    setParsingResponse(undefined);
    setFileName(undefined);
    setUploadStatus(UploadStatus.Unstarted);
  };

  useEffect(() => {
    resetState();
  }, [type]);

  const handleFile = async (info: any) => {
    if (info.file.status === 'uploading') {
      setIsLoading(true);
    } else if (info.file.status === 'done') {
      setIsLoading(false);
      const {
        file: { originFileObj, name, type: originFileType, response },
      } = info;
      setFileName(name);
      const text = await originFileObj?.text();
      const { columns, parsedData: data } = getTSVData(text, response);
      setParsingResponse(response);
      setFileContents(text);
      if (columns) {
        setParsedColumns(columns);
        setParsedData(data);
      }
      setFileType(originFileType);
    }
  };

  const handlePaste = async () => {
    setIsLoading(true);
    const clipText = await navigator.clipboard.readText();
    const data = new FormData();

    data.append(
      'in_file',
      new File([clipText], 'paste.tsv', {
        type: 'text/tab-separated-values',
      })
    );

    const response = await authFetch({
      url: API_ROUTES.biobank.validate(type!),
      options: {
        method: HTTPMethod.POST,
        data,
        omitContentType: true,
      },
    });
    setIsLoading(false);

    setFileName(' ');
    const { columns, parsedData: tsvdata } = getTSVData(clipText, response);
    setParsingResponse(response);
    setFileContents(clipText);
    if (columns) {
      setParsedColumns(columns);
      setParsedData(tsvdata);
    }
    setFileType('text/tab-separated-values');
  };

  const handleUpload = async () => {
    if (!uploadRoute) {
      return;
    }

    try {
      setUploadStatus(UploadStatus.InProgress);
      await authFetch({
        url: uploadRoute,
        options: {
          method: HTTPMethod.POST,
          data: parsedInput,
        },
      });
      setUploadStatus(UploadStatus.Success);
    } catch (e) {
      console.error(e);
      setUploadStatus(UploadStatus.Failed);
    }
  };

  const getUploadIcon = () => {
    switch (uploadStatus) {
      case UploadStatus.Unstarted:
        return <UploadOutlined />;
      case UploadStatus.InProgress:
        return <LoadingOutlined />;
      case UploadStatus.Success:
        return <CheckCircleOutlined />;
      case UploadStatus.Failed:
        return <ExclamationCircleFilled />;
    }
  };

  return (
    <GlobalHotKeys
      keyMap={{ PASTE: 'command+v', COPY: 'command+c' }}
      handlers={{ PASTE: handlePaste }}
    >
      <BodyWrapper>
        <Header>
          <ControlsRow>
            <Upload
              name="in_file"
              action={API_ROUTES.biobank.validate(type!)}
              headers={{ Authorization: `Bearer ${token}` }}
              onChange={handleFile}
              showUploadList={false}
              maxCount={1}
              beforeUpload={resetState}
            >
              <Button
                size="small"
                icon={<PaperClipOutlined />}
                style={{
                  fontSize: '12px',
                  alignItems: 'center',
                  display: 'inline-flex',
                  textDecoration: 'none',
                }}
              >
                Select TSV File
              </Button>
            </Upload>
            <Button
              size="small"
              icon={<CopyOutlined />}
              onClick={handlePaste}
              style={{
                fontSize: '12px',
                alignItems: 'center',
                display: 'inline-flex',
                textDecoration: 'none',
              }}
            >
              <Tooltip title={`⌘-V`}>Read Clipboard</Tooltip>
            </Button>
            <Button
              size="small"
              icon={<InsertRowAboveOutlined />}
              href={getTemplateDownload(type)}
              download={`${type}-template.tsv`}
              style={{
                fontSize: '12px',
                alignItems: 'center',
                display: 'inline-flex',
                textDecoration: 'none',
              }}
            >
              Download Template
            </Button>
          </ControlsRow>
          {parsedColumns.length || parsingResponse ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {isLoading ? <LoadingOutlined /> : null}
              <span style={{ fontSize: '12px' }}>{fileName}</span>
              <span style={{ marginLeft: '8px' }}>
                {
                  {
                    [FileStatus.IsValid]: (
                      <Tag>
                        <CheckCircleFilled style={{ color: 'green' }} /> Valid
                      </Tag>
                    ),
                    [FileStatus.IsValidWithIssues]: (
                      <Tag>
                        <ExclamationCircleFilled style={{ color: 'orange' }} />{' '}
                        Valid with Issues
                      </Tag>
                    ),
                    [FileStatus.IsInvalid]: (
                      <Tag>
                        <ExclamationCircleFilled style={{ color: 'red' }} />{' '}
                        Invalid
                      </Tag>
                    ),
                  }[fileStatus]
                }
              </span>
            </div>
          ) : null}
        </Header>
        {parsingResponse?.top_level_issues.length ? (
          <Alert
            message="Top level errors"
            description={parsingResponse?.top_level_issues.join('\n')}
            type="error"
            style={{ whiteSpace: 'pre-wrap', marginBottom: '8px' }}
          />
        ) : null}
        {!!fileContents ? (
          <DataGrid
            ref={gridRef}
            columns={parsedColumns}
            rows={parsedData}
            className="rdg-light"
            style={{ height: '85vh' }}
            onCellClick={(args, event) => {
              event.preventGridDefault();
            }}
          />
        ) : (
          <Spin spinning={isLoading}>
            <DataGrid
              ref={gridRef}
              columns={DEFAULT_COLUMNS[
                type as keyof typeof DEFAULT_COLUMNS
              ]?.map((c) => ({
                name: c,
                key: c,
              }))}
              rows={getEmptyRows(gridRef, type)}
              className="rdg-light"
              style={{ height: '85vh' }}
              onCellClick={(args, event) => {
                event.preventGridDefault();
              }}
            />
          </Spin>
        )}
        {!!parsingResponse ? (
          <div
            style={{
              display: 'flex',
              marginTop: '12px',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              type="primary"
              disabled={!canUpload || uploadStatus === UploadStatus.InProgress}
              icon={getUploadIcon()}
              onClick={handleUpload}
            >
              Upload{uploadStatus === UploadStatus.Success ? 'ed' : ''}
              {uploadStatus === UploadStatus.Failed ? ' failed' : ''}
            </Button>
          </div>
        ) : null}
      </BodyWrapper>
    </GlobalHotKeys>
  );
};

const BodyWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 88px);
`;

const getTSVData = (contents: string, parsingResponse: ParsingResponse) => {
  const lines = contents.split('\n');
  const header = lines.shift();
  const headerCols = header?.split('\t');

  const columns = [
    {
      name: 'Valid?',
      key: '___status',
      width: '16px',
      formatter: (props: any) => <StatusDisplayer row={props.row} />,
    },
    { name: 'Row Number', key: '___row', width: '16px', maxWidth: '32px' },
    ...(headerCols || []).map((col) => ({
      name: col.replace(/\s$/gi, '').replace(/(^"|"$)/gi, ''),
      key: col.replace(/\s$/gi, '').replace(/(^"|"$)/gi, ''),
      formatter: (props: any) => <CellDisplayer field={col} row={props.row} />,
    })),
  ];

  const parsedData = (lines || []).map((line, index) => {
    const retVal: Record<string, any> = { ___row: index + 1 };

    const issues = (parsingResponse.row_level_issues || []).find(
      (issue) => issue.row - 2 === index
    );

    retVal.___status = !!issues ? '❌' : '✅';
    retVal.___issues = issues;

    const vals = line.split('\t');
    vals.forEach((v, i) => {
      if (headerCols?.[i]) {
        retVal[headerCols?.[i]] = `${v
          .replace(/(^"|"$)/gim, '')
          .replace(/\s$/gi, '')}`;
      }
    });
    return retVal;
  });

  return { columns, parsedData };
};

const StatusDisplayer: FC<{ row: any }> = ({ row }) => {
  const hasIssues = !!row.___issues;
  if (!hasIssues) {
    return <span style={{ cursor: 'default' }}>{row.___status}</span>;
  }

  const { issues = [] } = row.___issues;
  const issueText = issues
    .map((iss: any) => `${iss.field}: ${iss.message}`)
    .join('\n\n');

  return (
    <Tooltip title={issueText} overlayInnerStyle={{ whiteSpace: 'pre-wrap' }}>
      <span style={{ cursor: 'default' }}>{row.___status}</span>
    </Tooltip>
  );
};

const CellDisplayer: FC<{ row: any; field: string }> = ({ row, field }) => {
  if (!row.___issues) {
    return row[field];
  }
  const { issues = [] } = row.___issues;
  const firstIssue = issues.find((issue: any) => issue.field === field);
  if (!firstIssue) {
    return row[field];
  }

  return (
    <Tooltip title={firstIssue.message}>
      <span style={{ fontWeight: 'bolder', color: 'red' }}>{row[field]}</span>
    </Tooltip>
  );
};

const Header = styled.div`
  margin-bottom: 12px;
  display: flex;
  gap: 12px;
  justify-content: space-between;
`;

type ParsingResponse = {
  file_is_valid: boolean;
  encoded_file: {
    columns: string[];
    data: { field: string; value: string }[][];
  };
  top_level_issues: any[];
  row_level_issues: {
    // row is off by 2?
    row: number;
    issues: { field: string; message: string }[];
  }[];
  food_tube_input?: any[];
  food_input?: any[];
};

const DEFAULT_COLUMNS = {
  lyo: [
    'short_name',
    'descriptive_name',
    'production_source',
    'retail_source',
    'expected_species',
    'notes',
    'status',
  ],
  lyo_tubes: ['Barcode', 'Lyo_ID'],
  basic_picks: [
    'tgt_plate',
    'tgt_well',
    'src_plate',
    'full_series',
    'pick_date',
  ],
  pick_plates: ['name', 'culture_source_tube', 'culture_conditions'],
  foods: [
    'short_name',
    'description',
    'production_source',
    'retail_source',
    'notes',
    'high_level_substrate',
    'high_level_product',
    'substrate_descriptors',
    'product_descriptors',
    'defined_strains',
    'is_vegan',
    'is_alcoholic',
    'is_starter',
    'cfus_per_ml',
  ],
  food_tubes: [
    'Food ID',
    'Barcode',
    'Volume',
    'Volume Unit',
    'Location',
    'Well',
    'Status',
    'Collection Date',
    'Notes',
  ],
};

const getTemplateDownload = (type?: keyof typeof DEFAULT_COLUMNS) => {
  if (!type) {
    return '';
  }
  return getDataUri(`"${DEFAULT_COLUMNS[type].join('"\t"')}"\n\n\n`);
};

const getEmptyRows = (
  ref: RefObject<DataGridHandle>,
  type?: keyof typeof DEFAULT_COLUMNS
) => {
  if (!type || !ref.current) {
    return [];
  }

  const height = ref.current?.element?.clientHeight || 0;
  const rowCount = Math.floor(height / 36);

  const singleRow: Record<string, any> = {};
  for (const col of DEFAULT_COLUMNS[type]) {
    singleRow[col] = '';
  }
  return Array.from({ length: rowCount }).map(() => ({ ...singleRow }));
};
