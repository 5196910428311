import { API_ROUTES } from '../constants';
import { APIExperimentCreateBody, HTTPMethod } from '../types';

export const createProjectExperiment = (
  projectId: string,
  newExperiment: APIExperimentCreateBody
) => ({
  url: API_ROUTES.experiments.createProjectExperiment(projectId),
  options: {
    method: HTTPMethod.POST,
    data: newExperiment,
  },
});

export const getProjectExperiments = (projectId: string) => ({
  url: API_ROUTES.experiments.getProjectExperiments(projectId),
});

export const getAllExperiments = () => ({
  url: API_ROUTES.experiments.getAll(),
});
