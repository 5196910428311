import { Select } from 'antd';
import { FC, useEffect } from 'react';
import { useUserCarts } from 'src/hooks/useUserCarts';
import { BiobankType } from 'src/types';

import { BIOBANK_TYPE_NAME } from '../biobank/constants';

interface Props {
  onHydrateCart: (cart: any) => void;
  onSelectCartId: (cartId: number) => void;
  cartId?: number;
  placeholder?: string;
  type?: BiobankType;
}

export const CartPicker: FC<Props> = ({
  onHydrateCart,
  onSelectCartId,
  placeholder,
  type,
  cartId,
}) => {
  const { data, loading } = useUserCarts(type);

  const options: { value: number; label: string }[] = (data || []).map(
    (cart) => ({
      value: cart.id,
      label: `${cart.name} - ${BIOBANK_TYPE_NAME[cart.type]} cart #${cart.id}`,
    })
  );

  useEffect(() => {
    if (cartId && data) {
      const foundCart = data.find((cart) => cart.id === cartId);

      onHydrateCart(foundCart);
    }
  }, [cartId, data]);

  return (
    <Select
      options={options}
      onChange={onSelectCartId}
      value={cartId}
      popupMatchSelectWidth={false}
      placeholder={loading ? 'Loading...' : placeholder || 'Select a cart'}
      disabled={loading}
      allowClear
    />
  );
};
