import { FC, Fragment, useState } from 'react';
import styled from 'styled-components';

import { COLORS } from '../../../constants';
import { useDocumentTitle } from '../../../hooks';
import { useItemCardData } from '../../../hooks/useItemCardData';
import { BiobankType } from '../../../types';
import { APIFood, APIFoodTube } from '../../../types/api/biobank';
import { Checkbox, Input, Textarea } from '../../forms';
import { Modal } from '../../Modal';
import { FieldTitle, Title } from '../../typography';
import {
  BIOBANK_TYPE_NAME,
  NAME_FIELD_BY_TYPE,
  VIEW_CARD_FIELD_VALUES_BY_TYPE,
} from '../constants';
import { FIELD_TYPES } from '../types';
import { getItemFieldValue, getNestedItemDisplayName } from '../utils';
import {
  DownloadDisplayer,
  FoodKeyData,
  SafetyDisplayer,
  SpanDisplayer,
  SpeciesDisplayer,
  TagDisplayer,
} from './displayers';
import { LoadingErrorDisplay } from './LoadingErrorDisplay';
import { ViewCardHeader } from './ViewCardHeader';

interface Props {
  item: APIFood | APIFoodTube | any;
  itemType: BiobankType;
  onClose?: () => void;
  refreshData?: () => void;
  hideMask?: boolean;
  isAdding?: boolean;
  depth?: number;
}

export const ViewCard: FC<Props> = ({
  item: providedItem,
  itemType,
  onClose = () => {},
  refreshData = () => {},
  hideMask = false,
  isAdding = false,
  depth = 0,
}) => {
  const [isEditing, setIsEditing] = useState(isAdding);
  const [hasUnsavedChanges, _setHasUnsavedChanges] = useState(false);
  const itemNameField = NAME_FIELD_BY_TYPE[itemType as BiobankType];
  const itemDisplayName = !isAdding
    ? (providedItem as any)?.[itemNameField]
    : '';
  const {
    data: item,
    loading,
    error,
  } = useItemCardData(providedItem, itemType);

  useDocumentTitle(`${itemDisplayName} - Biobank`);

  if (!item && !loading && !error) {
    return null;
  }

  return (
    <Modal
      style={{
        translate: `${depth * 8}px ${depth * 8}px`,
      }}
      onCancel={onClose}
      mask={!hideMask}
      header={
        <ViewCardHeader
          item={item}
          itemType={itemType}
          isAdding={isAdding}
          hasUnsavedChanges={hasUnsavedChanges}
          refreshData={refreshData}
          onClose={onClose}
          toggleIsEditing={() => setIsEditing(!isEditing)}
        />
      }
    >
      {loading || error ? (
        <LoadingErrorDisplay loading={loading} error={error} />
      ) : (
        <>
          {![
            BiobankType.Strains,
            BiobankType.PickPlates,
            BiobankType.Foods,
          ].includes(itemType) ? (
            <>
              <FieldTitle>{BIOBANK_TYPE_NAME[itemType]} ID</FieldTitle>
            </>
          ) : null}
          <CardTitle>{itemDisplayName}</CardTitle>
          {itemType === BiobankType.Strains ? (
            <SpeciesDisplayer phylogeny={item?.strain_phylogeny} />
          ) : null}
          {itemType === BiobankType.Strains ? (
            <SafetyDisplayer item={item} />
          ) : null}
          {itemType === BiobankType.Foods ? <FoodKeyData item={item} /> : null}

          <Group>
            {VIEW_CARD_FIELD_VALUES_BY_TYPE[
              itemType as keyof typeof VIEW_CARD_FIELD_VALUES_BY_TYPE
            ].map(
              ({
                title,
                field,
                type,
                tagFieldNames,
                tagOptions,
                nestedFieldName,
                nestedFieldType,
                derive,
                style,
                hideFieldTitle,
              }) => {
                const itemValue = getItemFieldValue(item as any, field);

                if (type === FIELD_TYPES.Derived) {
                  return (
                    <Fragment key={field}>
                      <FieldTitle>{title}</FieldTitle>
                      <span>{derive?.(item)}</span>
                    </Fragment>
                  );
                }

                if (type === FIELD_TYPES.DownloadURL) {
                  return (
                    <Fragment key={field}>
                      <FieldTitle>{title}</FieldTitle>
                      <DownloadDisplayer item={item} field={field} />
                    </Fragment>
                  );
                }

                if (type === FIELD_TYPES.Species) {
                  return (
                    <Fragment key={field}>
                      <SpeciesDisplayer phylogeny={itemValue} />
                      <FieldTitle />
                    </Fragment>
                  );
                }

                if (type === FIELD_TYPES.Nested) {
                  return (
                    <Fragment key={field}>
                      <FieldTitle>{title}</FieldTitle>
                      <NestedDisplayer
                        item={
                          nestedFieldName
                            ? itemValue?.[nestedFieldName]
                            : itemValue
                        }
                        itemType={nestedFieldType}
                        depth={depth}
                      />
                    </Fragment>
                  );
                }

                if (type === FIELD_TYPES.NestedByName) {
                  return (
                    <Fragment key={field}>
                      <FieldTitle>{title}</FieldTitle>
                      <NestedDisplayer
                        item={
                          nestedFieldName
                            ? itemValue?.[nestedFieldName]
                            : itemValue
                        }
                        itemType={nestedFieldType}
                        depth={depth}
                        isNameOnly
                      />
                    </Fragment>
                  );
                }

                if (type === FIELD_TYPES.Tags && itemValue) {
                  return (
                    <Fragment key={field}>
                      <FieldTitle>{title}</FieldTitle>
                      <TagDisplayer
                        values={itemValue as any[]}
                        fieldNames={tagFieldNames!}
                        isEditing={isEditing}
                        tagOptions={tagOptions}
                      />
                    </Fragment>
                  );
                }

                let InputComponent: any = Input;
                let inputType: any;

                switch (type) {
                  case FIELD_TYPES.Textarea:
                    InputComponent = Textarea;
                    break;
                  case FIELD_TYPES.Hardcoded:
                    InputComponent = SpanDisplayer;
                    break;
                  case FIELD_TYPES.Boolean:
                    InputComponent = Checkbox;
                    inputType = 'checkbox';
                    break;
                  case FIELD_TYPES.Date:
                    inputType = 'date';
                    break;
                }

                const hideTitleStyle = hideFieldTitle
                  ? { gridColumn: '1 / 3' }
                  : {};
                const finalStyle = style
                  ? { ...style, ...hideTitleStyle }
                  : { ...hideTitleStyle };

                return (
                  <Fragment key={field}>
                    {hideFieldTitle ? null : <FieldTitle>{title}</FieldTitle>}
                    <InputWrapper style={finalStyle}>
                      <InputComponent
                        disabled={!isEditing}
                        value={
                          type === FIELD_TYPES.Boolean
                            ? undefined
                            : itemValue || undefined
                        }
                        checked={
                          type === FIELD_TYPES.Boolean ? itemValue : undefined
                        }
                        type={inputType}
                      />
                    </InputWrapper>
                  </Fragment>
                );
              }
            )}
          </Group>
        </>
      )}
    </Modal>
  );
};

const Group = styled.div`
  padding: 16px 0px;
  border-top: 1px solid lightgrey;
  display: grid;
  grid-template-columns: 120px auto;
  row-gap: 16px;
  column-gap: 8px;
`;

const InputWrapper = styled.div`
  > * {
    width: 100%;
  }
  > input[type='checkbox'] {
    width: auto;
  }
`;

const NestedWrapper = styled.div`
  border-radius: 4px;
  padding: 4px;
  border: 1px solid ${COLORS.brand.blue}FF;
  background-color: ${COLORS.brand.blue}80;
  cursor: pointer;
  font-size: smaller;
  margin-bottom: 4px;
  text-align: center;
`;

export const NestedDisplayer = ({
  item,
  itemType,
  depth = 0,
  isNameOnly = false,
}: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (Array.isArray(item)) {
    return (
      <div>
        {item.map((subitem, index) => (
          <NestedDisplayer
            key={index}
            depth={depth}
            item={subitem}
            itemType={itemType}
          />
        ))}
      </div>
    );
  }

  const itemDisplayName = getNestedItemDisplayName({
    item,
    itemType,
    isNameOnly,
  });

  return (
    <NestedWrapper>
      <div
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        {itemDisplayName}
      </div>
      {isModalOpen ? (
        <ViewCard
          {...{
            item: isNameOnly ? { [NAME_FIELD_BY_TYPE[itemType]]: item } : item,
            itemType,
          }}
          depth={depth + 1}
          onClose={() => {
            setIsModalOpen(false);
          }}
          hideMask
        />
      ) : (
        <></>
      )}
    </NestedWrapper>
  );
};

const CardTitle = styled(Title)`
  margin: 0;
`;
