import { ConfigProvider } from 'antd';
import { RouterProvider } from 'react-router-dom';

import { COLORS } from './constants';
import { browserRouter } from './Router';

const App = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: COLORS.brand.orange,
        },
        components: {
          Button: {
            colorPrimary: COLORS.brand.orangeAlpha(0.9),
            colorPrimaryHover: COLORS.brand.orange,
            colorPrimaryBorderHover: COLORS.brand.orange,
          },
        },
      }}
    >
      <RouterProvider router={browserRouter} />
    </ConfigProvider>
  );
};
export default App;
