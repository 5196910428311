import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  LoadingOutlined,
  QuestionCircleTwoTone,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { FC } from 'react';
import { COLORS } from 'src/constants';
import { Job, JobResultStatus } from 'src/types/api/tasks';

export const JobStatusIcon: FC<{
  jobs: Pick<Job, 'job_status'>[];
  isSingle?: boolean;
}> = ({ jobs, isSingle }) => {
  const areAllSuccess = jobs.every(
    (job) =>
      job.job_status === JobResultStatus.Succeeded ||
      job.job_status === JobResultStatus.Finished
  );
  const areAnyLoading = jobs.some(
    (job) =>
      job.job_status === JobResultStatus.Queued ||
      job.job_status === JobResultStatus.Created ||
      job.job_status === JobResultStatus.Started
  );
  const areAnyErrored = jobs.some(
    (job) => job.job_status === JobResultStatus.Errored
  );
  const isEmptySet = jobs.length === 0;

  if (isEmptySet) {
    return (
      <Tooltip title={isSingle ? 'Missing' : 'No jobs'}>
        <QuestionCircleTwoTone twoToneColor={COLORS.brand.blue} />
      </Tooltip>
    );
  }

  if (areAllSuccess) {
    return (
      <Tooltip title={isSingle ? 'Success' : 'All jobs succeeded'}>
        <CheckCircleTwoTone twoToneColor={COLORS.brand.green} />
      </Tooltip>
    );
  }

  if (areAnyErrored) {
    return (
      <Tooltip title={isSingle ? 'Error' : 'Some job(s) errored'}>
        <ExclamationCircleTwoTone twoToneColor={COLORS.brand.orange} />
      </Tooltip>
    );
  }

  if (areAnyLoading) {
    return (
      <Tooltip
        title={isSingle ? 'Processing' : 'Some job(s) are still processing'}
      >
        <LoadingOutlined />
      </Tooltip>
    );
  }

  return null;
};
