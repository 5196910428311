import { createWellContents } from '../../api';
import {
  APIPlateWithAllChildren,
  APIWellContentsCreateBody,
  PlateSize,
} from '../../types';
import { authFetch } from '../../utils';
import { Coordinate, EMPTY_WELL, Plate, Well } from './types';

const randomString = (length: number) => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

const randomNumberOfStrings = (number: number, length: number) => {
  const max = Math.floor(Math.random() * number);
  const results: string[] = [];
  for (let i = 0; i < max; i++) {
    results.push(randomString(length));
  }
  return results;
};

export const randomWell = (): Well => {
  const newWell = { ...EMPTY_WELL };
  const shouldLeaveEmpty = Math.random() < 0.4;
  if (shouldLeaveEmpty) {
    return newWell;
  }
  newWell.strains = randomNumberOfStrings(3, 6);
  newWell.barcodes = randomNumberOfStrings(3, 6);
  newWell.conditions = randomNumberOfStrings(2, 25);
  return newWell;
};

export const countSelectedWells = (grid: Plate) => {
  let count = 0;
  for (let r = 0; r < grid.length; r++) {
    count += grid[r].reduce((acc, well) => acc + (well.isSelected ? 1 : 0), 0);
  }
  return count;
};

export const isWellSelected = (grid: Plate, row: number, column: number) => {
  return grid[row][column].isSelected;
};

export const isColumnSelected = (grid: Plate, column: number) => {
  return grid.reduce(
    (isSelected, row) => isSelected && !!row[column].isSelected,
    true
  );
};

export const isRowSelected = (grid: Plate, row: number) => {
  return grid[row].reduce(
    (isSelected, well) => isSelected && !!well.isSelected,
    true
  );
};

export const isGridSelected = (grid: Plate) => {
  return grid.reduce(
    (isSelected, row, rowNumber) =>
      isSelected && isRowSelected(grid, rowNumber),
    true
  );
};

export const setSingleWell = ({
  row,
  column,
  setGrid,
  isSelected,
}: {
  row: number;
  column: number;
  setGrid: (setter: (oldGrid: Plate) => Plate) => void;
  isSelected: boolean;
}) => {
  setGrid((oldGrid: Plate) => {
    const newGrid = [...oldGrid.map((col) => [...col])];
    newGrid[row][column] = {
      ...newGrid[row][column],
      isSelected,
    };
    return newGrid;
  });
};

export const toggleSingleWell = ({
  row,
  column,
  setGrid,
}: {
  row: number;
  column: number;
  setGrid: (setter: (oldGrid: Plate) => Plate) => void;
}) => {
  setGrid((oldGrid: Plate) => {
    const newGrid = [...oldGrid.map((col) => [...col])];
    newGrid[row][column] = {
      ...newGrid[row][column],
      isSelected: !newGrid[row][column].isSelected,
    };
    return newGrid;
  });
};

export const selectAllWellsBetweenCoordinates = ({
  pointA,
  pointB,
  setGrid,
}: {
  pointA: Coordinate;
  pointB: Coordinate;
  setGrid: (setter: (oldGrid: Plate) => Plate) => void;
}) => {
  const coordinates = getAllCellsBetweenCoordinates(pointA, pointB);
  for (const [row, column] of coordinates) {
    setSingleWell({ row, column, setGrid, isSelected: true });
  }
};

export const getAllCellsBetweenCoordinates = (
  pointA: Coordinate,
  pointB: Coordinate
) => {
  const [rowA, colA] = pointA;
  const [rowB, colB] = pointB;
  const coordinates: Coordinate[] = [];

  const isLTR = colB > colA;
  const isTTB = rowB > rowA;
  const hDistance = Math.abs(colB - colA);
  const vDistance = Math.abs(rowB - rowA);

  for (let r = 0; r <= vDistance; r++) {
    for (let c = 0; c <= hDistance; c++) {
      coordinates.push([
        isTTB ? rowA + r : rowA - r,
        isLTR ? colA + c : colA - c,
      ]);
    }
  }
  return coordinates;
};

export const getSizeOfPlate = (plate: APIPlateWithAllChildren) => {
  return Number(plate.size) as PlateSize;
};

export const rowNumberToLetter = (row: number) => String.fromCharCode(row + 65);

export const rowLetterToNumber = (row: string) => {
  return 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.indexOf(row);
};

export const addRowsAndColumnsToGrid = (grid: Plate) => {
  for (let row = 0; row < grid.length; row++) {
    for (let col = 0; col < grid[row].length; col++) {
      grid[row][col] = {
        ...grid[row][col],
        row: rowNumberToLetter(row),
        column: (col + 1).toFixed(0),
      };
    }
  }
  return grid;
};

export const getSelectedWells = (grid: Plate) => {
  return grid.flatMap((row) => row.filter(({ isSelected }) => !!isSelected));
};

export const saveGridContentsToPlate = async ({
  grid,
  plateId,
}: {
  grid: Plate;
  plateId: string;
}) => {
  const wellUpdates: APIWellContentsCreateBody[] = [];
  for (let row = 0; row < grid.length; row++) {
    for (let col = 0; col < grid[row].length; col++) {
      const { id, strains } = grid[row][col];
      if (id && strains?.length) {
        for (let strain_id of strains) {
          wellUpdates.push({
            well_id: id,
            strain_id,
          });
        }
      }
    }
  }
  await authFetch(createWellContents(plateId, wellUpdates));
};
