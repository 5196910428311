import { FC } from 'react';

import { API_ROUTES } from '../../../constants';
import { useFetch } from '../../../hooks';

export const KronaEmbed: FC<{ name: string }> = ({ name }) => {
  const { data } = useFetch<any>(API_ROUTES.biobank.strains.krona(name));

  if (!data?.url) {
    return null;
  }

  return (
    <iframe
      style={{
        aspectRatio: 1,
        width: '100%',
        alignSelf: 'center',
      }}
      src={data.url}
      title="Krona"
    />
  );
};
