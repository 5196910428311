import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { getBiobankItem } from '../api';
import { ViewItem } from '../components/biobank/view/ViewItem';
import { useFetch } from '../hooks';
import { BiobankType } from '../types';

export const BiobankSingleItem: FC = () => {
  const { type, itemId } = useParams();
  const {
    loading,
    error: _error,
    data,
  } = useFetch<any>(getBiobankItem(type || '', itemId || '').url);
  return (
    <div>
      {loading ? (
        'Loading...'
      ) : (
        <ViewItem item={data} itemId={itemId} itemType={type as BiobankType} />
      )}
    </div>
  );
};
