import { FC } from 'react';
import styled from 'styled-components';

import { DisplaySettings } from './types';

const BUTTON_COLORS = {
  yellow: 'rgb(248,212,71)',
  lime: 'rgb(156,283,79)',
  red: 'rgb(247,176,170)',
};

interface Props {
  displaySettings: DisplaySettings;
  setDisplaySettings: (d: DisplaySettings) => void;
}

export const PlateControls: FC<Props> = ({
  displaySettings,
  setDisplaySettings,
}) => {
  return (
    <Wrapper>
      <ButtonGroup>
        <ActionButton
          color={BUTTON_COLORS.yellow}
          onClick={() => setDisplaySettings(DisplaySettings.Strains)}
          disabled={displaySettings === DisplaySettings.Strains}
        >
          Display Strains
        </ActionButton>
        <ActionButton
          color={BUTTON_COLORS.yellow}
          onClick={() => setDisplaySettings(DisplaySettings.Barcodes)}
          disabled={displaySettings === DisplaySettings.Barcodes}
        >
          Display Barcodes
        </ActionButton>
        <ActionButton
          color={BUTTON_COLORS.yellow}
          onClick={() => setDisplaySettings(DisplaySettings.Conditions)}
          disabled={displaySettings === DisplaySettings.Conditions}
        >
          Display Conditions
        </ActionButton>
        <ActionButton
          color={BUTTON_COLORS.yellow}
          onClick={() => setDisplaySettings(DisplaySettings.Species)}
          disabled={displaySettings === DisplaySettings.Species}
        >
          Display Species
        </ActionButton>
      </ButtonGroup>
      <ButtonGroup>
        <ActionButton color={BUTTON_COLORS.lime}>+ / - Strain</ActionButton>
        <ActionButton color={BUTTON_COLORS.lime}>+ / - Condition</ActionButton>
        <ActionButton color={BUTTON_COLORS.lime}>+ / - Note</ActionButton>
      </ButtonGroup>
      <ButtonGroup>
        <ActionButton color={BUTTON_COLORS.red}>Export Platemap</ActionButton>
      </ButtonGroup>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`;

const ActionButton = styled.button<{ color: string; disabled?: boolean }>`
  cursor: pointer;
  background-color: ${({ color }) => color};
  color: ${({ disabled }) => (disabled ? 'grey' : 'black')};
  border: 1px solid black;
  border-radius: 4px;
  padding: 8px 4px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  &:first-child {
    margin-bottom: 16px;
  }
  &:not(:first-child) {
    margin: 16px 0;
  }
`;
