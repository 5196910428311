export const PLATE_COLUMNS = {
  24: 6,
  96: 12,
  384: 24,
  1536: 48,
};

export const PLATE_ROWS = {
  24: 4,
  96: 8,
  384: 16,
  1536: 32,
};

export type Well = {
  isSelected?: boolean;
  id?: string;
  row: string | null;
  column: string | null;
  strains: string[];
  barcodes: string[];
  conditions: string[];
  species: string[];
  notes: string[];
};

export type Plate = Well[][];

export const EMPTY_WELL: Well = {
  isSelected: false,
  row: null,
  column: null,
  strains: [],
  barcodes: [],
  conditions: [],
  species: [],
  notes: [],
};

export enum DisplaySettings {
  R1C1 = 'r1c1',
  Strains = 'strains',
  Barcodes = 'barcodes',
  Conditions = 'conditions',
  Species = 'species',
}

export type Coordinate = [row: number, column: number];
