import { Button, Divider, Input, message, Select, Spin, Tooltip } from 'antd';
import { FC, Fragment, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  getAvailablePageTasks,
  getAvailableRefinementTasks,
  getAvailableStandaloneTasks,
  getAvailableTasks,
  getStrainByName,
  getTaskInputs,
  startCartTask,
} from 'src/api/cartTasks';
import { useFetch, useUser } from 'src/hooks';
import { BiobankType } from 'src/types';
import {
  AvailableTask,
  CreatedJob,
  ReshapedTaskInputField,
  TaskInputField,
  TaskInputFieldType,
  TaskInputs,
  TaskStartResult,
  ValidationIssue,
} from 'src/types/api/tasks';
import { authFetch, compute } from 'src/utils';
import styled from 'styled-components';

import { FieldTitle } from '../typography';
import { RunTaskModalPostSubmission } from './RunTaskModalPostSubmission';
import { TaskTextView } from './TaskTextView';

interface Props {
  cartId?: number;
  strainName?: string;
  genomeUuid?: string;
  cartType?: BiobankType;
  isPage?: boolean;
  isStandalone?: boolean;
  originalJob?: {
    series_id: string;
    job_id: string;
    refine_id: string;
    task_name: string;
  };
}

type FormState = Record<string, any> & {
  task_name: string;
};

export const RunTaskModal: FC<Props> = ({
  cartId,
  cartType,
  isPage,
  isStandalone,
  strainName,
  genomeUuid,
  originalJob,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [jobsCreated, setJobsCreated] = useState<CreatedJob[]>([]);
  const [validationIssues, setValidationIssues] = useState<ValidationIssue[]>(
    []
  );

  const isStrainPage = !!(
    isPage &&
    cartType === BiobankType.Strains &&
    strainName
  );

  const { data: strainObject } = useFetch<{ id: number }>(
    isStrainPage ? getStrainByName(strainName).url : null
  );

  const isRefine = !!originalJob;

  const taskApiSource = (() => {
    if (isStandalone) {
      return getAvailableStandaloneTasks().url;
    }

    if (isPage && cartType) {
      return getAvailablePageTasks(cartType).url;
    }

    if (isRefine) {
      return getAvailableRefinementTasks(originalJob.task_name).url;
    }

    if (cartType) {
      return getAvailableTasks(cartType).url;
    }
  })();

  const { loading: loadingAvailableTasks, data: availableTasks } =
    useFetch<AvailableTask[]>(taskApiSource);

  const currentUser = useUser();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid },
    watch,
  } = useForm<FormState>();

  const selectedTask = watch('task_name');

  const { loading: _loadingInputs, data: taskInputs } = useFetch<TaskInputs>(
    selectedTask ? getTaskInputs(selectedTask).url : undefined
  );

  const header = taskInputs?.header;

  const transformedInputs = getTransformedInputs(taskInputs);
  const transformedValidation =
    getTransformedValidationIssues(validationIssues);

  useEffect(() => {
    if (selectedTask) {
      const defaults = getDefaultValues(transformedInputs);

      reset({ task_name: selectedTask, ...defaults });
    }
  }, [selectedTask, transformedInputs.length]);

  const onSubmit = async (data: FormState) => {
    const { task_name, ...otherData } = data;

    if (
      !currentUser ||
      (!cartId && !(strainObject || genomeUuid) && !isRefine && !isStandalone)
    ) {
      return;
    }

    try {
      setValidationIssues([]);
      setJobsCreated([]);
      setIsLoading(true);

      const payload = compute(() => {
        if (isRefine) {
          const { series_id, job_id } = originalJob;
          return {
            task_name,
            userId: currentUser?.id,
            data: { ...otherData, series_id, job_id },
          };
        }

        return {
          task_name,
          userId: currentUser?.id,
          data: otherData,
          ...(cartId ? { cartId } : {}),
          ...(strainName ? { strainId: strainObject?.id } : {}),
          ...(genomeUuid ? { uuid: genomeUuid } : {}),
        };
      });

      const result = await authFetch<TaskStartResult>(startCartTask(payload));

      const { jobs_created, validation_issues } = result || {};

      if (validation_issues?.length) {
        setValidationIssues(validation_issues);
        throw new Error('Validation issues');
      }

      if (jobs_created?.length) {
        setJobsCreated(jobs_created);
      } else {
        throw new Error('No jobs created');
      }
    } catch (e) {
      if ((e as any).detail) {
        const { detail } = e as any;
        message.error(
          <span style={{ whiteSpace: 'pre-wrap' }}>{`${JSON.stringify(
            detail
          )}`}</span>
        );
      } else {
        message.error(<span style={{ whiteSpace: 'pre-wrap' }}>{`${e}`}</span>);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const textViewPayload = compute(() => {
    if (isRefine) {
      const { series_id, job_id, refine_id } = originalJob;
      return {
        data: { refine_id, series_id, job_id },
      };
    }

    return {
      ...(cartId ? { cart_id: cartId } : {}),
      ...(strainName ? { strain_id: strainObject?.id } : {}),
      ...(genomeUuid ? { uuid: genomeUuid } : {}),
    };
  });

  return (
    <Spin spinning={loadingAvailableTasks}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Wrapper>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Group style={{ flexGrow: 1 }}>
              <FieldTitle style={{ display: 'flex', alignItems: 'center' }}>
                Task
              </FieldTitle>
              <InputWrapper>
                <Controller
                  control={control}
                  name={'task_name'}
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="Task"
                      options={(availableTasks || []).map((task) => {
                        return {
                          value: task.endpoint_base,
                          label: task.display_name,
                        };
                      })}
                    />
                  )}
                  rules={{ required: true }}
                />
              </InputWrapper>
              {header ? (
                <>
                  <FieldTitle />
                  <InputWrapper style={{ marginTop: '-12px' }}>
                    <FieldTitle dangerouslySetInnerHTML={{ __html: header }} />
                  </InputWrapper>
                </>
              ) : null}
              {transformedInputs.map((field) => {
                const {
                  label,
                  attribute_name,
                  help_text,
                  placeholder,
                  default_value,
                  options,
                  required,
                  inputType,
                } = field;

                const issue = transformedValidation[attribute_name];

                const isSelect = inputType === TaskInputFieldType.Select;

                const InputComponent = (() => {
                  switch (inputType) {
                    case TaskInputFieldType.SingleText:
                      return Input;
                    case TaskInputFieldType.MultiText:
                      return Input.TextArea;
                    case TaskInputFieldType.Select:
                      return Select;
                    default:
                      return Input;
                  }
                })();

                return (
                  <Fragment key={attribute_name}>
                    <FieldTitle
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Tooltip
                        title={`${required ? 'Required. ' : ''}${help_text}`}
                        placement="right"
                        arrow={{ pointAtCenter: true }}
                      >
                        {label} {required ? '*' : null}
                      </Tooltip>
                    </FieldTitle>
                    <InputWrapper>
                      <Controller
                        control={control}
                        name={attribute_name}
                        render={({ field }) => (
                          <InputComponent
                            {...field}
                            placeholder={placeholder || undefined}
                            required={required}
                            defaultValue={default_value || undefined}
                            {...(isSelect
                              ? {
                                  options: (options || []).map(
                                    ({ display, key }) => ({
                                      value: key,
                                      label: display,
                                    })
                                  ),
                                }
                              : {})}
                          />
                        )}
                        rules={{ required }}
                      />
                      {!!issue ? (
                        <IssueWrapper>
                          {/* <br /> */}
                          {issue}
                        </IssueWrapper>
                      ) : null}
                    </InputWrapper>
                  </Fragment>
                );
              })}
            </Group>
            <TaskTextView
              selectedTask={selectedTask}
              payload={textViewPayload}
            />
          </div>
          <div style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!isValid || isLoading || !selectedTask}
              loading={isLoading}
            >
              Submit
            </Button>
          </div>
        </Wrapper>
      </form>
      {jobsCreated.length ? (
        <>
          <Divider />
          <RunTaskModalPostSubmission jobsCreated={jobsCreated} />
        </>
      ) : null}
    </Spin>
  );
};

const InputWrapper = styled.div`
  > * {
    width: 100%;
  }
  > input[type='checkbox'] {
    width: auto;
  }

  &&&& .ant-select-selection-placeholder,
  &&&& input::placeholder {
    font-style: italic !important;
    color: rgba(1, 1, 1, 0.4);
  }
`;

const Group = styled.div`
  padding: 16px 0px;
  display: grid;
  grid-template-columns: fit-content(200px) auto;
  row-gap: 16px;
  column-gap: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const IssueWrapper = styled.div`
  color: red;
  font-size: 12px;
`;

const getTransformedInputs = (rawInputs?: TaskInputs) => {
  if (!rawInputs) {
    return [];
  }

  const keys = Object.keys(rawInputs).filter(
    (key) => key !== 'header'
  ) as TaskInputFieldType[];

  const output: ReshapedTaskInputField[] = [];

  keys.forEach((inputType) => {
    rawInputs[inputType].forEach((input: TaskInputField) => {
      output[input.vertical_position] = {
        ...input,
        default_value: input.default,
        inputType,
      };
    });
  });

  return output;
};

const getTransformedValidationIssues = (raw: ValidationIssue[]) => {
  return raw.reduce((acc, issue) => {
    const { field, message } = issue;
    acc[field] = message;
    return acc;
  }, {} as Record<string, string>);
};

const getDefaultValues = (transformedInputs: ReshapedTaskInputField[]) => {
  return transformedInputs.reduce((acc, field) => {
    if (field.default_value) {
      acc[field.attribute_name] = field.default_value;
    }

    return acc;
  }, {} as Record<string, any>);
};
