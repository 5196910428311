import { message } from 'antd';
import { useEffect, useState } from 'react';
import { getResults } from 'src/api/cartTasks';
import { JobResult, JobResultFile } from 'src/types/api/tasks';

import { useFetch, useInterval } from '.';

type HydratedJobResultFile = JobResultFile &
  Partial<{ fileBody: string; isDispatched: boolean }>;

export const useHydratedJobResults = (job?: {
  series_id: string;
  job_id: string;
  refine_id: string;
}) => {
  const [isHydrating, setIsHydrating] = useState(false);
  const [isHydrationStarted, setIsHydrationStarted] = useState(false);
  const [files, setFiles] = useState<HydratedJobResultFile[]>([]);
  const { loading, data, refresh } = useFetch<JobResult>(
    !!job
      ? getResults({
          seriesId: job.series_id,
          jobId: job.job_id,
          refineId: job.refine_id,
        }).url
      : undefined
  );

  const isFinal = !!(data && data.final);
  const isOneFileLoaded = files.some((file) => file.fileBody);

  useInterval(
    () => {
      if (isFinal && data?.files.length === files.length) {
        return;
      }

      refresh();
    },
    isFinal ? null : 5000
  );

  const hydrateFilesIdempotent = async (rawFiles: HydratedJobResultFile[]) => {
    setIsHydrationStarted(true);
    setIsHydrating(true);

    try {
      for (const i in rawFiles) {
        const file = rawFiles[i];

        if (files[i] && files[i].isDispatched) {
          continue;
        }

        setFiles((f) => {
          f[i] = {
            ...file,
            isDispatched: true,
          };

          return f;
        });

        console.log(`fetching ${file.tab_name}`);

        const fileFetch = await fetch(file.file_path);
        const fileBody = await fileFetch.text();

        setFiles((f) => {
          f[i] = { ...f[i], ...file, fileBody };

          return f;
        });
      }
    } catch (e) {
      message.error(`${e}`);
    } finally {
      setIsHydrating(false);
    }
  };

  useEffect(() => {
    if (data && data.files) {
      hydrateFilesIdempotent(data.files);
    }
  }, [data]);

  return {
    isHydrating,
    isHydrationStarted,
    isLoadingResults: loading,
    isFinal,
    isOneFileLoaded,
    hasCart: !!data?.has_cart,
    files,
  };
};
