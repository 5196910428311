import { useState } from 'react';

import { HTTPMethod } from '../types';

// Pass URL
export const useLogin = (url: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleGoogle = async (response: { credential: any }) => {
    setLoading(true);
    try {
      const res = await (
        await fetch(url, {
          method: HTTPMethod.POST,
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'X-Requested-With',
          },
          body: JSON.stringify({ credential: response.credential }),
        })
      ).json();

      setLoading(false);

      if (res && res.email) {
        localStorage.setItem('user', JSON.stringify(res));
        window.location.reload();
      } else {
        throw new Error(res?.message || res);
      }
    } catch (e: any) {
      setError(e?.message);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, handleGoogle };
};
