import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import { CSSProperties, useEffect, useState } from 'react';
import { NcbiTaxonomySchemaOut } from 'src/types/api/schemas';
import { getDataUri, getRandomTagColor, properCase } from 'src/utils';
import styled from 'styled-components';

import { FieldTitle } from '../../typography';
import { ItemDisplayer } from '../types';

export const FoodKeyData: ItemDisplayer = ({ item }) => {
  const { food } = item || {};
  const { high_level_product, high_level_substrate, substrate_descriptors } =
    food || {};
  const tags = [
    ...(high_level_product || []),
    ...(high_level_substrate || []),
    ...(substrate_descriptors || []),
  ];

  const [tagColors] = useState(
    Array.from({ length: tags.length }, () => getRandomTagColor())
  );

  if (!item) {
    return null;
  }

  return (
    <div style={{ marginBottom: '8px' }}>
      <div>
        <span style={{ fontStyle: 'italic' }}>{food?.descriptive_name}</span>
      </div>
      {tags.length > 0 ? (
        <div>
          {tags.map((tag, i) => (
            <Tag color={tagColors[i]} key={tag.name || tag.description}>
              {tag.name || tag.description}
            </Tag>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export const SafetyDisplayer = ({ item }: { item: any }) => {
  return (
    <div
      style={{
        position: 'absolute',
        right: 0,
        textAlign: 'right',
        display: 'flex',
        flexDirection: 'column',
        padding: 'inherit',
        paddingTop: 'unset',
      }}
    >
      {item?.gene_level_concern ? (
        <FieldTitle>
          Gene-Level Concern: {properCase(item.gene_level_concern)}
        </FieldTitle>
      ) : null}
      {item?.taxonomic_concern ? (
        <FieldTitle>Taxonomic Concern: {item?.taxonomic_concern}</FieldTitle>
      ) : null}
    </div>
  );
};

const PHYLOGENY_MAPPINGS = [
  'Kingdom',
  'Phylum',
  'Class',
  'Order',
  'Family',
  'Genus',
  'Species',
];

const SmallButton = styled(Button)`
  &&& {
    font-size: 10px;
  }
`;

export const SpeciesDisplayer = ({
  phylogeny,
}: {
  phylogeny: NcbiTaxonomySchemaOut;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { ncbi_species } = phylogeny || {};

  if (!phylogeny) {
    return (
      <div
        style={{
          marginBottom: '8px',
          height: '8px',
        }}
      />
    );
  }

  return (
    <div
      style={{
        marginBottom: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ fontStyle: 'italic' }}>{ncbi_species?.name}</span>
        <div style={{ width: '16px' }} />
        <div>
          <SmallButton size="small" onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? 'Collapse' : 'Expand'}
          </SmallButton>
        </div>
      </div>
      {isExpanded
        ? PHYLOGENY_MAPPINGS.map((mapping) => (
            <div key={mapping}>
              <span>{mapping}</span>:{' '}
              <span style={{ fontStyle: 'italic' }}>
                {
                  phylogeny?.[
                    `ncbi_${mapping.toLowerCase()}` as keyof NcbiTaxonomySchemaOut
                  ]?.name
                }
              </span>
            </div>
          ))
        : null}
    </div>
  );
};

export const DownloadDisplayer: ItemDisplayer<{ field: string }> = ({
  item,
  field,
}) => {
  const [fileContents, setFileContents] = useState<string | null>(null);
  const [isFetching, setIsFetching] = useState(false);

  const downloadUrl = item?.[field] as string | undefined;
  const fileOnlyUrl = (downloadUrl || '').split('?')[0];
  const urlComponents = (fileOnlyUrl || '').split('/');
  const originalFilename = urlComponents[urlComponents.length - 1];

  const assemblyId = item.genome_assembly_id || '';
  const downloadFilename = `${assemblyId}_${originalFilename}`;

  useEffect(() => {
    const doPrefetch = async () => {
      if (!downloadUrl) {
        return;
      }

      setIsFetching(true);
      try {
        const res = await fetch(downloadUrl);
        const text = await res.text();
        setFileContents(text);
      } finally {
        setIsFetching(false);
      }
    };

    doPrefetch();
  }, [downloadUrl]);

  return (
    <Button
      href={fileContents ? getDataUri(fileContents) : undefined}
      download={downloadFilename}
    >
      {isFetching ? (
        <>
          <LoadingOutlined /> Loading
        </>
      ) : (
        <>
          <DownloadOutlined /> Download
        </>
      )}
    </Button>
  );
};

export const SpanDisplayer = ({
  value,
  style,
}: {
  value: any;
  style?: CSSProperties;
}) => (
  <span
    style={{
      fontSize: '13px',
      overflowWrap: 'anywhere',
      ...(style ? style : {}),
    }}
  >
    {value}
  </span>
);

export const TagDisplayer = ({
  values,
  fieldNames,
  isEditing,
  tagOptions = {},
}: {
  values: any[];
  fieldNames: string[];
  isEditing?: boolean;
  tagOptions?: Record<string, any>;
}) => {
  const [tagColors] = useState(Array.from(values, () => getRandomTagColor()));
  const { useColors = true } = tagOptions;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      {values.map((value, i) => {
        return (
          <Tag
            closable={isEditing}
            key={value?.id}
            color={useColors ? tagColors[i] : undefined}
          >
            {fieldNames.map((fieldName) => value[fieldName]).join(' ')}
          </Tag>
        );
      })}
    </div>
  );
};
