import { LoadingOutlined } from '@ant-design/icons';
import { Tree } from 'antd';
import { DataNode } from 'antd/es/tree';
import { useMemo } from 'react';

import { API_ROUTES } from '../constants';
import { useFetch } from '../hooks';

export const TreeView = () => {
  const { loading, error: _error, data } = useFetch<any>(API_ROUTES.treeView());
  // console.log(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const persistedData = useMemo(() => reshapeToLeaves(data?.items), [loading]);
  console.log(persistedData);
  return (
    <div>
      {loading || !persistedData ? (
        <LoadingOutlined />
      ) : (
        <Tree treeData={persistedData} showLine />
      )}
    </div>
  );
};

const reshapeToLeaves = (data: any, parentIndex: any = 0): DataNode[] => {
  if (!data) {
    return [];
  }

  return data.flatMap((item: any, index: any) => {
    const returnValues: Partial<DataNode>[] = [];
    const keys = Object.keys(item);
    if (typeof item !== 'object') {
      const returnValue: Partial<DataNode> = {};
      returnValue.title = `${item}`;
      returnValue.key = `${parentIndex}-${index}-${item}`;
      returnValues.push(returnValue);
    } else {
      for (const key of keys) {
        const returnValue: Partial<DataNode> = {};
        returnValue.title = key;
        returnValue.key = `${parentIndex}-${index}-${key}`;
        if (Array.isArray(item[key])) {
          const children = reshapeToLeaves(item[key], returnValue.key);
          returnValue.children = children;
          if (!children?.length) {
            returnValue.title = (
              <span>
                {key} <i>[empty]</i>
              </span>
            );
          }
        } else if (typeof item[key] === 'object') {
          const children = reshapeToLeaves([item[key]], returnValue.key);
          returnValue.children = children;
        } else {
          returnValue.title = `${key}: ${item[key]}`;
        }
        returnValues.push(returnValue);
      }
    }
    return returnValues;
  });
};
