import { FC, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import { PlateSize } from '../../types';
import { HiddenInput, PlainButton, PlainLabelButton } from '../forms';
import { PLATE_COLUMNS, PLATE_ROWS } from './types';

interface Props {
  doSave: () => Promise<void>;
  plateSize: PlateSize;
  onLoadCSV: (wells: any[][], type: string) => any;
}

export const PlateDataControls: FC<Props> = ({
  doSave,
  plateSize,
  onLoadCSV,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [_error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const onFileSelect = useCallback(() => {
    const files = fileInputRef.current?.files;
    if (files?.[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        const loadedCells: string[][] = [];
        if (!reader.result) {
          return;
        }
        const csv = reader.result as String;
        if (!csv?.length) {
          return;
        }
        const expectedCols = PLATE_COLUMNS[plateSize];
        const expectedRows = PLATE_ROWS[plateSize];

        const splitRows = csv.split('\r\n');
        if (splitRows.length !== expectedRows) {
          return;
        }
        for (let row of splitRows) {
          const splitCols = row.split(',');
          if (splitCols.length !== expectedCols) {
            return;
          }
          loadedCells.push(splitCols);
        }
        onLoadCSV(loadedCells, 'strains');
      };
      reader.readAsText(files[0]);
    }
  }, [onLoadCSV, plateSize]);

  const save = async () => {
    setIsSaving(true);
    try {
      await doSave();
    } catch (e) {
      setError((e as Error).message);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <TopControls>
      <PlainLabelButton>
        <HiddenInput type="file" ref={fileInputRef} onInput={onFileSelect} />
        Import Strain CSV
      </PlainLabelButton>
      <PlainButton disabled={isSaving} onClick={save}>
        {isSaving ? 'Saving...' : 'Save Plate'}
      </PlainButton>
    </TopControls>
  );
};

const TopControls = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
